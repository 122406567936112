import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { ToolBar } from "../../components/Shared/ToolBar";
import { Localization } from "../../models/Localization";
import { Teacher } from "../../models/Teacher";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { getTeacherProfile } from "../../services/TeacherService";
import { HeaderTexts as Texts } from '../../helpers/LayoutTexts';
import { Helmet } from "react-helmet-async";
import { ReactComponent as Whatsapp } from "../../assets/svgs/whatsapp.svg";
import { DetailLoader } from "../../loaders/DetailLoader";

export const TeacherProfilePage: React.FC = () => {
    let params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("profilePage"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [teacherProfile, setTeacherProfile] = useState<Teacher>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const callbackUrl: string = searchParams.get("callbackUrl")!;
    
    useEffect(() => {    
        window.scrollTo(0, 0);
        getLocalizations();  
        getTeacherDisplayProfile();
        window.addEventListener("popstate", function handlePopState() {                             
            navigate(callbackUrl);            
            window.removeEventListener("popstate", handlePopState, true);
        }, true);                             
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("profilePage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(7, 22) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }

    async function getTeacherDisplayProfile() {        
        const teacherProfile = await getTeacherProfile(+params.teacherId!) as unknown as Teacher;
        setTeacherProfile(teacherProfile);         
        setLoading(false);
    }

    const toWhatsapp = (event: React.MouseEvent, whatsappNo: string) => {
        event.stopPropagation();
        window.open(`https://wa.me/${whatsappNo}`, '_blank');
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            {loading ? <DetailLoader/> : 
            <>
                <Helmet>
                    <title>{teacherProfile?.SEOTitle}</title>
                    <meta name="description" content={teacherProfile?.SEODescription}/>                    
                </Helmet>
                <div style={styles.container}>
                    <div>
                        <img src={teacherProfile?.Image ? teacherProfile?.Image : Texts.defaultAvatar} style={styles.image}></img>
                    </div> 
                    <div style={styles.detailContainer}>
                        <div style={styles.name}>{teacherProfile?.FirstName + " " + teacherProfile?.LastName}</div> 
                        <div style={styles.tepNo} onClick={(e) => toWhatsapp(e, teacherProfile!.WhatsappNo)}>
                            {teacherProfile?.WhatsappNo}
                            <Whatsapp style={styles.tepNo.icon}/>
                        </div>                                                      
                        <div style={styles.description}>{teacherProfile?.Description}</div>   
                    </div>                                
                </div>
            </>}
        </div>
    )
}