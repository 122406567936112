import { useEffect, useState } from "react";
import Slider from 'react-slick';
import { ImageSliderLoader } from "../../loaders/ImageSliderLoader";
import { getTeacherFeaturedAds } from "../../services/TeacherService";
import { TeacherFeaturedAd } from "../../models/TeacherFeaturedAd";
import { useNavigate } from "react-router-dom";
import { SEOKeywords } from "../../helpers/LayoutTexts";

interface Props {
    styles: any;
    title: string;
    content: string;
}

export const TeacherSlider: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styles = props.styles; 
    const [featuredAds, setFeaturedAds] = useState<TeacherFeaturedAd[]>([]);                         

    useEffect(() => {        
        getFeaturedAds();                
    }, []);
    
    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        dots: false,         
        rows: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        useTransform: false
    };        

    async function getFeaturedAds() {        
        await getTeacherFeaturedAds()
        .then((featuredAdList: TeacherFeaturedAd[]) => {
            setFeaturedAds(featuredAdList);                           
        })
        .catch((error) => {            
            //toErrorPage(error);
        });        
    }

    const toTeachers = () => {
        navigate('/teachers/search' + SEOKeywords.teacher);
    }
    
    return (
        <>
            {props.title ? <div style={styles}>
                <div style={styles.leftWrapper}>
                    <div style={styles.title}>
                        {props.title}
                    </div>
                    <div style={styles.content}>
                        {props.content}
                    </div>
                </div>
                <div style={styles.rightWrapper}>
                    <Slider {...sliderSettings}>
                        {featuredAds.map((featuredAd, index) =>
                            <div key={index} onClick={toTeachers}>
                                <img src={featuredAd.TeacherAdUrl} style={styles.slider} alt="TuteClass teacher"/>
                            </div>
                        )}
                    </Slider>
                </div>            
            </div>
            : <ImageSliderLoader styles={styles}/>}
        </>
    )
}