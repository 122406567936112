import { Color } from "../enums/Color";

export const toast = {
    mobile: {     
        position: "absolute" as "absolute", 
        top: "10%",   
        marginLeft: "5%",
        marginRight: "5%",               
        width: "-webkit-fill-available",                        
        textAlign: "center" as "center",
        padding: "8px 0",
        borderRadius: "20px",
        background: Color.MiddleAsh,                
        fontSize: "15px",
        color: Color.Black,
        zIndex: "2"
    },
    desktop: {
        top: "15%",
        left: "30%",
        width: "30%",
        height: "30px",
        lineHeight: "30px",
        fontSize: "16px"
    }
}