import React, { useState } from 'react';
import { Button } from '../Shared/Button';
import { Input } from '../Shared/Inputs/Input';
import { useNavigate } from 'react-router-dom';
import { AccountRegisterModel, AccountValidation } from '../../models/AccountRegisterModel';
import { register } from '../../services/AccountService';
import { ResponseModel } from '../../models/ResponseModel';
import { RegisterPageLoader } from '../../loaders/RegisterPageLoader';
import { getWhatsAppFormatNumber, validateEmail, validatePhoneNumber } from '../../helpers/Common';

interface Props {
    styles: any;  
    callbackUrl: string | null;
    callbackMessage: string | null;
    setError(isError: boolean): void;   
    setStage(stage: string): void;
    openMessageDialog(message: string): void;
}

const initialRegisterModel: AccountRegisterModel = {
    FirstName: "",
    LastName: "",
    WhatsappNo: "",           
    Email: "",    
    Password: "",
    ConfirmPassword: "",
    UserType: -1
}

const initialValidation: AccountValidation = {
    FirstName: {},
    LastName: {}, 
    WhatsappNo: {},         
    Email: {},
    Password: {},
    ConfirmPassword: {},
    UserType: {}
}

export const Register: React.FC<Props> = (props) => {
    const styles = props.styles;
    const navigate = useNavigate();    
    const [registerModel, setRegisterModel] = useState<AccountRegisterModel>(initialRegisterModel); 
    const [validation, setValidation] = useState<AccountValidation>(initialValidation);
    const [loading, setLoading] = useState<boolean>(false);   

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setRegisterModel((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }
    
    const validateInput = (name: string, value: string) => {        
        if(name === "FirstName" || name === "LastName" || name === "Password") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: name + " is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "WhatsappNo") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Whatsapp number is required"}}));
            } 
            else if (!validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "Email") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Email is required"}}));
            } 
            else if (!validateEmail(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid email address!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }  
        if(name === "ConfirmPassword") {
            const password = registerModel.Password;      
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password confirmation is required"}}));
            } 
            else if (password !== value) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }   
        if(name === "UserType") {
            if(value === "-1") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "User type confirmation is required"}}));
            }
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        } 
    } 
    
    const validateForm = () => {
        let formValidation: AccountValidation = {
            FirstName: {valid: true},
            LastName: {valid: true},
            WhatsappNo: {valid: true},
            Email: {valid: true},
            Password: {valid: true},
            ConfirmPassword: {valid: true},
            UserType: {valid: true}
        }        
        if(registerModel.FirstName === "") {
            formValidation.FirstName = {valid: false, invalid: true, invalidMessage: "FirstName is required"};
        }
        if(registerModel.LastName === "") {
            formValidation.LastName = {valid: false, invalid: true, invalidMessage: "LastName is required"};
        }
        if(registerModel.WhatsappNo === "") {
            formValidation.WhatsappNo = {valid: false, invalid: true, invalidMessage: "Whatsapp number is required"};
        } 
        else if(!validatePhoneNumber(registerModel.WhatsappNo)) {
            formValidation.WhatsappNo = {valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number!"};
        } 
        if(registerModel.Email === "") {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        } 
        else if (!validateEmail(registerModel.Email)) {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        } 
        if(registerModel.Password === "") {
            formValidation.Password = {valid: false, invalid: true, invalidMessage: "Password is required"};
        }
        if(registerModel.ConfirmPassword === "") {
            formValidation.ConfirmPassword = {valid: false, invalid: true, invalidMessage: "Password confirmation is required"};
        } 
        else if (registerModel.Password !== registerModel.ConfirmPassword) {
            formValidation.ConfirmPassword = {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"};
        }
        if(registerModel.UserType === -1) {
            formValidation.UserType = {valid: false, invalid: true, invalidMessage: "User type is required"};
        }
        setValidation(formValidation);        
    }

    async function handleRegistration(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault(); 
        const validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;
        if(validInputCount === 7) { 
            setLoading(true); 
            let finalRegisterModel = registerModel;
            finalRegisterModel.WhatsappNo = getWhatsAppFormatNumber(registerModel.WhatsappNo);                                      
            await register(finalRegisterModel)
            .then((response: ResponseModel) => {
                setLoading(false);
                if(response.Status) {
                    const userString = JSON.stringify(response.Data);
                    const userType: number = +registerModel.UserType;                
                    if(userType === 1) {                    
                        localStorage.setItem('teacher', userString);                
                    }
                    else if(userType === 2) {                
                        localStorage.setItem('student', userString);                
                    }            
                    if(props.callbackUrl) {
                        navigate(props.callbackUrl);
                    } else {
                        navigate('/');
                    }
                }
                else {
                    props.setError(true);
                    props.openMessageDialog(response.Message);
                }
            }) 
            .catch((error) => {
                setLoading(false);         
                //toErrorPage(error);
            }); 
        }
        else {                        
            validateForm();            
        }                   
    }

    return (
        <form style={styles.form} onSubmit={handleRegistration}>
            <h1 style={styles.title}>Register</h1>            
            {loading ? <RegisterPageLoader/> : <> 
                {props.callbackMessage && <div style={styles.callbackMessage}>{props.callbackMessage}</div>}           
                <Input 
                    type="text" 
                    name={"FirstName"} 
                    value={registerModel.FirstName} 
                    label={"First Name"} 
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.FirstName} 
                />
                <Input 
                    type="text" 
                    name={"LastName"} 
                    value={registerModel.LastName} 
                    label={"Last Name"} 
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}
                    validation={validation.LastName} 
                />    
                <div style={styles.rowWrapper}>
                    <div style={styles.leftRadioButton}>
                        <input id={"userType_1"} type="radio" name={"UserType"} value={1} onChange={handleInputChange} />
                        <label htmlFor={"userType_1"} style={styles.radioLabel}>{"Teacher"}</label>   
                    </div>
                    <div style={styles.rightRadioButton}>
                        <input id={"userType_2"} type="radio" name={"UserType"} value={2} onChange={handleInputChange} />
                        <label htmlFor={"userType_2"} style={styles.radioLabel}>{"Student"}</label>   
                    </div>
                </div>
                {validation.UserType.invalid && <div style={styles.invalidMessage}>{validation.UserType.invalidMessage}</div>}
                <Input 
                    type="text" 
                    name={"WhatsappNo"} 
                    value={registerModel.WhatsappNo} 
                    label={"Whatsapp Number"} 
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}
                    validation={validation.WhatsappNo} 
                />
                <Input 
                    type="text" 
                    name={"Email"} 
                    value={registerModel.Email} 
                    label={"Email"} 
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.Email} 
                />
                <Input 
                    type="password" 
                    name={"Password"} 
                    value={registerModel.Password} 
                    label={"Password"} 
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}
                    validation={validation.Password}
                />                  
                <Input 
                    type="password" 
                    name={"ConfirmPassword"} 
                    value={registerModel.ConfirmPassword} 
                    label={"ConfirmPassword"} 
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}
                    validation={validation.ConfirmPassword}
                />
                <p style={styles.agreement}>I'm agree to TuteClass Terms and Policy</p>
                <Button style={styles.submitButton} onClick={handleRegistration}>Register</Button>
            </>}
            <div style={styles.rowWrapper}>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.left}} onClick={() => {props.setStage("forgotPassword")}}>Forgot Password</Button>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.right}} onClick={() => {props.setStage("login")}}>Login</Button>
            </div> 
        </form>
    )
}