import React, { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/webviewer';
import { Keys } from '../../helpers/LayoutTexts';

interface Props {
    styles: any;
    document?: string | File;
}

export const DocumentViewer: React.FC<Props> = (props) => {
    const viewerRef = useRef(null);
    const webviewerInstance = useRef<any>(null);

    useEffect(() => {
        // Make sure to copy the files from node_modules/@pdftron/public to /public/pdftron before goes to production
        WebViewer(
            {
                path: "/pdftron",
                licenseKey: Keys.pdftron,
                disabledElements: ["header", "toolsHeader","textHighlightToolButton","textUnderlineToolButton",
                "copyTextButton","textSquigglyToolButton","textStrikeoutToolButton","linkButton"],                
                fullAPI: true,
                initialDoc: typeof(props.document) === "string" ? props.document : undefined     
            },
            viewerRef.current!
        ).then(instance => {
            webviewerInstance.current = instance;
            webviewerInstance.current.UI.loadDocument(props.document);                
        });
    }, [])

    useEffect(() => {
        if(webviewerInstance.current){
            webviewerInstance.current.UI.loadDocument(props.document);
        }
    }, [props.document])

    return <div style={props.styles} ref={viewerRef}></div>
}