import './App.css';
import { useEffect } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { StyleContextProvider } from './providers/StyleContextProvider';
import { HelmetProvider } from 'react-helmet-async';
import { HomePage } from './pages/HomePage';
import { GovernmentDegreeListPage } from './pages/Degree/GovernmentDegreeListPage';
import { GovernmentDegreeDisplayPage } from './pages/Degree/GovernmentDegreeDisplayPage';
import { PrivateDegreeListPage } from './pages/Degree/PrivateDegreeListPage';
import { PrivateDegreeDisplayPage } from './pages/Degree/PrivateDegreeDisplayPage';
import { GovernmentDiplomaListPage } from './pages/Diploma/GovernmentDiplomaListPage';
import { GovernmentDiplomaDisplayPage } from './pages/Diploma/GovernmentDiplomaDisplayPage';
import { PrivateDiplomaListPage } from './pages/Diploma/PrivateDiplomaListPage';
import { PrivateDiplomaDisplayPage } from './pages/Diploma/PrivateDiplomaDisplayPage';
import { NewAdPage } from './pages/Teacher/NewAdPage';
import { AdListPage } from './pages/Teacher/AdListPage';
import { TeacherProfilePage } from './pages/Teacher/TeacherProfilePage';
import { SettingsPage } from './pages/SettingsPage';
import { LoginRegisterPage } from './pages/LoginRegisterPage';
import { AuthContextProvider } from './providers/AuthContextProvider';
import { ChatPage } from './pages/ChatPage';
import { PaymentResponsePage } from './pages/PaymentResponsePage';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import { AdViewPage } from './pages/Teacher/AdViewPage';
import { BankDetailsPage } from './pages/BankDetailsPage';
import { CashPaymentPage } from './pages/Teacher/CashPaymentPage';
import { ClassListPage } from './pages/OnlineClass/ClassListPage';
import { TeacherClassPage } from './pages/OnlineClass/TeacherClassPage';
import { StudentClassPage } from './pages/OnlineClass/StudentClassPage';
import { ClassManagePage } from './pages/OnlineClass/ClassManagePage';
import { TeacherClassWeekPage } from './pages/OnlineClass/TeacherClassWeekPage';
import { WeekManagePage } from './pages/OnlineClass/WeekManagePage';

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <HomePage/> },
    { path: "governmentDegrees/:seo", element: <GovernmentDegreeListPage/> },
    { path: "governmentDegreeDisplay/:degreeId/:seo", element: <GovernmentDegreeDisplayPage/> },
    { path: "privateDegrees/:seo", element: <PrivateDegreeListPage/> },
    { path: "privateDegreeDisplay/:degreeId/:seo", element: <PrivateDegreeDisplayPage/> },       
    { path: "governmentDiplomas/:seo", element: <GovernmentDiplomaListPage/> },
    { path: "governmentDiplomaDisplay/:diplomaId/:seo", element: <GovernmentDiplomaDisplayPage/> },    
    { path: "privateDiplomas/:seo", element: <PrivateDiplomaListPage/> },
    { path: "privateDiplomaDisplay/:diplomaId/:seo", element: <PrivateDiplomaDisplayPage/> },
    { path: "teacherAds/:tab", element: <NewAdPage/> },
    { path: "teachers/:seo", element: <AdListPage/> },
    { path: "adView/:adId/:seo", element: <AdViewPage/> },
    { path: "teacherProfile/:teacherId", element: <TeacherProfilePage/> },
    { path: "teacherAds/cashPayment/:paymentId", element: <CashPaymentPage/> },
    { path: "onlineClasses/:seo", element: <ClassListPage/> },
    { path: "teacher/onlineClasses", element: <TeacherClassPage/> },
    { path: "student/onlineClasses", element: <StudentClassPage/> },
    { path: "teacher/classManage/:classId", element: <ClassManagePage/> },
    { path: "teacher/classWeeks/:classId", element: <TeacherClassWeekPage/> },
    { path: "teacher/weekManage/:classId/:weekId", element: <WeekManagePage/> },
    { path: "settings", element: <SettingsPage/> },
    { path: "chat", element: <ChatPage/> },
    { path: "account/:stage", element: <LoginRegisterPage/> },
    { path: "paymentResponse", element: <PaymentResponsePage/> },
    { path: "termsAndConditions", element: <TermsAndConditionsPage/> },
    { path: "bankDetails", element: <BankDetailsPage/> },
    { path: "*", element: <HomePage/> }
  ]);
  return routes;
};

function App() {
  
  useEffect(() => {
    if(localStorage.getItem('languageCode') === null) {
      localStorage.setItem('languageCode', "ENG");   
    }    
  });

  return (
    <StyleContextProvider>
      <AuthContextProvider>
        <BrowserRouter>
          <HelmetProvider>
            <AppRoutes/>
          </HelmetProvider>          
        </BrowserRouter>
      </AuthContextProvider>      
    </StyleContextProvider> 
  );
}

export default App;
