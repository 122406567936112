import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { ToolBar } from "../../components/Shared/ToolBar";
import { DetailLoader } from "../../loaders/DetailLoader";
import { Localization } from "../../models/Localization";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { validateCashCode } from "../../services/TeacherService";
import { Footer } from "../../components/Shared/Footer";
import { Button } from "../../components/Shared/Button";
import { useAlert } from "../../hooks/useAlert";
import { SEOKeywords } from "../../helpers/LayoutTexts";

interface StateProps { 
    price: string;
    whatsappNo: string; 
}

export const CashPaymentPage: React.FC = () => { 
    let params = useParams();  
    const location = useLocation(); 
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("cashPaymentPage")); 
    const state = location.state as StateProps;   
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const [cashCode, setCashCode] = useState<string>();
    const [cashInvalid, setCashInvalid] = useState<boolean>();
    const { Alert, openAlert } = useAlert();

    useEffect(() => {   
        window.scrollTo(0, 0);    
        getLocalizations();                               
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("cashPaymentPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(7, 35)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);               
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                       
    }    

    async function applyCashCode() {               
        if (cashCode) {
            setLoading(true);
            await validateCashCode(+params.paymentId!, cashCode, state.whatsappNo)
            .then((success: boolean) => {
                setLoading(false);
                if(success) {
                    openAlert("success", "Cash Code applied successfully");
                    navigate('/teacherAds/myAds');
                }
                else {
                    openAlert("error", "Cash Code is invalid");
                }
            })
            .catch((error) => {
                // toErrorPage(error);            
            });
        } else {            
            setCashInvalid(true);
            openAlert("error", "Cash Code is required");
        }        
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            {loading ? <DetailLoader/> : 
            <>
                <div style={styles.container}>
                    <div style={styles.description}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwDescription')?.ElementValue.replace("{amount}", state.price)}
                    </div>
                    <div style={styles.cashWrapper}>
                        <input
                            type={"text"}
                            name={"cashCode"}
                            placeholder={"Enter Cash Code"}
                            value={cashCode}
                            onChange={(e) => setCashCode(e.target.value)}                            
                            style={{...styles.cashInput, ...(cashInvalid && styles.cashInvalidInput)}}
                        />
                        <Button type="button" style={styles.applyButton} onClick={applyCashCode}>{"APPLY"}</Button>
                    </div>
                </div>
                {isMobile && <Footer/>}
            </>}
            <Alert/>
        </div>
    )
}