import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../providers/StyleContextProvider";
import { getPageLocalizations } from "../services/CommonService";
import { ReactComponent as Degrees } from "../assets/svgs/degrees.svg";
import { ReactComponent as Diplomas } from "../assets/svgs/diplomas.svg";
import { ReactComponent as Teachers } from "../assets/svgs/teachers.svg";
import { ReactComponent as Settings } from "../assets/svgs/settings.svg";
import { ReactComponent as QA } from "../assets/svgs/q_and_a.svg";
import { ReactComponent as OnlineClass } from "../assets/svgs/online-class.svg";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { Localization } from "../models/Localization";
import { Footer } from "../components/Shared/Footer";
import { Header } from "../components/Shared/Header";
import { SelectOption } from "../models/SelectOption";
import { TagLoader } from "../loaders/TagLoader";
import { DegreeSlider } from "../components/Home/DegreeSlider";
import { DiplomaSlider } from "../components/Home/DiplomaSlider";
import { TeacherSlider } from "../components/Home/TeacherSlider";
import { HomeTexts as Texts, SEOKeywords } from "../helpers/LayoutTexts";
import { HorizontalBanner } from "../components/Shared/HorizontalBanner";
import { Helmet } from "react-helmet-async";

export const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("homePage"));        
    const [keyword, setKeyword] = useState<string>("");
    const [causeTypeId, setCauseTypeId] = useState<string | number>(0);    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const bannerType = isMobile ? 5 : 6;

    useEffect(() => {  
        window.scrollTo(0, 0);    
        getLocalizations();                                    
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("homePage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(5, 30) as unknown as Localization[];
        setLocalizations(localizationList);                    
        setLoading(false);                 
    }
    
    const causeTypes: SelectOption[] = [
        { value: 0, option: "Government Degree", checked: false},
        { value: 1, option: "Private Degree", checked: false},
        { value: 2, option: "Government Diploma", checked: false},
        { value: 3, option: "Private Diploma", checked: false}                        
    ]

    const handleSearch = () => {                
        let pathname: string = "";
        switch(causeTypeId) {
            case 0:                                
                pathname = '/governmentDegreeList' + SEOKeywords.governmentDegree;
            break;
            case 1:                
                pathname = '/privateDegreeList' + SEOKeywords.privateDegree;                
            break;
            case 2:
                pathname = '/governmentDiplomaList' + SEOKeywords.governmentDiploma;
            break;
            case 3:
                pathname = '/privateDiplomaList' + SEOKeywords.privateDiploma;
            break;
        }
        let params: any = {isSearch: "true", keyword: keyword.trim() };
        navigate({
            pathname: pathname,
            search: `?${createSearchParams(params)}`,
        });
    }

    return (
        <>
            <Helmet>
                <title>{Texts.title}</title>
                <meta name="description" content={Texts.description}/>
            </Helmet>
            <div style={styles}>
                <Header 
                    styleSheet={"header"} 
                    searchEnable={true} 
                    setKeyword={setKeyword} 
                    searchSelectEnable={true} 
                    searchSelectLabel={"Cause Type"} 
                    searchSelectOptions={causeTypes} 
                    setSelectValue={setCauseTypeId} 
                    handleSearch={handleSearch}
                />            
                <div style={styles.wrapper}>
                    <div style={styles.item}>
                        <Link to={'/governmentDegrees' + SEOKeywords.governmentDegree} style={styles.pageTag}>
                            <Degrees/>
                            {loading ? <TagLoader/> : <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTextBtnDegrees')?.ElementValue}</div>}
                        </Link>
                    </div>
                    <div style={styles.item}>
                        <Link to={'/governmentDiplomas' + SEOKeywords.governmentDiploma} style={styles.pageTag}>
                            <Diplomas/>
                            {loading ? <TagLoader/> : <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTextBtnDiplomas')?.ElementValue}</div>}
                        </Link>
                    </div>                    
                    <div style={styles.item}>
                        <Link to={'/teachers' + SEOKeywords.teacher} style={styles.pageTag}>
                            <Teachers/>
                            {loading ? <TagLoader/> : <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTextBtnTeachers')?.ElementValue}</div>}
                        </Link>
                    </div>
                    <div style={styles.item}>
                        <Link to={'/onlineClasses' + SEOKeywords.onlineClass} style={styles.pageTag}>
                            <OnlineClass/>
                            {loading ? <TagLoader/> : <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTextBtnOnlineClasses')?.ElementValue}</div>}
                        </Link>
                    </div>
                    <div style={styles.item}>
                        <Link to={'/settings'} style={styles.pageTag}>
                            <Settings/>
                            {loading ? <TagLoader/> : <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTextBtnSettings')?.ElementValue}</div>}
                        </Link>
                    </div>                    
                    <div style={styles.item}>
                        <Link to={'/chat'} style={styles.pageTag}>
                            <QA style={styles.icon}/>
                            {loading ? <TagLoader/> : <div style={styles.label}>{localizations.find(({ ElementName }) => ElementName === 'vwTextBtnChat')?.ElementValue}</div>}
                        </Link>                                    
                    </div>
                </div>
                <DegreeSlider 
                    styles={styles.imageSlider} 
                    title={localizations.find(({ ElementName }) => ElementName === 'vwDegreeSliderTitle')?.ElementValue!} 
                    content={localizations.find(({ ElementName }) => ElementName === 'vwDegreeSliderContent')?.ElementValue!}
                />
                <DiplomaSlider 
                    styles={styles.contentSlider} 
                    title={localizations.find(({ ElementName }) => ElementName === 'vwDiplomaSliderTitle')?.ElementValue!} 
                    content={localizations.find(({ ElementName }) => ElementName === 'vwDiplomaSliderContent')?.ElementValue!}
                />
                <TeacherSlider 
                    styles={styles.teacherSlider} 
                    title={localizations.find(({ ElementName }) => ElementName === 'vwTeacherSliderTitle')?.ElementValue!} 
                    content={localizations.find(({ ElementName }) => ElementName === 'vwTeacherSliderContent')?.ElementValue!}
                />
                <div style={styles.banner}>
                    <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne} adsenseSlotTwo={Texts.adSlotTwo}/>
                </div>            
                <Footer/>
            </div>  
        </>      
    )
}