import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToolBar } from "../../components/Shared/ToolBar";
import { CardListLoader } from "../../loaders/CardListLoader";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { NoData } from "../../components/Shared/NoData";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Teacher } from "../../models/Teacher";
import { Button } from "../../components/Shared/Button";
import { ReactComponent as EditIcon } from "../../assets/svgs/edit.svg";
import { ReactComponent as BinIcon } from "../../assets/svgs/bin.svg";
import { ReactComponent as CalendarIcon } from "../../assets/svgs/calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/svgs/clock.svg";
import { ReactComponent as EyeIcon } from "../../assets/svgs/eye-open.svg";
import { ReactComponent as PlusIcon } from "../../assets/svgs/plus.svg";
import { OnlineClassWeek } from "../../models/OnlineClassWeek";
import { deleteOnlineClassWeek, getClassWeeks } from "../../services/ClassService";
import { getFormatedDate, getFormatedTime } from "../../helpers/Common";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { useAlert } from "../../hooks/useAlert";
import { PopUpDialog } from "../../components/Shared/Dialogs/PopUpDialog";

export const TeacherClassWeekPage: React.FC = () => {   
    const params = useParams(); 
    const location = useLocation();
    const navigate = useNavigate();
    const classId: number = +params.classId!;       
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("classListPage")); 
    const authContext = useContext(AuthContext);
    const teacher: Teacher | null = authContext.getLoggedTeacher();
    const [classWeeks, setClassWeeks] = useState<OnlineClassWeek[]>([]);    
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>();
    const {Alert, openAlert} = useAlert();                                       
    const bannerType = isMobile ? 7 : 8; 
    
    useEffect(() => {    
        window.scrollTo(0, 0);
        if(teacher !== null) {
            getWeekList();                               
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login or register to access the class week page." };
            navigate({
                pathname: '/account/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
    }, []);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("classListPage"));        
    }, [isMobile]);

    async function getWeekList() {                  
        await getClassWeeks(classId)
        .then((classWeekList: OnlineClassWeek[]) => {
            setClassWeeks(classWeekList);               
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }

    async function deleteClassWeek(weekId: number) {                  
        await deleteOnlineClassWeek(weekId)
        .then((success: boolean) => {  
            setOpenDialog(false);
            if(success) {
                openAlert("success", "Online class week has been deleted successfully.");  
                getWeekList();                                                       
            }
            else {
                openAlert("error", "Oops! something went wrong.");
            }
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }

    const viewClassWeek = (classWeekId: number) => {

    }

    const toManageClassWeek = (weekId: number) => {
        navigate(`/teacher/weekManage/${classId}/${weekId}`);
    }

    const openDeleteWarning = (weekId: number, title?: string) => {
        let content = <>
            <h1 style={styles.dialog.title}>{"Are you sure?"}</h1>
            <p style={styles.dialog.message}>{`${title} class week will be deleted permanently.`}</p>
            <div className="center-content" style={styles.dialog.buttons}>
                <Button style={{...styles.dialog.button, ...styles.dialog.button.blue}} onClick={closeDialog}>{"NO"}</Button>                
                <Button style={{...styles.dialog.button, ...styles.dialog.button.red}} onClick={() => deleteClassWeek(weekId)}>{"YES"}</Button>
            </div>
        </>        
        setDialogContent(content);        
        setOpenDialog(true);
    }
           
    const closeDialog = () => {
        setOpenDialog(false);
    }

    return (
        <>
            <ToolBar>{"Online Class Weeks"}</ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{"Online Class Weeks"}</h1>            
                    <div style={styles.week.container}>
                        {loading ? <CardListLoader/> : <>
                            {classWeeks.length === 0 ? <NoData/> : <>                                               
                                {classWeeks.map((classWeek, index) =>
                                    <div key={index} style={styles.week.card}> 
                                        <div style={styles.week.weekNo}>{"Week " + (index + 1)}</div>                                                                                                  
                                        <div style={styles.week.title}>
                                            {classWeek.Title}
                                        </div>                                   
                                        <div style={styles.week.dateWrapper}>
                                            <div style={styles.week.rowWrapper}>
                                                <CalendarIcon style={styles.week.calendarIcon}/>{getFormatedDate(classWeek.Date!)}
                                            </div>
                                            <div style={styles.week.rowWrapper}>
                                                <ClockIcon style={styles.week.clockIcon}/>{getFormatedTime(classWeek.Time!)}
                                            </div>                                    
                                        </div>
                                        <div style={styles.week.dateWrapper}>
                                            <span style={styles.week.studentCount}>{classWeek.StudentCount}</span>
                                            <span>{"Students"}</span>                                    
                                        </div>
                                        <div style={styles.week.buttonWrapper}>
                                            <Button style={styles.week.editButton} onClick={() => toManageClassWeek(classWeek.OnlineClassWeekId)}>
                                                {isMobile ? <EditIcon style={styles.week.editButton.icon} /> : "Edit"}
                                            </Button>                                        
                                            <Button style={styles.week.deleteButton} onClick={() => openDeleteWarning(classWeek.OnlineClassWeekId, classWeek.Title)}>
                                                {isMobile ? <BinIcon style={styles.week.deleteButton.icon} /> : "Delete"}
                                            </Button>
                                            <Button style={styles.week.viewButton} onClick={() => viewClassWeek(classWeek.OnlineClassWeekId)}>
                                                <EyeIcon style={styles.week.viewButton.icon} />{" View More"}
                                            </Button>
                                        </div>                                
                                    </div>
                                )}                        
                            </>}
                            <Button style={styles.week.addButton} onClick={() => toManageClassWeek(0)}>
                                {"Create New Week"}
                                <PlusIcon style={styles.week.addButton.icon} />
                            </Button>
                        </>}
                    </div>
                </div>
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>
            <PopUpDialog styles={styles.dialog.popUp} open={openDialog} toggle={closeDialog} closeIcon={true}>
                {dialogContent}
            </PopUpDialog> 
            <Alert/>            
        </>
    )
}