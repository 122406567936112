import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ToolBar } from "../../components/Shared/ToolBar";
import { StyleContext } from "../../providers/StyleContextProvider";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Teacher } from "../../models/Teacher";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { SearchContentLoader } from "../../loaders/SearchContentLoader";
import { Input } from "../../components/Shared/Inputs/Input";
import { MultySelectMultyMenuInput } from "../../components/Shared/Inputs/MultySelectMultyMenuInput";
import { InputLoader } from "../../loaders/InputLoader";
import { MultySelectInput } from "../../components/Shared/Inputs/MultySelectInput";
import { Button } from "../../components/Shared/Button";
import { Localization } from "../../models/Localization";
import { InputValidation } from "../../models/InputValidation";
import { Option } from "../../models/Option";
import { MenuOption } from "../../models/MenuOption";
import { getPageLocalizations } from "../../services/CommonService";
import { getOnlineClass, getSearchRequestModel, saveOnlineClass } from "../../services/ClassService";
import { TeacherSearchRequestModel } from "../../models/TeacherSearchRequestModel";
import { getGradeCategorySubjects } from "../../services/TeacherService";
import { getWhatsAppFormatNumber, validatePhoneNumber } from "../../helpers/Common";
import { OnlineClass } from "../../models/OnlineClass";
import { useAlert } from "../../hooks/useAlert";
import { QuillEditor } from "../../components/Shared/QuillEditor";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";

export const ClassManagePage: React.FC = () => {
    let params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const classId: number = +params.classId!;
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("classManagePage"));
    const authContext = useContext(AuthContext);
    const teacher: Teacher | null = authContext.getLoggedTeacher();
    const [loading, setLoading] = useState<boolean>(true);
    const [editLoading, setEditLoading] = useState<boolean>(true);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);    
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [teacherName, setTeacherName] = useState<string>("");
    const [nameValidation, setNameValidation] = useState<InputValidation>({});
    const [whatsappNo, setWhatsappNo] = useState<string>("");
    const [whatsappValidation, setWhatsappValidation] = useState<InputValidation>({});
    const [categoryGrades, setCategoryGrades] = useState<MenuOption[]>([]); 
    const [selectedGradeCategoryIds, setSelectedGradeCategoryIds] = useState<string>();  
    const [selectedGradeIds, setSelectedGradeIds] = useState<string>();
    const [subjects, setSubjects] = useState<Option[]>([]);    
    const [selectedSubjectIds, setSelectedSubjectIds] = useState<string>();
    const [subjectValidation, setSubjectValidation] = useState<InputValidation>({});
    const [mediums, setMediums] = useState<Option[]>([]);    
    const [selectedMediumIds, setSelectedMediumIds] = useState<string>();
    const [classTypes, setClassTypes] = useState<Option[]>([]);    
    const [selectedClassTypeIds, setSelectedClassTypeIds] = useState<string>();
    const [description, setDescription] = useState<string>();
    const fileInputRef = useRef<HTMLInputElement>(null); 
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(Texts.defaultTeacherAd);
    const [fileValidation, setFileValidation] = useState<InputValidation>({});    
    const {Alert, openAlert} = useAlert();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const bannerType = isMobile ? 7 : 8;            
    
    useEffect(() => {    
        window.scrollTo(0, 0);
        if(teacher !== null) {              
            getLocalizations();
            getRequestModelData();
            if(classId === 0) {
                setTeacherName(teacher.FirstName + " " + teacher.LastName);      
                setWhatsappNo(teacher.WhatsappNo);
            }    
            else {
                getOnlineClassData();
            }
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login to create a new class." };
            navigate({
                pathname: '/account/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("classManagePage"));          
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(7, 18)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getOnlineClassData() {  
        setEditLoading(true);              
        await getOnlineClass(classId)
        .then((classData: OnlineClass) => {            
            setTeacherName(classData.TeacherName);      
            setWhatsappNo(classData.WhatsappNo); 
            setSelectedGradeCategoryIds(classData.GradeCategoryIds);
            setSelectedGradeIds(classData.GradeIds); 
            setSelectedSubjectIds(classData.SubjectIds); 
            setSelectedMediumIds(classData.MediumIds);
            setSelectedClassTypeIds(classData.ClassTypeIds);
            setDescription(classData.Description);
            setImagePreviewUrl(classData.ClassImageUrl ? classData.ClassImageUrl : Texts.defaultTeacherAd);
            setEditLoading(false);                  
        })
        .catch((error) => {
            // toErrorPage(error);
        });                        
    }

    async function getRequestModelData() {                
        await getSearchRequestModel(selectedGradeCategoryIds)
        .then((requestModel: TeacherSearchRequestModel) => {            
            setCategoryGrades(requestModel.CategoryGrades);
            setSubjects(requestModel.Subjects);
            setMediums(requestModel.Mediums);
            setClassTypes(requestModel.ClassTypes);   
            setLoading(false);                  
        })
        .catch((error) => {
            // toErrorPage(error);
        });                        
    }

    async function getGradeCategorySubjectList(gradeCategoryIds: string) {        
        await getGradeCategorySubjects(gradeCategoryIds)
        .then((subjectList: Option[]) => {
            setSubjects(subjectList);
            setSubjectLoading(false);
        })
        .catch((error) => {
            // toErrorPage(error);
        });                    
    }

    const getSelectedGradeCategories = (gradeCategoryIds: string) => {
        setSubjectLoading(true);
        setSelectedGradeCategoryIds(gradeCategoryIds);
        setSelectedSubjectIds(undefined);                              
        getGradeCategorySubjectList(gradeCategoryIds);        
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "teacherName") {
            setTeacherName(value);
            if(nameValidation.invalid) {
                validateInput(name, value);
            }
        }
        else if(name === "whatsappNo") {
            setWhatsappNo(value);
            if(whatsappValidation.invalid) {
                validateInput(name, value);
            }
        }        
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }
    
    const validateInput = (name: string, value: string) => {        
        if(name === "teacherName") {
            if(value === "") {
                setNameValidation({valid: false, invalid: true, invalidMessage: "Teacher name is required"});
            } 
            else {
                setNameValidation({valid: true, invalid: false});
            }
        }
        if(name === "whatsappNo") {            
            if(value === "") {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            } 
            else if (!validatePhoneNumber(value)) {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"});
            } 
            else {
                setWhatsappValidation({valid: true, invalid: false});
            }
        }         
    } 
    
    const validateForm = (): boolean => {
        let valid: boolean = true;        
        if(teacherName === "") {
            setNameValidation({valid: false, invalid: true, invalidMessage: "Teacher name is required"});
            valid = false;
        }        
        if(whatsappNo === "") {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            valid = false;
        } 
        else if(!validatePhoneNumber(whatsappNo)) {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"});
            valid = false;
        }
        if(!selectedSubjectIds) {                                
            setSubjectValidation({valid: false, invalid: true, invalidMessage: "Subject is required"});
            valid = false;
        }
        // if(!selectedImage) {
        //     setFileValidation({valid: false, invalid: true, invalidMessage: "Advertisement is required"});
        //     valid = false;
        // }
        return valid;
    }
    
    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if(file.size > 0 && file.size <= 2097152) {
                setSelectedImage(file);
                setImagePreviewUrl(URL.createObjectURL(file));
                setFileValidation({valid: true, invalid: false});
            }
            else {
                setFileValidation({valid: false, invalid: true, invalidMessage: "File size is invalid"});
            }
        }
    };

    async function submitResponse(event: React.FormEvent<HTMLFormElement>) {    
        event.preventDefault();     
        var isValid = validateForm();
        if(isValid) {
            const responseModel: OnlineClass = {  
                OnlineClassId: classId,              
                TeacherId: teacher?.TeacherId!,
                TeacherName: teacherName,
                WhatsappNo: getWhatsAppFormatNumber(whatsappNo),
                GradeCategoryIds: selectedGradeCategoryIds,
                GradeIds: selectedGradeIds,                
                SubjectIds: selectedSubjectIds,                
                MediumIds: selectedMediumIds,                
                ClassTypeIds: selectedClassTypeIds,
                Description: description             
            }
            const responseString = JSON.stringify(responseModel);
            const formData = new FormData();
            formData.append('response', encodeURIComponent(responseString));             
            formData.append('adImage', selectedImage!);                      
            await saveOnlineClass(formData)
            .then((success: boolean) => {  
                if(success) {
                    openAlert("success", "Online class has been submitted successfully.");  
                    navigate("/teacher/onlineClasses");                                                       
                }
                else {
                    openAlert("error", "Oops! something went wrong.");
                }
            })
            .catch((error) => {            
                //toErrorPage(error);
            });  
        }             
    }  

    return (
        <>            
            <ToolBar>{localizations.find(({ ElementName }) => ElementName === 'vwClassTitle')?.ElementValue!}</ToolBar>
            <DesktopMenuDrawer/> 
            <div style={styles.container}>
                {(loading || editLoading) ? <SearchContentLoader/> : <div style={styles.leftWrapper}> 
                    <h1 style={styles.desktopTitle}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwClassTitle')?.ElementValue!}
                    </h1>
                    <form style={styles.class.formWrapper} onSubmit={submitResponse}> 
                        <Input 
                            type="text" 
                            name={"teacherName"} 
                            styles={styles.class.input}
                            value={teacherName} 
                            label={localizations.find(({ ElementName }) => ElementName === 'vwNameLabel')?.ElementValue!}
                            onChange={handleInputChange} 
                            onBlur={handleInputBlur}                   
                            validation={nameValidation} 
                        />
                        <Input 
                            type="text" 
                            name={"whatsappNo"} 
                            styles={styles.class.input}
                            value={whatsappNo} 
                            label={localizations.find(({ ElementName }) => ElementName === 'vwWhatsappLabel')?.ElementValue!}
                            onChange={handleInputChange} 
                            onBlur={handleInputBlur}
                            validation={whatsappValidation} 
                        />               
                        <MultySelectMultyMenuInput 
                            label={localizations.find(({ ElementName }) => ElementName === 'vwGradeLabel')?.ElementValue!}
                            menuOptions={categoryGrades} 
                            selectedOptionIds={selectedGradeIds}
                            setSelectedLabels={getSelectedGradeCategories}
                            setSelectedOptions={setSelectedGradeIds}
                            searchEnabled={true}
                        />
                        {subjectLoading ? <InputLoader/> :
                        <MultySelectInput 
                            label={localizations.find(({ ElementName }) => ElementName === 'vwSubjectLabel')?.ElementValue!}
                            options={subjects} 
                            selectedIds={selectedSubjectIds}
                            setSelected={setSelectedSubjectIds}
                            searchEnabled={true}
                            validation={subjectValidation}                    
                            setValidation={setSubjectValidation}
                        />}
                        <MultySelectInput 
                            label={localizations.find(({ ElementName }) => ElementName === 'vwMediumLabel')?.ElementValue!}                        
                            options={mediums} 
                            selectedIds={selectedMediumIds}
                            setSelected={setSelectedMediumIds}
                        />
                        <MultySelectInput 
                            label={localizations.find(({ ElementName }) => ElementName === 'vwClassTypeLabel')?.ElementValue!}                        
                            options={classTypes} 
                            selectedIds={selectedClassTypeIds}
                            setSelected={setSelectedClassTypeIds}
                        />  
                        <div style={styles.class.editorLabel}>{localizations.find(({ ElementName }) => ElementName === 'vwDescriptionLabel')?.ElementValue!}</div>
                        <QuillEditor 
                            value={description}
                            onChange={setDescription}
                            styles={styles.class.editor}
                        />              
                        <div>
                            <div style={styles.class.imageWarning}>{"MAX FILE SIZE: 2 MB"}</div>
                            <img src={imagePreviewUrl} alt="Teacher Ad" onClick={handleImageClick} style={{...styles.class.imageInput, ...(fileValidation.invalid && styles.class.imageInvalid)}}/>
                            <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef}/>
                            {fileValidation.invalid && <div style={styles.class.invalidMessage}>{fileValidation.invalidMessage}</div>}
                        </div>
                        <Button type="submit" style={styles.class.submitButton}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwBtnAdd')?.ElementValue}
                        </Button> 
                    </form>
                </div>}
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>            
            <Alert/>
        </>
    )
}