import React, { useContext, useEffect, useState } from 'react';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';

export interface Props {    
    long?: boolean;            
    children?: string;  
    setDisplay(show: boolean): void;  
};

export const Toast: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("toast")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const duration = props.long ? 4000 : 2000;
    
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("toast"));                                                                   
    }, [isMobile]);

    useEffect(() => {
        const interval = setInterval(() => {
          props.setDisplay(false);
        }, duration);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={styles}>            
            {props.children}
        </div>
    );
}