import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { InputLoader } from "../../loaders/InputLoader";
import { SearchContentLoader } from "../../loaders/SearchContentLoader";
import { Localization } from "../../models/Localization";
import { Option } from "../../models/Option";
import { MenuOption } from "../../models/MenuOption";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { getAdRequestModel, getDistrictTowns, getGradeCategorySubjects, postAdResponseModel, validatePromoCode } from "../../services/TeacherService";
import { Button } from "../Shared/Button";
import { DesktopMenuDrawer } from "../Shared/DesktopMenuDrawer";
import { TeacherSearchRequestModel } from "../../models/TeacherSearchRequestModel";
import { MultySelectMultyMenuInput } from "../Shared/Inputs/MultySelectMultyMenuInput";
import { MultySelectInput } from "../Shared/Inputs/MultySelectInput";
import { TeacherTexts as Texts } from '../../helpers/LayoutTexts';
import { TeacherAdResponseModel } from "../../models/TeacherAdResponseModel";
import { Teacher } from "../../models/Teacher";
import { WebXPayModel } from "../../models/WebXPayModel";
import $ from 'jquery'
import { PopUpDialog } from "../Shared/Dialogs/PopUpDialog";
import { InputValidation } from "../../models/InputValidation";
import { PromoCode } from "../../models/PromoCode";
import { Input } from "../Shared/Inputs/Input";
import { getWhatsAppFormatNumber, validatePhoneNumber } from "../../helpers/Common";
import { useNavigate } from "react-router-dom";

interface Props {
    teacher: Teacher;
}

export const NewAd: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("newAds"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [loading, setLoading] = useState<boolean>(true);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [townLoading, setTownLoading] = useState<boolean>(false);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [teacherName, setTeacherName] = useState<string>("");
    const [nameValidation, setNameValidation] = useState<InputValidation>({});
    const [whatsappNo, setWhatsappNo] = useState<string>("");
    const [whatsappValidation, setWhatsappValidation] = useState<InputValidation>({});
    const [categoryGrades, setCategoryGrades] = useState<MenuOption[]>([]); 
    const [selectedGradeCategoryIds, setSelectedGradeCategoryIds] = useState<string>();   
    const [selectedGradeIds, setSelectedGradeIds] = useState<string>();    
    const [subjects, setSubjects] = useState<Option[]>([]);    
    const [selectedSubjectIds, setSelectedSubjectIds] = useState<string>();
    const [subjectValidation, setSubjectValidation] = useState<InputValidation>({});
    const [mediums, setMediums] = useState<Option[]>([]);    
    const [selectedMediumIds, setSelectedMediumIds] = useState<string>();
    const [classTypes, setClassTypes] = useState<Option[]>([]);    
    const [selectedClassTypeIds, setSelectedClassTypeIds] = useState<string>();
    const [districts, setDistricts] = useState<Option[]>([]);
    const [selectedDistrictIds, setSelectedDistrictIds] = useState<string>();
    const [districtTowns, setDistrictTowns] = useState<MenuOption[]>([]);        
    const [selectedTownIds, setSelectedTownIds] = useState<string>();
    /* const fileInputRef = useRef<HTMLInputElement>(null); 
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(Texts.defaultTeacherAd);
    const [fileValidation, setFileValidation] = useState<InputValidation>({}); */   
    const [openDialog, setOpenDialog] = useState<boolean>(false);   
    const [promoCode, setPromoCode] = useState<string>();
    const [discountRate, setDiscountRate] = useState<number>();
    const [discount, setDiscount] = useState<number>();    
    const [promoInvalid, setPromoInvalid] = useState<boolean>(false);
    const [promoInvalidMessage, setPromoInvalidMessage] = useState<string>();
    const teacher = props.teacher;
    const paymentType: number = 1;
    const price: number = 990;

    useEffect(() => {    
        window.scrollTo(0, 0);
        getLocalizations();  
        getRequestModelData();                                    
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("newAds"));          
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(7, 18) as unknown as Localization[];
        setLocalizations(localizationList);        
    }
        
    async function getRequestModelData() {        
        await getAdRequestModel(selectedGradeCategoryIds, selectedDistrictIds)
        .then((requestModel: TeacherSearchRequestModel) => {            
            setCategoryGrades(requestModel.CategoryGrades);
            setSubjects(requestModel.Subjects);
            setMediums(requestModel.Mediums);
            setClassTypes(requestModel.ClassTypes);
            setDistricts(requestModel.Districts);
            setDistrictTowns(requestModel.DistrictTowns);            
            setLoading(false);            
        })
        .catch((error) => {
            // toErrorPage(error);
        });              
    }

    async function getGradeCategorySubjectList(gradeCategoryIds?: string) {        
        await getGradeCategorySubjects(gradeCategoryIds)
        .then((subjectList: Option[]) => {
            setSubjects(subjectList);
            setSubjectLoading(false);
        })
        .catch((error) => {
            // toErrorPage(error);
        });                    
    }

    async function getDistrictTownList(districtIds?: string) {                
        await getDistrictTowns(districtIds)
        .then((districtTownList: MenuOption[]) => {            
            setDistrictTowns(districtTownList);
            setTownLoading(false);           
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }
    
    const getSelectedGradeCategories = (gradeCategoryIds?: string) => {
        setSubjectLoading(true);
        setSelectedGradeCategoryIds(gradeCategoryIds);
        setSelectedSubjectIds(undefined);                              
        getGradeCategorySubjectList(gradeCategoryIds);        
    }

    const getSelectedDistricts = (districtIds?: string) => {
        setTownLoading(true);
        setSelectedDistrictIds(districtIds);
        setSelectedTownIds(undefined);                               
        getDistrictTownList(districtIds);        
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "teacherName") {
            setTeacherName(value);
            if(nameValidation.invalid) {
                validateInput(name, value);
            }
        }
        else if(name === "whatsappNo") {
            setWhatsappNo(value);
            if(whatsappValidation.invalid) {
                validateInput(name, value);
            }
        }        
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }
    
    const validateInput = (name: string, value: string) => {        
        if(name === "teacherName") {
            if(value === "") {
                setNameValidation({valid: false, invalid: true, invalidMessage: "Teacher name is required"});
            } 
            else {
                setNameValidation({valid: true, invalid: false});
            }
        }
        if(name === "whatsappNo") {            
            if(value === "") {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            } 
            else if (!validatePhoneNumber(value)) {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"});
            } 
            else {
                setWhatsappValidation({valid: true, invalid: false});
            }
        }         
    } 
    
    const validateForm = (): boolean => {
        let valid: boolean = true;        
        if(teacherName === "") {
            setNameValidation({valid: false, invalid: true, invalidMessage: "Teacher name is required"});
            valid = false;
        }        
        if(whatsappNo === "") {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            valid = false;
        } 
        else if(!validatePhoneNumber(whatsappNo)) {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"});
            valid = false;
        }
        if(!selectedSubjectIds) {                                
            setSubjectValidation({valid: false, invalid: true, invalidMessage: "Subject is required"});
            valid = false;
        }
        /* if(!selectedImage) {
            setFileValidation({valid: false, invalid: true, invalidMessage: "Advertisement is required"});
            valid = false;
        } */
        return valid;
    }
    
    /* const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if(file.size > 0 && file.size <= 2097152) {
                setSelectedImage(file);
                setImagePreviewUrl(URL.createObjectURL(file));
                setFileValidation({valid: true, invalid: false});
            }
            else {
                setFileValidation({valid: false, invalid: true, invalidMessage: "File size is invalid"});
            }
        }
    }; */

    const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {        
        setPromoCode(event.target.value);        
        if(promoInvalid) {
            setPromoInvalid(false);
            setPromoInvalidMessage(undefined);
        }
    }

    async function applyPromoCode() {               
        if (promoCode) {
            await validatePromoCode(teacher.TeacherId, promoCode, paymentType)
            .then((promoResponse: PromoCode) => {
                if(promoResponse) {                                   
                    let discountRate = promoResponse.Discount;
                    let deduction = (price*discountRate)/100;
                    setDiscountRate(discountRate);
                    setDiscount(deduction);                    
                } else {
                    setDiscount(undefined);
                    setPromoInvalid(true);
                    setPromoInvalidMessage("Invalid Promo Code. Try Again");
                }
            })
            .catch((error) => {
                // toErrorPage(error);            
            });
        } else {            
            setPromoInvalid(true);
            setPromoInvalidMessage("Promo Code is required");
        }        
    }

    const submitResponse = () => {         
        var isValid = validateForm();
        if(isValid) {   
            setOpenDialog(true);
        }             
    }

    async function postResponse (isOnlinePayment: boolean, isBankPayment: boolean, isCashPayment: boolean) {  
        setOpenDialog(false);        
        setLoading(true); 
        let finalDistrictIds = selectedDistrictIds; 
        if(selectedClassTypeIds && selectedClassTypeIds.includes("4")) {                 
            if(!selectedDistrictIds) {
                finalDistrictIds = "0";
            }
            else {
                finalDistrictIds = "0," + selectedDistrictIds;
            }
        }      
        const responseModel: TeacherAdResponseModel = {
            TeacherId: teacher.TeacherId,
            TeacherName: teacherName,            
            Email: teacher.Email,
            WhatsappNo: getWhatsAppFormatNumber(whatsappNo),
            GradeCategoryIds: selectedGradeCategoryIds,
            GradeIds: selectedGradeIds,
            SubjectIds: selectedSubjectIds,
            MediumIds: selectedMediumIds,
            ClassTypeIds: selectedClassTypeIds,
            DistrictIds: finalDistrictIds,
            TownIds: selectedTownIds,
            PromoCode: promoCode,
            IsOnlinePayment: isOnlinePayment        
        }
        const responseString = JSON.stringify(responseModel);
        const formData = new FormData();
        formData.append('response', responseString);  
        // formData.append('adImage', selectedImage!);
        await postAdResponseModel(formData)
        .then((payModel: any) => {  
            if(isOnlinePayment) {     
                const webXPayModel: WebXPayModel = payModel;
                $('.pay').append('<form action="' + webXPayModel.PaymentUrl + '" id="form" name="form" method="post" style="display:none;">' +
                    '<input type="text" name="first_name" value="' + webXPayModel.FirstName + '" />' +
                    '<input type="text" name="last_name" value="' + webXPayModel.LastName + '" />' +
                    '<input type="text" name="email" value="' + webXPayModel.Email + '" />' +
                    '<input type="text" name="contact_number" value="' + webXPayModel.ContactNumber + '" />' +
                    '<input type="text" name="address_line_one" value="' + webXPayModel.Address + '" />' +
                    '<input type="text" name="process_currency" value="' + webXPayModel.Currency + '" />' +
                    '<input type="text" name="secret_key" value="' + webXPayModel.SecretKey + '" />' +
                    '<input type="text" name="custom_fields" value="' + webXPayModel.CustomFields + '" />' +
                    '<input type="text" name="payment" value="' + webXPayModel.PaymentStr + '" />' +
                '</form>');            
                var paymentForm = document.getElementById("form") as HTMLFormElement;                
                paymentForm.submit();
            }
            if(isBankPayment) {
                navigate('/bankDetails', {
                    state: { price: discount ? price - discount : price }
                })
            }
            if(isCashPayment) {
                var paymentId: number = payModel;
                navigate('/teacherAds/cashPayment/' + paymentId, {
                    state: { price: discount ? price - discount : price, whatsappNo: getWhatsAppFormatNumber(whatsappNo) }
                })
            }
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                       
    }

    return (
        <>
            <DesktopMenuDrawer/>            
            {loading ? <SearchContentLoader/> : 
            <> 
                <Input 
                    type="text" 
                    name={"teacherName"} 
                    styles={styles.input}
                    value={teacherName} 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwNameLabel')?.ElementValue!}
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}                   
                    validation={nameValidation} 
                />
                <Input 
                    type="text" 
                    name={"whatsappNo"} 
                    styles={styles.input}
                    value={whatsappNo} 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwWhatsappLabel')?.ElementValue!}
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}
                    validation={whatsappValidation} 
                />               
                <MultySelectMultyMenuInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwGradeLabel')?.ElementValue!}
                    menuOptions={categoryGrades} 
                    setSelectedLabels={getSelectedGradeCategories}
                    setSelectedOptions={setSelectedGradeIds}
                    searchEnabled={true}
                />
                {subjectLoading ? <InputLoader/> :
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwSubjectLabel')?.ElementValue!}
                    options={subjects} 
                    setSelected={setSelectedSubjectIds}
                    searchEnabled={true}
                    validation={subjectValidation}                    
                    setValidation={setSubjectValidation}
                />}
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwMediumLabel')?.ElementValue!}                        
                    options={mediums} 
                    setSelected={setSelectedMediumIds}
                />
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwClassTypeLabel')?.ElementValue!}                        
                    options={classTypes} 
                    setSelected={setSelectedClassTypeIds}
                />                
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwDistrictLabel')?.ElementValue!}
                    options={districts} 
                    setSelected={getSelectedDistricts}
                    searchEnabled={true}                    
                />
                {townLoading ? <InputLoader/> :
                <MultySelectMultyMenuInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwTownLabel')?.ElementValue!}
                    menuOptions={districtTowns} 
                    setSelectedLabels={setSelectedDistrictIds}
                    setSelectedOptions={setSelectedTownIds}
                    searchEnabled={true}
                />}
                {/* <div>
                    <div style={styles.imageWarning}>{"MAX FILE SIZE: 2 MB"}</div>
                    <img src={imagePreviewUrl} alt="Teacher Ad" onClick={handleImageClick} style={{...styles.imageInput, ...(fileValidation.invalid && styles.imageInvalid)}}/>
                    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef}/>
                    {fileValidation.invalid && <div style={styles.invalidMessage}>{fileValidation.invalidMessage}</div>}
                </div> */}
                {!openDialog && <Button style={styles.searchButton} onClick={submitResponse}>
                    {localizations.find(({ ElementName }) => ElementName === 'vwBtnAdd')?.ElementValue}
                </Button>}                
            </>}            
            <div className="pay"></div>
            <PopUpDialog styles={styles.paymentDialog} open={openDialog} toggle={() => setOpenDialog(!openDialog)}>
                <p style={styles.paymentMessage}>{localizations.find(({ ElementName }) => ElementName === 'vwPaymentMessage')?.ElementValue!}</p>                           
                <div style={styles.promoWrapper}>
                    <input
                        type={"text"}
                        name={"promoCode"}
                        placeholder={"Have a Promo Code?"}
                        value={promoCode}
                        onChange={handlePromoCodeChange}
                        style={{...styles.promoInput, ...(promoInvalid && styles.promoInvalidInput)}}
                        disabled={discount !== undefined}
                    />
                    <Button type="button" style={styles.applyButton} onClick={applyPromoCode} disabled={discount !== undefined}>{"APPLY"}</Button>
                </div>
                {promoInvalid && <div style={styles.promoInvalidMessage}>{promoInvalidMessage}</div>}
                {discount && <>
                    <div style={styles.discountLabel}>{discountRate + localizations.find(({ ElementName }) => ElementName === 'vwDiscountMessage')?.ElementValue!}<span style={styles.discount}>{discount.toFixed(2)}</span></div>
                    <div style={styles.discountLabel}>{localizations.find(({ ElementName }) => ElementName === 'vwNewPriceMessage')?.ElementValue!}<span style={styles.discount}>{(price - discount).toFixed(2)}</span></div>
                </>}
                <div style={styles.payButtonWrapper}>
                    <Button style={styles.onlinePayButton} type="button" onClick={() => postResponse(true, false, false)}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwOnlinePayButton')?.ElementValue!}
                    </Button>                    
                    <Button style={styles.bankPayButton} type="button" onClick={() => postResponse(false, true, false)}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwBankPayButton')?.ElementValue!}
                    </Button>
                    <Button style={styles.cashPayButton} type="button" onClick={() => postResponse(false, false, true)}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwCashPayButton')?.ElementValue!}
                    </Button>
                </div>
            </PopUpDialog>
        </>
    )
}