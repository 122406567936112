import { Color } from "../enums/Color";

export const myAds = {
    mobile: {        
        container: {
            marginTop: "20px"
        },
        card: {
            width: "100%",
            border: "2px solid black",
            borderRadius: "10px",
            marginBottom: "10px"
        },
        image: {
            height: "198px",
            width: "-webkit-fill-available",
            borderRadius: "10px 10px 0 0"            
        },
        label: {
            margin: "0 0 6px 6px",
            fontSize: "12px",
            fontWeight: "bold"
        },
        initialLabel: {
            marginTop: "12px"
        },
        value: {
            fontWeight: "normal"
        },
        link: {
            textDecoration: "none",
            color: Color.Black
        },
        buttonWrapper: {
            display: "flex",
            justifyContent: "right",
            margin: "10px"            
        },
        editButton: {
            border: "2px solid " + Color.Green,
            borderRadius: "10px",
            color: Color.Green,
            padding: "6px 10px",
            marginRight: "12px",
            fontWeight: "bold",
            backgroundColor: Color.SharpGreen
        },
        deleteButton: {
            border: "1px solid " + Color.Red,
            borderRadius: "10px",
            color: Color.Red,
            padding: "6px 10px",
            marginRight: "12px",
            fontWeight: "bold",            
            backgroundColor: Color.Linen
        },
        dialog: {
            dialog: {
                height: "20%",
                top: "40%"
            },
            title: {
                height: "25%"      
            },
            container: {
                height: "60%",
                overflow: "auto"            
            },            
            buttonWrapper: {
                paddingTop: "0px"
            },
            button: {  
                clear: {
                    float: "left"
                }                
            }
        },
        buttonsWrapper: {
            display: "flex",
            flexDirection: "row",
            margin: "12px 0",
            justifyContent: "space-between"
        },
        statusButton: {  
            width: "auto",
            height: "32px",          
            background: "transparent",
            fontSize: "12px",
            fontWeight: "bold",                        
            borderRadius: "6px",            
            live: {
                color: Color.Green
            },
            pending: {
                color: Color.LemonCurry
            },
            unpaid: {
                color: Color.Red
            },
            active: {
                width: "82px",
                background: Color.Green,
                color: Color.White
            }
        }
    },
    desktop: {                       
        container: {
            marginTop: "50px"            
        }, 
        card: {                        
            borderRadius: "20px"
        },
        image: {
            height: "334px",
            borderRadius: "20px 20px 0 0"
        },       
        dialog: {
            dialog: {                       
                top: "30%",
                right: "36.3%",
                padding: "2%"
            },
            container: {
                height: "48%",                
                paddingTop: "2%"          
            }
        },
        buttonsWrapper: {
            display: "flex",
            flexDirection: "row",
            margin: "16px 0"
        },
        statusButton: {              
            height: "40px",                      
            fontSize: "16px",
            borderRadius: "8px",           
            active: {
                width: "132px"
            }
        }
    }
}