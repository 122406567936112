
export function validateEmail(email: string): boolean {
    const emailRegex = /\S+@\S+\.\S+/;    
    return emailRegex.test(email);
}

export const validatePhoneNumber = (number: string) => {
    //Valid numbers : +947********, 947********, +9407********, 9407********, 07********, 7********
    let regex = /^(?:\+94|94|\+940|940|0)?(7\d{8})$/;  
    return regex.test(number);  
}

export const getWhatsAppFormatNumber = (number: string) : string => {
    let whatsAppFormatNumber: string = "";
    let regex = /^(?:\+94|94|\+940|940|0)?(7\d{8})$/;  
    if(regex.test(number)) {
        whatsAppFormatNumber = "94" + number.substring(number.length - 9);
    }
    return whatsAppFormatNumber;
}

export const getSeoName = (value: string) => {    
    return value
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-')     // Replace spaces with hyphens
    .trim();                  // Trim any leading/trailing spaces
}

export const getFormatedDate = (date: Date | string) => {
    if (date) {
        let formatedDate = new Date(date).toLocaleDateString("en-us", {
            year: "numeric",
            month: "long",
            day: "numeric"
        });
        return formatedDate;
    }
};

export const getFormatedTime = (time24: string): string => {
    const [hoursStr, minutesStr] = time24.split(':');
    let hours = parseInt(hoursStr);
    const minutes = parseInt(minutesStr);
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
  
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  
    return `${hours}:${formattedMinutes} ${ampm}`;
};