import React from "react"
import ContentLoader from "react-content-loader"
import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive";
import { Color } from "../enums/Color"

const loaderStyles = {
    mobile: {                
        width: "100%",
        margin: "13% 0 0 0"
    },
    desktop: {        
        width: "44%",
        margin: "4% 0 0 30%"
    }
};

export const ChatPageLoader: React.FC = (props) => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var styles  = loaderStyles.mobile; 

    if(!isMobile) {           
        let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
        styles = extendStyles;
    }

    return (        
        <div style={styles}>
            <ContentLoader 
                speed={2}
                width={"100%"}
                height={660}          
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                {...props}
            >
                <rect x="5%" y="10" rx="3" ry="3" width="90%" height="70" />
                <rect x="5%" y="610" rx="30" ry="30" width="75%" height="50" />
                <rect x="83%" y="610" rx="30" ry="30" width="14%" height="50" />
            </ContentLoader>
        </div>
    )
}