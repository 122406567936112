import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';
import { Degree } from '../../models/Degree';

interface Props {    
    degree: Degree;
};

export const DegreeCard: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("card")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const languageCode = localStorage.getItem('languageCode');
    const degree = props.degree;
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("card"));                                                                             
    }, [isMobile]);

    return (
        <div style={styles.wrapper}>                        
            <div>
                <img src={degree.UniversityLogoUrl} style={styles.logo}></img>
            </div>
            <div>
                <div style={styles.university}>{degree.UniversityName}</div>
                <div style={styles.degree}>{degree.DegreeName}</div>
                {(languageCode !== "ENG") && <div style={styles.degreeSecondary}>{degree.DegreeName_ENG}</div>}
            </div>
        </div>        
    );
}