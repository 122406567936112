import React, { useContext, useEffect, useState } from 'react';
import { Option } from '../../../models/Option';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { merge } from 'react-merge';

interface Props { 
    styles?: any;
    streamId?: number;      
    title: string;          
    options: Option[];
    subjectSelectionError?: string;
    getSelected(selectedOptions: Option[]): void;
    openToastMessage?(message: string): void;
    closeDialog(): void;    
};

export const SingleSelectFilterDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [options, setOptions] = useState<Option[]>(props.options); 
    const [filteredOptions, setFilteredOptions] = useState<Option[]>(props.options);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const languageCode = localStorage.getItem('languageCode')!;           

    useEffect(() => {   
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);                                                                  
    }, [isMobile]);

    const itemChecked = (value: number) => {        
        let tempOptions = [...options];        
        let prevSelectIndex = tempOptions.findIndex(({ Checked: checked }) => checked === true);
        if(prevSelectIndex != -1) {        
            tempOptions[prevSelectIndex].Checked = false;
        }
        tempOptions.filter(option => option.Value === value)[0].Checked = true;        
        setOptions(tempOptions);  
        
        let tempFilteredOptions = [...filteredOptions];        
        let prevSelectFilteredIndex = tempFilteredOptions.findIndex(({ Checked: checked }) => checked === true);
        if(prevSelectFilteredIndex != -1) {        
            tempFilteredOptions[prevSelectFilteredIndex].Checked = false;
        }
        tempFilteredOptions.filter(option => option.Value === value)[0].Checked = true;        
        setFilteredOptions(tempFilteredOptions);
        props.getSelected(tempOptions);
    }    

    const filterOptions = (keyword: string) => {    
        keyword = keyword.trim();    
        let tempFilteredOptions = options.filter(option => (
            option.PrimaryOption.toLowerCase().includes(keyword.toLowerCase()) ||
            option.SecondaryOption && option.SecondaryOption.toLowerCase().includes(keyword.toLowerCase())
        ));          
        setFilteredOptions(tempFilteredOptions);
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={{...styles.dialog, ...styles.filterDialog}}>
                <div style={styles.title}>{props.title}</div>
                <input type="text" style={styles.search} placeholder={"Search"} onChange={(e) => filterOptions(e.target.value)}/>
                <div style={styles.container} className={"selector"}>
                    {filteredOptions.map((option, index) =>                                     
                        <div key={index} style={styles.optionWrapper} onClick={() => itemChecked(option.Value)}>                                                    
                            <input type={"radio"} checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                            <div style={styles.option}>
                                {option.PrimaryOption}    
                                {languageCode !== "ENG" && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                            </div>
                        </div>                    
                    )}    
                </div>
            </div> 
        </>       
    );
}