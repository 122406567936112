import { Color } from "../enums/Color";

export const cashPaymentPage = {
    mobile: {        
        desktopTitle: {
            display: "none"
        },                    
        container: {                                        
            marginTop: "98px",
            paddingLeft: "5%",
            paddingRight: "5%"
        },        
        description: { },
        cashWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            marginTop: "42px"
        },
        cashInput: {
            height: "4.76vh",
            width: "-webkit-fill-available",
            borderRadius: "1.2vw",
            padding: "0 2.78vw",
            backgroundColor: Color.White,
            border: "1px solid " + Color.SharpAsh
        },
        cashInvalidInput: {
            border: "1px solid " + Color.Red
        },
        applyButton: {
            width: "22.70vw",
            height: "4.76vh",
            border: "2px solid " + Color.Blue,
            color: Color.Blue,
            background: "transparent",            
            fontWeight: "bold" as "bold",                     
            borderRadius: "1.2vw",            
            marginLeft: "4.36vw"
        }       
    },
    desktop: {
        desktopTitle: {
            display: "block",
            textAlign: "center" as "center",
            color: Color.Green,
            marginTop: "50px"
        },        
        container: {
            marginTop: "50px",
            paddingLeft: "32%",
            paddingRight: "32%"
        },
        description: {
            marginBottom: "42px"
        },
        cashInput: {
            height: "50px",
            borderRadius: "0.52vw",
            padding: "0 0.70vw",
            fontSize: "1.12vw"
        },
        applyButton: {
            height: "50px",
            width: "8.07vw",            
            fontSize: "1.12vw",            
            borderRadius: "0.52vw",            
            marginLeft: "1.36vw"
        }
    }
}