import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const classListPage = {
    mobile: {                 
        desktopTitle: {
            display: "none"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",             
            padding: "0 5%"            
        },
        listCount: {
            fontSize: "4vw",
            color: Color.Green,            
            fontWeight: "bold",
            marginBottom: "10px"
        },        
        filter: {
            position: "relative",
            margin: "70px 0 0 0",                       
            backgroundColor: Color.LightAsh,
            borderRadius: "2.41vw"            
        },
        filterExpandButton: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "10.86vw",
            lineHeight: "10.86vw",           
            paddingLeft: "3.62vw",
            paddingRight: "3.62vw",            
            border: "none",
            backgroundColor: Color.DarkAsh,
            borderRadius: "2.41vw",        
            cursor: "pointer",
            boxSizing: "border-box",
            WebkitTapHighlightColor: 'transparent',
            fontWeight: "bold",
            expand: {
                borderRadius: "2.41vw 2.41vw 0 0"
            }
        },        
        expandButtonIcon: {
            height: "4.58vw",
            width: "4.1vw",
            margin: "auto",
            marginRight: "0",
            transition: "transform .1s ease-in-out",
            up: {
                transform: "rotate(180deg)"
            }
        },
        filterContent: {
            padding: "0 4.83vw 4.83vw 4.83vw"
        },
        classButton: {
            display: "flex",
            backgroundColor: Color.Black,
            color: Color.White,
            padding: "8px 16px",
            margin: "70px 0 -50px auto",
            fontWeight: "bold" as "bold",
            fontSize: "14px",
            borderRadius: "1.41vw",
            noMargin: {
                margin: "70px 0 0 auto"
            }
        },                                
        class: {
            container: {
                margin: "20px 0 50px 0"
            },
            card: {
                display: "flex",
                flexDirection: "row",
                borderBottom: "2px solid " + Color.Green,                        
                padding: "10px 0",            
                alignItems: "center",
                cursor: "pointer"
            },
            contentWrapper: {
                display: "flex",
                flexDirection: "row"
            },
            imageWrapper: {
                width: "28.98vw"
            },
            image: {
                height: "28.98vw",
                width: "28.98vw",
                borderRadius: "0.31vw",                
                objectFit: "cover",
                cursor: "pointer"
            },
            detailWrapper: {
                width: "59.09vw",
                marginLeft: "1.93vw"
            },
            name: {
                fontSize: "3.86vw",
                fontWeight: "bold"              
            },
            subjects: {
                fontSize: "3.38vw",
                fontWeight: "bold",
                color: Color.PhilippineGray,
                marginTop: "1.21vw"
            },
            grades: {
                fontSize: "2.66vw",
                fontWeight: "bold",
                color: Color.DarkLiver,
                marginTop: "1.21vw"
            },
            see: {
                fontSize: "13px",
                fontWeight: "bold",
                color: Color.Green,
                marginTop: "2.41vw"
            },
            buttonWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "3.38vw" 
            },
            editButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.DeepBlack,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.White
                }
            },
            viewButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.AliceBlue,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.Blue
                }
            },
            deleteButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Linen,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.Red
                }
            },
            weekButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28.98vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Nyanza,
                color: Color.MediumGreen,
                fontWeight: "700",
                marginLeft: "auto",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.MediumGreen,
                    marginRight: "1.93vw"
                }
            }
        },        
        week: {
            container: {
                margin: "70px 0 50px 0"
            },
            card: {
                borderBottom: "2px solid " + Color.Green,
                paddingBottom: "4.34vw"
            },
            weekNo: {
                fontSize: "2.41vw",
                fontWeight: "bold",
                color: Color.DarkLiver
            },
            title: {
                fontSize: "3.86vw",
                fontWeight: "bold",
                color: Color.Black
            },
            dateWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "1.54vw",
                fontSize: "3.38vw",
                fontWeight: "bold",
                color: Color.PhilippineGray
            },
            rowWrapper: {
                display: "flex",
                alignItems: "center"
            },
            calendarIcon: {
                height: "3.8vw",
                width: "3.38vw",
                fill: Color.DeepAsh,
                marginRight: "1.87vw"
            },
            clockIcon: {
                height: "3.8vw",
                width: "3.38vw",
                fill: Color.DeepAsh,
                marginRight: "1.87vw",
                marginLeft: "10.14vw"
            },
            studentCount: {
                fontWeight: "bold",
                color: Color.Black,
                marginRight: "1.21vw"
            },            
            buttonWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "3.38vw" 
            },
            editButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.DeepBlack,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.White
                }
            },            
            deleteButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Linen,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.Red
                }
            },
            viewButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28.98vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Nyanza,
                color: Color.MediumGreen,
                fontWeight: "700",
                marginLeft: "auto",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.MediumGreen,
                    marginRight: "1.21vw"
                }
            },
            addButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "63.04vw",
                height: "12.08vw",
                border: "none",
                backgroundColor: Color.Orange,
                color: Color.White,
                borderRadius: "2.41vw",
                fontSize: "3.86vw",
                fontWeight: "bold",
                margin: "10.86vw auto",
                icon: {
                    width: "4.13vw",
                    height: "4.13vw",
                    fill: Color.White,
                    marginLeft: "2.41vw"
                }
            }
        },
        dialog: {
            popUp: {
                container: {
                    width: "calc(100% - 9.66vw)",
                    height: "unset",
                    padding: "7.246vw 4.93vw",
                    borderRadius: "2.415vw 2.415vw 0 0"
                }
            },
            title: {
                marginTop: "unset",
                marginBottom: "7vw",
                fontSize: "7.24vw",
                color: Color.LightBlack
            },
            message: {
                margin: "4.83vw 0 0 0",
                fontSize: "3.86vw",
                color: Color.DarkLiver,
                font: Font.Default,
                fontWeight: "600"
            },
            buttons: {
                justifyContent: "end"
            },
            button: {
                width: "50%",
                height: "12.07vw",
                fontWeight: "bold",
                fontSize: "4.83vw",
                borderRadius: "2.41vw",
                margin: "7.24vw 0 0 0",
                blue: {
                    border: "3px solid " + Color.Blue,
                    color: Color.Blue,
                    background: "transparent",
                    marginRight: "2.89vw",
                },
                red: {
                    border: "none",
                    color: Color.Red,
                    backgroundColor: Color.LightRed
                }
            }
        }     
    },
    desktop: {        
        desktopTitle: {
            display: "block",            
            textAlign: "center" as "center",
            color: Color.Green,
            background: Color.White,
            margin: 0,
            zIndex: 1
        },
        container: { 
            flexDirection: "row" as "row",
            margin: "35px 0 20px 0",     
            padding: "0 8% 0 32%"            
        }, 
        leftWrapper: {
            width: "60%"
        },
        rightWrapper: {
            width: "34%",
            margin: "60px 0 0 6%"
        }, 
        listCount: {
            fontSize: "1.34vw"
        },        
        filter: {
            position: "unset",
            margin: "20px 0 0 0",
            borderRadius: "0.73vw"
        },
        filterExpandButton: {
            height: "3.2vw",            
            paddingLeft: "1.62vw",
            paddingRight: "1.62vw",
            lineHeight: "3.2vw",
            borderRadius: "0.73vw",
            expand: {
                borderRadius: "0.73vw 0.73vw 0 0"
            }
        },        
        expandButtonIcon: {
            width: "0.8vw",
            height: "0.8vw"
        },
        filterContent: {
            padding: "0 1.62vw 1.62vw 1.62vw"
        },
        classButton: {
            width: "13.59vw",
            height: "2.60vw",
            padding: "unset",
            margin: "20px 0 0 auto",
            fontSize: "0.83vw",
            borderRadius: "0.52vw",
            alignItems: "center",
            justifyContent: "center",
            noMargin: {
                margin: "20px 0 0 auto"
            }
        },
        class: {
            imageWrapper: {
                width: "7.29vw"
            },
            image: {
                height: "7.29vw",
                width: "7.29vw",
                borderRadius: "8px"
            },
            detailWrapper: {
                width: "auto",
                marginLeft: "12px"
            },
            name: {
                fontSize: "1.04vw"                
            },
            subjects: {
                fontSize: "1.04",
                marginTop: "0.42vw"
            },
            grades: {
                fontSize: "12px",
                marginTop: "0.42vw"
            },
            see: {
                fontSize: "14px",
                marginTop: "0.42vw"
            },
            buttonWrapper: {
                marginTop: "1.56vw" 
            },
            editButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.White,
                marginRight: "0.83vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw"
                }
            },
            viewButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                marginRight: "0.83vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw",
                    fill: Color.Blue
                }
            },
            deleteButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.Red,
                marginRight: "0.83vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw"
                }
            },
            weekButton: {
                width: "13.50vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw",
                    marginRight: "0.83vw"
                }
            }
        },        
        week: {
            container: { },
            card: {
                paddingBottom: "0.96vw"
            },
            weekNo: {
                fontSize: "0.73vw"
            },
            title: {
                fontSize: "1.04vw"
            },
            dateWrapper: {
                marginTop: "0.625vw",
                fontSize: "0.83vw"
            },
            calendarIcon: {
                height: "0.94vw",
                width: "0.94vw",
                marginRight: "0.81vw"
            },
            clockIcon: {
                height: "0.94vw",
                width: "0.94vw",
                marginRight: "0.81vw",
                marginLeft: "2.86vw"
            },
            studentCount: {
                marginRight: "0.31vw"
            },
            buttonWrapper: {
                marginTop: "1.56vw" 
            },
            editButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.White,
                marginRight: "0.83vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.White
                }
            },
            deleteButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.Red,
                marginRight: "0.83vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw"
                }
            },
            viewButton: {
                width: "13.50vw",
                height: "1.82vw",
                borderRadius: "0.36vw",               
                icon: {
                    width: "0.94vw",
                    height: "0.94vw",
                    marginRight: "0.52vw"
                }
            },
            addButton: {
                width: "13.59vw",
                height: "2.60vw",
                borderRadius: "0.52vw",
                fontSize: "0.83vw",
                margin: "2.60vw auto",
                icon: {
                    width: "0.88vw",
                    height: "0.88vw",
                    marginLeft: "0.52vw"
                }
            }
        },
        dialog: {
            popUp: {
                container: {
                    width: "21.33vw",
                    height: "unset",
                    padding: "3.489vw 3.54vw",
                    borderRadius: "0.52vw"
                }
            },
            title: {
                fontSize: "1.56vw",
                color: Color.LightBlack,
                marginBottom: "1.51vw"
            },
            message: {
                margin: "1.51vw 0 0 0",
                fontSize: "0.83vw",
                color: Color.DarkLiver,
                lineHeight: "1.5"
            },
            button: {
                width: "10.15vw",
                height: "2.604vw",
                fontSize: "1.041vw",
                borderRadius: "0.52vw",
                margin: "1.25vw 0 0 0",
                blue: {
                    border: "3px solid " + Color.Blue,
                    color: Color.Blue,
                    background: "transparent",
                    marginRight: "0.625vw",
                },
                red: {
                    border: "none",
                    color: Color.Red,
                    backgroundColor: Color.Linen
                }
            }
        } 
    }
}