import { Color } from "../enums/Color";

export const homePage = {
    mobile: {            
        wrapper: {                           
            margin: "20px 3% 0 3%",            
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
        },
        item: {
            width: "50%",
            boxSizing: "border-box", 
            marginTop: "70px",
            textAlign: "center"
        },
        pageTag: {
            textDecoration: "none"
        },
        icon: {
            height: "70px",
            width: "90px",
            marginBottom: "10px"
        },
        label: {
            color: Color.Green,            
            fontWeight: "bold",
            textAlign: "center"
        },
        imageSlider: {                      
            display: "flex",
            flexDirection: "column" as "column",            
            margin: "50px 4% 0 4%",  
            overflow: "hidden",      
            leftWrapper: { },
            rightWrapper: { },
            title: {                
                fontSize: "22px",
                fontWeight: "bold",
                color: Color.SharpAsh
            },
            content: {
                marginTop: "20px",
                fontSize: "16px",   
                lineHeight: "1.4",
                color: Color.SharpAsh
            },
            slider: {
                width: "98%",
                height: "520px", 
                border: "2px solid black",
                borderRadius: "20px",
                margin: "10px 1%"
            }
        },
        contentSlider: { 
            display: "flex",                     
            flexDirection: "column-reverse" as "column-reverse",            
            margin: "50px 4% 0 4%",  
            overflow: "hidden",                 
            leftWrapper: { },
            rightWrapper: { },
            title: {
                fontSize: "22px",
                fontWeight: "bold",
                color: Color.SharpAsh
            },
            content: {
                marginTop: "20px",
                fontSize: "16px",   
                lineHeight: "1.4",
                color: Color.SharpAsh
            },
            slider: {
                width: "98%",
                height: "520px", 
                border: "2px solid black",
                borderRadius: "20px",
                margin: "10px 1%",
                overflow: "hidden"
            },
            card: {
                padding: "12px",                
                titleWrapper: {
                    display: "flex",
                    flexDirection: "row" as "row",
                    justifyContent: "center",
                    alignItems: "center"
                },
                logo: {
                    height: "40px",
                    weight: "40px"
                },
                title: {
                    textAlign: "center",                                                        
                    fontWeight: "bold",
                    fontSize: "14px"
                },
                titleSecondary: {
                    textAlign: "center",                                                                   
                    fontSize: "12px"
                },
                label: {
                    marginTop: "14px",
                    textAlign: "left",                                                        
                    fontWeight: "bold",
                    fontSize: "12px"
                },
                content: {
                    fontSize: "12px !important" 
                }
            }
        },
        teacherSlider: {                      
            display: "flex",
            flexDirection: "column" as "column",            
            margin: "50px 4% 0 4%",  
            overflow: "hidden",      
            leftWrapper: { },
            rightWrapper: { },
            title: {                
                fontSize: "22px",
                fontWeight: "bold",
                color: Color.SharpAsh
            },
            content: {
                marginTop: "20px",
                fontSize: "16px",   
                lineHeight: "1.4",
                color: Color.SharpAsh
            },
            slider: {
                width: "98%",                
                border: "2px solid black",
                borderRadius: "20px",
                margin: "10px 1%"
            }
        },
        banner: {
            margin: "50px 4% 0 4%"
        }
    },
    desktop: {           
        wrapper: { 
            margin: "20px 3% 0 3%"            
        },
        item: {
            width: "33%",            
            marginTop: "70px"            
        },      
        label: {            
            fontSize: "20px"
        },
        imageSlider: {                      
            flexDirection: "row" as "row",
            margin: "100px 8%",
            leftWrapper: {
                width: "35%",
                marginRight: "5%"
            },
            rightWrapper: {
                width: "55%",
                marginLeft: "5%"
            },
            title: {
                marginTop: "10px",
                fontSize: "36px"
            },
            content: {
                marginTop: "30px",
                fontSize: "20px"
            },
            slider: {
                width: "96%",
                height: "640px",                
                borderRadius: "20px",
                margin: "10px 2%",                
                cursor: "pointer",            
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)"                
            }
        },
        contentSlider: {                      
            flexDirection: "row" as "row",
            margin: "100px 8%",
            leftWrapper: {
                width: "55%",
                marginRight: "5%"                
            },
            rightWrapper: {
                width: "35%",
                marginLeft: "5%"
            },
            title: {
                marginTop: "10px",
                fontSize: "36px",
                fontWeight: "bold",
                color: Color.SharpAsh
            },
            content: {
                marginTop: "30px",
                fontSize: "20px",   
                lineHeight: "1.4",
                color: Color.SharpAsh
            },
            slider: {
                width: "96%",
                height: "640px",
                margin: "10px 2%",                              
                cursor: "pointer",            
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)"                                     
            },
            card: {
                padding: "20px",
                logo: {
                    height: "50px",
                    weight: "50px"
                },
                title: {
                    fontSize: "18px"
                },
                titleSecondary: {                                                                   
                    fontSize: "16px"
                },
                label: {
                    marginTop: "20px",
                    fontSize: "14px"
                }
            }
        },
        teacherSlider: {                      
            flexDirection: "row" as "row",
            margin: "100px 8%",
            leftWrapper: {
                width: "35%",
                marginRight: "5%"
            },
            rightWrapper: {
                width: "55%",
                marginLeft: "5%"
            },
            title: {
                marginTop: "10px",
                fontSize: "36px"
            },
            content: {
                marginTop: "30px",
                fontSize: "20px"
            },
            slider: {
                width: "96%",
                height: "640px",                
                borderRadius: "20px",
                margin: "10px 2%",                
                cursor: "pointer",            
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)"                
            }
        },
        banner: {
            margin: "0 8%"
        }
    }
}