import { Color } from "../enums/Color";

export const teacherListPage = {
    mobile: {                 
        desktopTitle: {
            display: "none"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",                
            padding: "0 5%"            
        },
        myAdButton: {
            display: "flex",
            backgroundColor: Color.Black,
            color: Color.White,
            padding: "8px 16px",
            margin: "70px 0 -50px auto",
            fontWeight: "bold" as "bold",
            fontSize: "14px",
            borderRadius: "1.41vw"
        },
        filter: {
            position: "relative",
            margin: "70px 0 0 0",                       
            backgroundColor: Color.LightAsh,
            borderRadius: "2.41vw"            
        },
        filterExpandButton: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "10.86vw",
            lineHeight: "10.86vw",           
            paddingLeft: "3.62vw",
            paddingRight: "3.62vw",            
            border: "none",
            backgroundColor: Color.DarkAsh,
            borderRadius: "2.41vw",        
            cursor: "pointer",
            boxSizing: "border-box",
            WebkitTapHighlightColor: 'transparent',
            fontWeight: "bold",
            expand: {
                borderRadius: "2.41vw 2.41vw 0 0"
            }
        },        
        expandButtonIcon: {
            height: "4.58vw",
            width: "4.1vw",
            margin: "auto",
            marginRight: "0",
            transition: "transform .1s ease-in-out",
            up: {
                transform: "rotate(180deg)"
            }
        },
        filterContent: {
            padding: "0 4.83vw 4.83vw 4.83vw"
        },
        searchWrapper: {
            width: "-webkit-fill-available",            
            top: "50px",
            paddingTop: "20px",
            background: Color.White
        },
        searchInput: {
            height: "38px",
            width: "-webkit-fill-available",
            backgroundColor: Color.White,
            border: "2px solid " + Color.Green,
            borderRadius: "20px",
            padding: "0 15px"
        },
        link: {
            textDecoration: "none",
            color: Color.Black
        },
        dialog: {
            dialog: {
                height: "20%",
                top: "40%"
            },
            title: {
                height: "25%"      
            },
            container: {
                height: "60%",
                overflow: "auto"            
            },            
            buttonWrapper: {
                paddingTop: "0px"
            },
            button: {            
                float: "right"
            }
        },
        listCount: {
            fontSize: "4vw",
            color: Color.Green,            
            fontWeight: "bold",
            marginBottom: "10px"
        },
        listContainer: {
            margin: "20px 0 50px 0"
        },
        image: {
            height: "198px",
            width: "-webkit-fill-available",
            borderRadius: "10px",
            marginBottom: "10px",
            objectFit: "cover"
        },
        adCard: {
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid " + Color.Green,                        
            padding: "10px 0",            
            alignItems: "center",
            cursor: "pointer",
            image: {
                height: "120px",
                width: "120px",
                borderRadius: "6px",                
                objectFit: "cover",
                cursor: "pointer"
            },
            detailWrapper: {
                marginLeft: "8px"
            },
            name: {
                fontSize: "18px"                
            },
            subjects: {
                fontSize: "13px"
            },
            districts: {
                fontSize: "13px"
            },
            type: {
                fontSize: "13px"
            },
            see: {
                fontSize: "13px",
                fontWeight: "bold",
                color: Color.Green
            }
        },                
        switch: {
            wrapper: {
                display: "flex",
                marginTop: "16px"
            },
            label: {
                fontSize: "16px"                               
            }
        }
    },
    desktop: {        
        desktopTitle: {
            display: "block",            
            textAlign: "center" as "center",
            color: Color.Green,
            background: Color.White,
            margin: 0,
            zIndex: 1
        }, 
        container: { 
            flexDirection: "row" as "row",
            margin: "35px 0 20px 0",     
            padding: "0 8% 0 32%"            
        }, 
        leftWrapper: {
            width: "60%"
        },
        rightWrapper: {
            width: "34%",
            margin: "60px 0 0 6%"
        },
        myAdButton: {
            width: "13.59vw",
            height: "2.60vw",
            padding: "unset",
            margin: "20px 0 0 auto",
            fontSize: "0.83vw",
            borderRadius: "0.52vw",
            alignItems: "center",
            justifyContent: "center"
        },
        filter: {
            position: "unset",
            margin: "20px 0 0 0",
            borderRadius: "0.73vw"
        },
        filterExpandButton: {
            height: "3.2vw",            
            paddingLeft: "1.62vw",
            paddingRight: "1.62vw",
            lineHeight: "3.2vw",
            borderRadius: "0.73vw",
            expand: {
                borderRadius: "0.73vw 0.73vw 0 0"
            }
        },        
        expandButtonIcon: {
            width: "0.8vw",
            height: "0.8vw"
        },
        filterContent: {
            padding: "0 1.62vw 1.62vw 1.62vw"
        },
        searchWrapper: {            
            top: "86px",
            padding: "10px 32%" ,
            textAlign: "center" as "center"           
        },
        searchInput: {            
            width: "86%"
        },       
        dialog: {
            dialog: {                       
                top: "30%",
                right: "35%",
                padding: "2%"
            },
            container: {
                height: "48%",                
                paddingTop: "2%"          
            }
        },        
        listCount: {
            fontSize: "1.34vw"
        },
        image: {
            height: "334px",
            borderRadius: "20px"
        },
        adCard: {
            padding: "12px 0",            
            alignItems: "center",
            image: {
                height: "140px",
                width: "140px",
                borderRadius: "8px"
            },
            detailWrapper: {
                marginLeft: "12px"
            },
            name: {
                fontSize: "20px"                
            },
            subjects: {
                fontSize: "14px"
            },
            districts: {
                fontSize: "14px"
            },
            type: {
                fontSize: "14px"
            },
            see: {
                fontSize: "14px"
            }
        },                
        switch: {
            wrapper: {                
                marginTop: "18px"
            },
            label: {
                fontSize: "18px"                             
            }
        }
    }
}