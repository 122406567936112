import Axios from "axios";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { TeacherSearchResponseModel } from "../models/TeacherSearchResponseModel";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

export async function getOnlineClass(classId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/class", {            
            params: {
                classId: classId
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getOnlineClassWeek(weekId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/week", {            
            params: {
                weekId: weekId
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getSearchRequestModel(gradeCategoryIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/searchRequestModel", {            
            params: {
                gradeCategoryIds: gradeCategoryIds ? gradeCategoryIds : "",                
                languageCode: localStorage.getItem('languageCode')!
            },            
            cache: {                
                ttl: 1000 * 60 * 60 * 24 // 1 day
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postSearchResponseModel(responseModel: TeacherSearchResponseModel) {
    responseModel.LanguageCode = localStorage.getItem('languageCode')!;
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/searchResponseModel", responseModel, {                                
            cache: {    
                methods: ["post"],            
                ttl: 1000 * 60 * 5 // 5 min
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherOnlineClasses(page: number, pageSize: number, teacherId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/teacherOnlineClasses", {                                
            params: {
                page: page,
                pageSize: pageSize,
                teacherId: teacherId,
                languageCode: localStorage.getItem('languageCode')!             
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function saveOnlineClass(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/saveOnlineClass", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function deleteOnlineClass(classId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .delete(Texts.apiBaseUrl + "onlineClass/deleteOnlineClass", {                                
            params: {
                classId: classId            
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getClassWeeks(classId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/teacher/classWeeks", {                                
            params: {
                classId: classId            
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function saveClassWeek(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/saveClassWeek", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function deleteOnlineClassWeek(weekId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .delete(Texts.apiBaseUrl + "onlineClass/deleteOnlineClassWeek", {                                
            params: {
                weekId: weekId            
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}