import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Option } from "../../../models/Option";
import { StyleContext } from "../../../providers/StyleContextProvider";
import { DialogInput } from "../../Shared/Inputs/DialogInput";
import { MultySelectDialog } from "../Dialogs/MultySelectDialog";
import { InputValidation } from "../../../models/InputValidation";
import { MultySelectFilterDialog } from "../Dialogs/MultySelectFilterDialog";

interface Props {
    label: string;    
    options: Option[];
    selectedIds?: string;
    setSelected(selectedIds?: string): void;
    searchEnabled?: boolean;
    validation?: InputValidation;
    setValidation?(validation: InputValidation): void;
}

export const MultySelectInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [options, setOptions] = useState<Option[]>(props.options);    
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
                            
    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("input"));          
    }, [isMobile]);

    useEffect(() => {        
        setInitialSelects();        
    }, [props.selectedIds]);
    
    const setInitialSelects = () => {        
        if(props.selectedIds && props.selectedIds !== "") {     
            let selectedIdList: string[] = props.selectedIds!.split(",");
            let selectedOptionList: string[] = [];        
            props.options.forEach(option => {
                if(selectedIdList.includes(option.Value.toString())) {                             
                    option.Checked = true; 
                    selectedOptionList.push(option.PrimaryOption);
                }
            });        
            setSelectedOptions(selectedOptionList);
        }
        else {
            props.options.forEach(option => {option.Checked = false});
            setSelectedOptions([]);
        }
        setOptions(props.options);
    }

    const getSelected = (resultOptions: Option[]) => {  
        let selectedIds: string = "";
        let tempSelectedOptions: string[] = [];           
        resultOptions.forEach((option) => {
            if(option.Checked) {
                selectedIds = selectedIds + option.Value + ",";
                tempSelectedOptions.push(option.PrimaryOption);
            }
        });  
        selectedIds = selectedIds.substring(0, selectedIds.length - 1);  
        props.setSelected(selectedIds);
        setSelectedOptions(tempSelectedOptions);        
        setOptions(resultOptions);        
        setOpenDialog(false);     
        if(props.setValidation) {
            props.setValidation({});
        }  
    }    

    const resetOptions = () => {        
        setSelectedOptions([]);
        let tempOptions = [...options];
        tempOptions.forEach(option => {option.Checked = false});
        setOptions(tempOptions);
        props.setSelected();
    }

    return (
        <div style={styles}>                
            <div style={styles.inputWrapper} onClick={() => setOpenDialog(true)}>
                <DialogInput values={selectedOptions} validation={props.validation} onReset={resetOptions}>
                    {props.label}
                </DialogInput>
            </div>     
            {openDialog && (props.searchEnabled ? <MultySelectFilterDialog title={props.label} options={options} getSelected={getSelected} closeDialog={() => setOpenDialog(false)}/>
            : <MultySelectDialog title={props.label} options={options} getSelected={getSelected} closeDialog={() => setOpenDialog(false)}/>)}            
        </div>
    )
}