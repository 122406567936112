import React from "react"
import ContentLoader from "react-content-loader"
import { Color } from "../enums/Color"

const styles = {
    width: "100%",
    margin: "18% 0 0 0"
};

export const SearchContentLoader: React.FC = (props) => {

    return (        
        <div style={styles}>
            <ContentLoader 
                speed={2}
                width={"100%"}
                height={400}                
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                {...props}
            >
                <rect x="0%" y="0" rx="3" ry="3" width="90%" height="20" /> 
                <rect x="95%" y="0" rx="3" ry="3" width="5%" height="20" />
                <rect x="0%" y="80" rx="3" ry="3" width="90%" height="20" /> 
                <rect x="95%" y="80" rx="3" ry="3" width="5%" height="20" />
                <rect x="0%" y="160" rx="3" ry="3" width="90%" height="20" /> 
                <rect x="95%" y="160" rx="3" ry="3" width="5%" height="20" />
                <rect x="0%" y="240" rx="3" ry="3" width="90%" height="20" /> 
                <rect x="95%" y="240" rx="3" ry="3" width="5%" height="20" />
                <rect x="0%" y="320" rx="3" ry="3" width="100%" height="50" />                
            </ContentLoader>
        </div>
    )
}