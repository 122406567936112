import React from "react"
import ContentLoader from "react-content-loader"
import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive";
import { Color } from "../enums/Color"

const loaderStyles = {
    mobile: {                
        width: "100%",
        margin: "4% 0 0 0",
        title: {
            display: "none"
        }
    },
    desktop: {        
        width: "40%",
        margin: "4% 0 0 30%",
        title: {
            display: "inline"
        }
    }
};

export const SettingsPageLoader: React.FC = (props) => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var styles  = loaderStyles.mobile; 

    if(!isMobile) {           
        let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
        styles = extendStyles;
    }

    return (        
        <div style={styles}>
            <ContentLoader 
                speed={2}
                width={"100%"}
                height={220}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                {...props}
            >                
                {!isMobile && <rect x="31%" y="0" rx="3" ry="3" width="30%" height="40"/>}                
                <rect x="5%" y="100" rx="3" ry="3" width="80%" height="20"/>
                <rect x="90%" y="100" rx="3" ry="3" width="5%" height="20"/>                
                <rect x="5%" y="160" rx="3" ry="3" width="90%" height="50"/>
            </ContentLoader>
        </div>
    )
}