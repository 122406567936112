import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '../Shared/Button';
import { Input } from '../Shared/Inputs/Input';
import { RegisterPageLoader } from '../../loaders/RegisterPageLoader';
import { ProfileValidation, Teacher } from '../../models/Teacher';
import { getTeacherProfile, postTeacherProfile } from '../../services/TeacherService';
import { StyleContext } from '../../providers/StyleContextProvider';
import { TextAreaInput } from '../Shared/Inputs/TextAreaInput';
import { useMediaQuery } from 'react-responsive';
import { HeaderTexts as Texts } from '../../helpers/LayoutTexts';
import { validateEmail, validatePhoneNumber } from '../../helpers/Common';
import { ResponseModel } from '../../models/ResponseModel';

interface Props {
    teacherId: number;
    setToastMessage(message: string): void;
    setOpenToast(open: boolean): void;
    openAlert(status: string, message: string): void;
}

const initialValidation: ProfileValidation = {
    FirstName: {valid: true},
    LastName: {valid: true}, 
    TepNo: {valid: true}, 
    WhatsappNo: {valid: true},        
    Email: {valid: true}
}

export const Profile: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("profilePage"));   
    const [teacher, setTeacher] = useState<Teacher>();
    const [validation, setValidation] = useState<ProfileValidation>(initialValidation);
    const fileInputRef = useRef<HTMLInputElement>(null);  
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(Texts.defaultAvatar);
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });   

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("profilePage"));        
    }, [isMobile]);

    useEffect(() => {    
        getProfile();        
    }, []);

    async function getProfile() {
        await getTeacherProfile(props.teacherId)
        .then((teacherProfile: Teacher) => {            
            setTeacher(teacherProfile);
            if(teacherProfile.Image !== "") {
                setImagePreviewUrl(teacherProfile.Image);
            }            
            setLoading(false);            
        })
        .catch((error) => {
            // toErrorPage(error);
        });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setTeacher((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedImage(file);
            setImagePreviewUrl(URL.createObjectURL(file));
        }
    };
    
    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {        
        if(name === "FirstName" || name === "LastName") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: name + " is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "TepNo") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Phone number is required"}}));
            } 
            else if (!validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid phone number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "WhatsappNo") {            
            if (value !== "" && !validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "Email") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Email is required"}}));
            } 
            else if (!validateEmail(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid email address!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }     
    }    

    const validateForm = () => {
        let formValidation: ProfileValidation = {
            FirstName: {valid: true},
            LastName: {valid: true},
            TepNo: {valid: true},
            WhatsappNo: {valid: true},
            Email: {valid: true}
        }        
        if(teacher!.FirstName === "") {
            formValidation.Forename = {valid: false, invalid: true, invalidMessage: "FirstName is required"};
        }
        if(teacher!.LastName === "") {
            formValidation.Surname = {valid: false, invalid: true, invalidMessage: "LastName is required"};
        }
        if(teacher!.TepNo === "") {
            formValidation.TepNo = {valid: false, invalid: true, invalidMessage: "Phone number is required"};
        } 
        else if (!validatePhoneNumber(teacher!.TepNo)) {
            formValidation.TepNo = {valid: false, invalid: true, invalidMessage: "Invalid phone number"};
        } 
        if (teacher!.WhatsappNo !== "" && !validatePhoneNumber(teacher!.WhatsappNo)) {
            formValidation.WhatsappNo = {valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"};
        }
        if(teacher!.Email === "") {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        } 
        else if (!validateEmail(teacher!.Email)) {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        } 
        setValidation(formValidation);        
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {        
        event.preventDefault();     
        const validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;        
        if(validInputCount === 5) {               
            setLoading(true);   
            const teacherString = JSON.stringify(teacher);
            const formData = new FormData();
            formData.append('teacher', teacherString);  
            formData.append('profileImage', selectedImage!);
            await postTeacherProfile(formData)
            .then((response: ResponseModel) => {
                setLoading(false);
                if(response.Status) {
                    props.openAlert("success", "Profile has been updated successfully");
                    localStorage.setItem('teacher', response.Data);                                       
                }
                else {
                    props.openAlert("error", "Oops! something went wrong");
                }
            })
            .catch((error) => { 
                setLoading(false);           
                //toErrorPage(error);
            });
        } else {            
            validateForm();
        } 
    }

    return (
        <form onSubmit={handleSubmit}>            
            {loading ? <RegisterPageLoader/> : <>
                <div style={styles.imageWrapper}>
                    <img src={imagePreviewUrl} alt="Profile Image" onClick={handleImageClick} style={styles.image}/>
                    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef}/>
                </div>            
                <Input 
                    type="text" 
                    name={"FirstName"} 
                    value={teacher!.FirstName} 
                    label={"First Name"} 
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.FirstName} 
                />
                <Input 
                    type="text" 
                    name={"LastName"} 
                    value={teacher!.LastName} 
                    label={"Last Name"} 
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.LastName} 
                />                            
                <Input 
                    type="text" 
                    name={"TepNo"} 
                    value={teacher!.TepNo} 
                    label={"Phone No"} 
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.TepNo} 
                />
                <Input 
                    type="text" 
                    name={"WhatsappNo"} 
                    value={teacher!.WhatsappNo} 
                    label={"Whatsapp No"} 
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.WhatsappNo} 
                />
                <Input 
                    type="text" 
                    name={"Email"} 
                    value={teacher!.Email} 
                    label={"Email"} 
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    validation={validation.Email} 
                />
                <TextAreaInput 
                    name={"Description"} 
                    value={teacher!.Description} 
                    label={"Description"} 
                    onChange={handleInputChange}
                />                
                <Button style={styles.submitButton} onClick={handleSubmit}>Submit</Button>
            </>}             
        </form>
    )
}