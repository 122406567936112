import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as CloseCircle } from '../../../assets/svgs/close-circle-ash.svg';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

interface Props {
    open: boolean;
    styles?: any;    
    toggle?(): void;
    closeIcon?: boolean; 
    children?: React.ReactNode;
}

export const PopUpDialog: React.FC<Props> = (props) => {   
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("popUpDialog"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);
    
    if (!props.open) return null;

    return (
        <div style={styles}>
            <div style={styles.backdrop} onClick={props.toggle}></div>
            <div style={styles.container}>  
                {props.closeIcon && <CloseCircle style={styles.closeButton} onClick={props.toggle}/>}
                {props.children}
            </div>
        </div>
    )
}