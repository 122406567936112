import { Color } from "../enums/Color"

export const searchArea = {
    mobile: {        
        display:"flex",      
        position: "relative" as "relative", 
        left: "50%",         
        transform: "translate(-50%, 0%)",
        width: "90%", 
        marginTop: "150px", 
        borderRadius: "10px",
        backgroundColor: Color.White,
        boxShadow: "0px 0px 10px grey",
        height: "120px",
        zIndex: "1",
        input: {
            width: "56%",
            height: "60px",            
            paddingLeft: "5%",
            fontSize: "15px",            
            border: "none",
            borderRadius: "10px",
            resize:"none",
        },
        button: {
            position: "absolute" as "absolute", 
            right: "5%",
            left: "5%",
            top: "60px",
            bottom: "10px",
            width: "90%",
            height: "50px",                                    
            backgroundColor: Color.Green,
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            buttonWrapper: {        
                display: "flex",
                flexDirection: "row" as "row",
            },
            buttonText: {
                color: Color.White,                
                fontWeight: "bold",
                marginLeft: "auto"
            },
            buttonIcon: {
                width: "15px",
                height: "15px",
                fill: Color.White,              
                marginLeft: "3px",
                marginRight: "auto",
                marginTop: "1px"
            }
        }          
    },
    desktop: {        
        flexDirection: "row" as "row",             
        position: "relative" as "relative", 
        marginTop: "70px",            
        width: "80%",  
        height: "70px",  
        alignItems: "center" as "center",
        boxShadow: "0px 0px 10px black",
        input: {    
            width: "59%",            
            height: "34px",            
            fontSize: "16px",
            boxShadow: "none"
        },
        button: {
            left: "79%",
            top: "10px",
            bottom: "5px",
            width: "20%",
            height: "50px",
            backgroundColor: Color.Green
        }        
    }
}
