export const termsAndConditionsPage = {
    mobile: {
        container: {
            margin: "50px 8%"            
        },
        list: {
            listStyleType: "none"
        },
        listItem: {
            marginBottom: "10px"
        }
    },
    desktop: {
        
    }
}