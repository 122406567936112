import axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";

export async function getChatResponse(question: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/chat/response", {
            params: {
                text: question
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}