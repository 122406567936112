import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { ToolBar } from "../../components/Shared/ToolBar";
import { PrivateDegreeView } from "../../models/PrivateDegreeView";
import { Localization } from "../../models/Localization";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { getDegree } from "../../services/PrivateDegreeService";
import { DetailLoader } from "../../loaders/DetailLoader";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { Helmet } from "react-helmet-async";

export const PrivateDegreeDisplayPage: React.FC = () => {
    let params = useParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("displayPage"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [degreeView, setDegreeView] = useState<PrivateDegreeView>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  

    useEffect(() => {  
        window.scrollTo(0, 0);    
        getLocalizations();  
        getDegreeView();                            
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("displayPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(4, 13) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }

    async function getDegreeView() {        
        const degreeView = await getDegree(params.degreeId!) as unknown as PrivateDegreeView;
        setDegreeView(degreeView);         
        setLoading(false);
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer />
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            {loading ? <DetailLoader /> : 
            <>
                <Helmet>
                    <title>{degreeView?.SEOTitle}</title>
                    <meta name="description" content={degreeView?.SEODescription}/>                    
                </Helmet>
                <div style={styles.container}>
                    <div>
                        <div style={styles.title}>{degreeView?.PrivateDegreeName}</div>
                        <div style={styles.titleSecondary}>{degreeView?.PrivateDegreeName_ENG}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwUniversityLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{degreeView?.PrivateUniversityName}</div>
                    </div>                
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDegreePeriodLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{degreeView?.PrivateDegreePeriodName}</div>
                    </div>
                    {degreeView?.RequiredQualifications && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwRequiredQualificationLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: degreeView?.RequiredQualifications}}></div>
                    </div>}
                    {degreeView?.PrivateDegreeDetail && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDegreeDetailLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: degreeView?.PrivateDegreeDetail}}></div>
                    </div>}
                    {degreeView?.PrivateDegreeContent && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDegreeContentLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: degreeView?.PrivateDegreeContent}}></div>
                    </div>}
                    {degreeView?.JobOpportunities && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwJobOpportunitiesLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: degreeView?.JobOpportunities}}></div>
                    </div>}
                    <div style={styles.linkWrapper}>
                        <a href={degreeView?.UniversityPageLink} target="_blank" style={styles.link}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwUniversityPageLinkLabel')?.ElementValue}
                        </a>
                    </div>
                </div>
            </>}
        </div>
    )
}