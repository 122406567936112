import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { ToolBar } from "../../components/Shared/ToolBar";
import { DetailLoader } from "../../loaders/DetailLoader";
import { DegreeView } from "../../models/DegreeView";
import { Localization } from "../../models/Localization";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { getDegreeById } from "../../services/GovernmentDegreeService";
import { ReactComponent as Dropdown } from "../../assets/svgs/drop_down.svg";
import { Helmet } from "react-helmet-async";

export const GovernmentDegreeDisplayPage: React.FC = () => {
    let params = useParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("displayPage"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [degreeView, setDegreeView] = useState<DegreeView>();
    const [subDegreeIndex, setSubDegreeIndex] = useState<number>(-1);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  

    useEffect(() => {   
        window.scrollTo(0, 0);    
        getLocalizations();  
        getDegreeView();                            
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("displayPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(4, 8) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }

    async function getDegreeView() {        
        const degreeView = await getDegreeById(params.degreeId!) as unknown as DegreeView;
        setDegreeView(degreeView);         
        setLoading(false);
    }

    const expandSubDegree = (subIndex: number) => {
        if(subIndex === subDegreeIndex) {
            setSubDegreeIndex(-1);
        }
        else {
            setSubDegreeIndex(subIndex);
        }
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            {loading ? <DetailLoader/> : 
            <>
                <Helmet>
                    <title>{degreeView?.SEOTitle}</title>
                    <meta name="description" content={degreeView?.SEODescription}/>                    
                </Helmet>
                <div style={styles.container}>
                    <div>
                        <div style={styles.title}>{degreeView?.DegreeName}</div>
                        <div style={styles.titleSecondary}>{degreeView?.DegreeName_ENG}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwUniversityLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{degreeView?.UniversityName}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwFacultyLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{degreeView?.FacultyName}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDegreePeriodLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{degreeView?.DegreePeriodName}</div>
                    </div>
                    {degreeView?.DegreeDetail && <div style={styles.details} dangerouslySetInnerHTML={{ __html: degreeView?.DegreeDetail}}></div>}
                    {degreeView?.DegreeContent && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwDegreeContentLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: degreeView?.DegreeContent}}></div>
                    </div>}
                    {degreeView?.JobOpportunities && <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwJobOpportunitiesLabel')?.ElementValue}
                        </div>
                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: degreeView?.JobOpportunities}}></div>
                    </div>}
                    {degreeView?.IsMultySubDegree && <>
                        {degreeView?.SubDegrees.map((subDegree, index) =>
                            <div key={index} onClick={() => expandSubDegree(index)}>
                                <div style={styles.subTitleWrapper}>
                                    <div>
                                        <div style={styles.subTitle}>{subDegree.SubDegreeName}</div>
                                        <div style={styles.subTitleSecondary}>{subDegree.SubDegreeName_ENG}</div>
                                    </div>                                
                                    <Dropdown style={{...styles.dropdownIcon, ...((subDegreeIndex === index) && styles.dropdownIcon.hover)}} />
                                </div>                            
                                {(subDegreeIndex === index) && <div style={styles.subContainer}>
                                    {subDegree.SubDegreeContent && <div>
                                        <div style={styles.label}>
                                            {localizations.find(({ ElementName }) => ElementName === 'vwDegreeContentLabel')?.ElementValue}
                                        </div>
                                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: subDegree.SubDegreeContent}}></div>
                                    </div>}
                                    {subDegree.JobOpportunities && <div>
                                        <div style={styles.label}>
                                            {localizations.find(({ ElementName }) => ElementName === 'vwJobOpportunitiesLabel')?.ElementValue}
                                        </div>
                                        <div style={styles.value} dangerouslySetInnerHTML={{ __html: subDegree.JobOpportunities}}></div>
                                    </div>}
                                </div>}           
                            </div>                     
                        )}  
                    </>}
                </div>
            </>}
        </div>
    )
}