import React from 'react';
import { Student } from '../models/Student';
import { Teacher } from '../models/Teacher';

interface Props {
    children: React.ReactNode;
}

export interface AuthContextType {        
    getLoggedTeacher(): any;
    getLoggedStudent(): any;    
}

export const AuthContext = React.createContext<AuthContextType>({            
    getLoggedTeacher: () => {},
    getLoggedStudent: () => {}
});

export const AuthContextProvider: React.FC<Props> = (props) => {             

    const getTeacher = (): Teacher | null => {           
        const teacherString = localStorage.getItem("teacher");          
        if(teacherString !== null) {            
            const teacher: Teacher = JSON.parse(teacherString);
            return teacher;
        } else {
            return null;
        }       
    }
    
    const getStudent = (): Student | null => {           
        const studentString = localStorage.getItem("student");          
        if(studentString !== null) {            
            const student: Student = JSON.parse(studentString);
            return student;
        } else {
            return null;
        }  
    }

    return (
        <AuthContext.Provider value={{                        
            getLoggedTeacher: getTeacher,
            getLoggedStudent: getStudent            
        }}>           
            {props.children}
        </AuthContext.Provider>
    );
}