import React, { useContext, useEffect, useState } from 'react'
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { TabItem } from '../../models/TabItem';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useNavigate } from 'react-router-dom';

interface Props {
    styles?: any;
    path?: string;
    tabItems: TabItem[];
    selectedTab: string;
    getSelectedTab?(selectedTabs: string): void;
}

export const Tabs: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("tabs"));
    const [selectedTab, setSelectedTab] = useState<string>(props.selectedTab);
    const [tabContent, setTabContent] = useState();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    useEffect(() => {
        changeTab(props.selectedTab);
    }, [props.selectedTab]);

    const onTabChange = (tabValue: string) => {
        if(props.path) {
            navigate(props.path + tabValue);
        } else {
            changeTab(tabValue);
        }        
    }

    const changeTab = (tabValue: string) => {
        let selectedTab: TabItem = props.tabItems.filter(t => t.value === tabValue)[0];
        if(selectedTab.component) {
            setSelectedTab(tabValue);
            setTabContent(props.tabItems.filter(t => t.value === tabValue)[0].component);
        }        
        if(props.getSelectedTab) {
            props.getSelectedTab(tabValue);
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.linkWrapper} className={"disableScrollBar"}>
                {props.tabItems.map((tabItem, index) =>
                    <div
                        key={index}
                        style={{ ...styles.link, ...(selectedTab === tabItem.value && styles.link.active) }}
                        onClick={() => onTabChange(tabItem.value)}>
                        {tabItem.label}
                    </div>
                )}
            </div>
            <div style={styles.contentWrapper}>                
                {tabContent}
            </div>
        </div>
    )
}