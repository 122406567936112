import { Color } from "../enums/Color";

export const paymentResponsePage = {
    mobile: {
        container: {
            textAlign: "center"
        },
        image: {
            width: "73vw",
            height: "auto"
        },
        button: {
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            borderRadius: "1.68vw",
            width: "90vw",
            height: "5.58vh",
            padding: "1.67vh",
            fontWeight: "bold" as "bold",
            marginTop: "1.52vh"
        }
    },
    desktop: {
        image: {                
            width: "20vw"
        },
        button: {                                    
            borderRadius: "0.41vw",
            width: "21vw",
            height: "3.58vw",                        
            marginTop: "1.52vh"
        }
    }
}