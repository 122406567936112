import { Color } from "../enums/Color";

export const loginPage = {
    mobile: { 
        rowWrapper: {
            display: "flex",
            flexDirection: "row" as "row"
        },        
        title: {
            textAlign: "center",            
            fontSize: "30px",                              
            letterSpacing: "0px",
            color: Color.Green
        },
        subTitle: {
            textAlign: "center",            
            fontSize: "16px",                              
            letterSpacing: "0px",
            color: Color.Black
        },
        form: {
            marginLeft: "10%",
            marginRight: "10%",
            marginTop: "30px"
        },
        callbackMessage: {
            height: "4.76vh",
            lineHeight: "4.76vh",
            margin: "3.24vh 0 0 0",
            borderRadius: "2.41vw",
            border: "0.24vw solid " + Color.HarvestGold,
            backgroundColor: Color.LightGold,            
            fontSize: "2.6vw",
            fontWeight: 500,
            color: Color.HarvestGold,
            textAlign: "center"
        },
        submitButton: {
            height: "40px",
            width: "-webkit-fill-available",
            background: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "30px",
            marginBottom: "15px"
        },
        redirectButton: {
            display: "flex",
            background: "transparent",
            color: Color.Green,
            fontSize: "14px",
            fontWeight: "bold",
            border: "none",
            left: {
                marginRight: "auto"
            },
            right: {
                marginLeft: "auto"
            }                          
        },
        leftRadioButton: {
            marginTop: "30px",
            marginRight: "auto",
            marginLeft: "5%"
        },
        rightRadioButton: {
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "8%"
        },
        radioLabel: {
            cursor: "pointer"
        },
        agreement: {
            marginBottom: "-25px",
            fontSize: "smaller",
            textAlign: "center"
        },
        dialog: {
            dialog: {
                height: "20%",
                top: "40%"
            },
            title: {
                height: "25%"      
            },
            container: {
                height: "60%",
                overflow: "auto"            
            },            
            buttonWrapper: {
                paddingTop: "0px"
            },
            button: {            
                float: "right"
            }
        },
        dialogError: {
            title: {
                height: "25%",
                color: Color.Crimson    
            },
            button: {
                float: "right",          
                color: Color.Crimson
            }
        },
        invalidMessage: {
            color: Color.Red,
            fontSize: "14px",
            fontWeight: "700",
            paddingLeft: "10px"
        }
    },
    desktop: {        
        title: {                       
            fontSize: "40px"
        },
        subTitle: {                        
            fontSize: "20px",            
            margin: "-20px 0 60px 0"
        },
        form: {
            marginLeft: "30%",
            marginRight: "30%",
            marginTop: "40px"
        },
        callbackMessage: {
            height: "5.92vh",
            lineHeight: "5.92vh",
            margin: "2.68vh 0 0 0",
            borderRadius: "0.52vw",
            border: "0.05vw solid " + Color.HarvestGold,
            fontSize: "0.94vw"
        },
        submitButton: {
            height: "50px",
            borderRadius: "10px",
            fontSize: "20px",            
            marginTop: "40px",
            marginBottom: "20px"
        },
        redirectButton: {
            fontSize: "18px"                       
        },
        leftRadioButton: {
            marginTop: "40px",            
            marginLeft: "15%",
            fontSize: "18px"
        },
        rightRadioButton: {
            marginTop: "40px",            
            marginRight: "18%",
            fontSize: "18px"
        },
        agreement: {
            marginTop: "50px",
            marginBottom: "-35px",
            fontSize: "medium"
        },               
        dialog: {
            dialog: {                       
                top: "30%",
                right: "35%",
                padding: "2%"
            },
            container: {
                height: "48%",                
                paddingTop: "2%"          
            }
        }
    }
}