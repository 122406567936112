import { Color } from "../enums/Color";

export const chatPage = {
    mobile: {        
        minHeight: "100vh",
        backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/chat_background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover" as "cover",
        backgroundPosition: "center bottom",
        desktopTitle: {
            display: "none"
        },                    
        container: {                                        
            margin: "0 5%", 
            padding: "60px 0"
        },
        warningMessage: {
            fontSize: "12px",
            backgroundColor: Color.OldLace,
            border: "1px solid " + Color.HarvestGold,
            borderRadius: "5px",
            padding: "8px",
            textAlign: "center" as "center",
            marginBottom: "20px"
        },
        form: {
            display: "flex",
            flexDirection: "row" as "row",
            position: "fixed" as "fixed",
            width: "90%",
            bottom: 0,
            padding: "10px 0",            
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/chat_background.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover" as "cover",
            backgroundPosition: "center bottom",
            zIndex: 1
        },
        input: {
            extendArea: {
                width: "76%",
                minHeight: "22px",
                backgroundColor: Color.White,
                border: "2px solid " + Color.Green,
                borderRadius: "20px",
                padding: "8px 15px"
            }
        },
        button: {
            height: "42px",
            width: "42px",
            border: "none",
            borderRadius: "22px",
            backgroundColor: Color.Green,
            marginLeft: "auto"            
        },
        icon: {
            width: "26px",
            height: "26px",
            fill: Color.White
        },
        list: {
            padding: 0
        },
        question: {
            width: "fit-content" as "fit-content",
            maxWidth: "80%",
            display: "flex",
            margin: "20px 0 0 auto",
            padding: "8px",
            border: "none",
            borderRadius: "10px 0 10px 10px",
            backgroundColor: Color.Gossip,
            boxShadow: "0 0 2px rgb(0 0 0 / 0.2)"
        },
        answer: {
            width: "fit-content" as "fit-content",
            maxWidth: "90%",
            display: "flex",
            margin: "10px auto 0 0",
            padding: "8px",
            border: "none",
            borderRadius: "0 10px 10px 10px",
            backgroundColor: Color.White,
            boxShadow: "0 0 2px rgb(0 0 0 / 0.2)",
            whiteSpace: "break-spaces"
        }
    },
    desktop: {
        backgroundImage: "none",
        desktopTitle: {
            display: "block",
            textAlign: "center" as "center",
            color: Color.Green,
            marginTop: 0,
            paddingTop: "50px"
        },        
        container: {
            marginTop: "30px",            
            padding: "0 28% 110px 28%"
        },
        warningMessage: {
            marginBottom: "30px"
        },
        form: {
            backgroundImage: "none",
            background: Color.White,
            padding: "20px 0 40px 0"
        },
        input: {
            extendArea: {
                width: "31.6%",
                minHeight: "34px",
                borderRadius: "28px"
            }
        },
        button: {
            height: "50px",
            width: "50px",            
            borderRadius: "25px",            
            marginLeft: "16px"            
        },
        answer: {            
            boxShadow: "0 0 2px rgb(0 0 0 / 1)"
        }
    }
}