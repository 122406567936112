import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import { StyleContext } from '../../providers/StyleContextProvider';
import { Button } from './Button';
import { ProfileEntrance } from './ProfileEntrance';
import { SiderMenu } from './SiderMenu';

interface Props {    
    open: boolean;
    toggle(): void;       
}

export const MobileMenuDrawer: React.FC<Props> = (props) => {        
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("mobileMenu")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("mobileMenu"));                                                                   
    }, [isMobile]);

    return (
        <div style={{...styles, ...(props.open ? styles.open : styles.close)}}>
            <div style={styles.leftContent} onClick={props.toggle}></div>
            <div style={styles.rightContent}>
                <Button type="button" style={styles.closeButton} onClick={props.toggle}>
                    <Close style={styles.closeIcon}/>
                </Button>
                <SiderMenu styles={styles.menu}/>                
                <ProfileEntrance/>
            </div>
        </div>
    );
}