import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { Button } from '../Button';
import { merge } from 'react-merge';

export interface Props { 
    styles?: any;        
    title: string;    
    message: string;
    handleClose?(): void;    
    handleOk(): void;       
};

export const MessageDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));             
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });           

    useEffect(() => {           
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);        
    }, [isMobile]);    

    return (
        <>
            <div style={styles.backdrop} onClick={props.handleClose ? props.handleClose : props.handleOk}></div>
            <div style={styles.dialog}>
                <div style={styles.title}>{props.title}</div>
                <div style={styles.container}>
                    {props.message}    
                </div>
                <div style={styles.buttonWrapper}>                
                    {props.handleClose && <Button style={{...styles.button, ...styles.button.clear}} onClick={props.handleClose}>Close</Button>}
                    <Button style={{...styles.button, ...styles.button.select}} onClick={props.handleOk}>Ok</Button>                
                </div>
            </div>  
        </>      
    );
}