import { useContext, useEffect, useState } from "react";
import { StyleContext } from "../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { Footer } from "../components/Shared/Footer";
import { Header } from "../components/Shared/Header";

export const TermsAndConditionsPage: React.FC = () => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("termsAndConditionsPage"));    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });   

    useEffect(() => { 
        window.scrollTo(0, 0);       
        setStyles(styleContext.getComponentStyle("termsAndConditionsPage"));
    }, [isMobile]);

    return (
        <>
            <Header styleSheet={"subHeader"} heading={"Terms and Conditions Agreement and Privacy Policy"} searchEnable={false} searchSelectEnable={false}/>            
            <div style={styles.container}>                
                <h3>Effective Date: February 13, 2021</h3>
                <p>
                    Welcome to Tuteclass.com. This website is operated by CLIPES SOLUTIONS (PRIVATE) LIMITED (registered number 00234766) which has its registered office at No 472, Unawa, 5th mile Post, Ruhunu Ridiyagama, Ambalantota, Sri Lanka. We can be contacted by email or by mail to the address set out above.
                    These website terms of use as amended by us from time to time ("terms") govern your use of our website, Tuteclass.com. By using our website, you are agreeing to these terms, whether you are just browsing (a "Visitor") or whether you are a registered user (a "Member"). Please stop using our website if you do not agree to these terms.
                    We may revise these terms at any time at our sole discretion. If we do, we will notify you on our home page. Please also check back regularly for updates: you can easily determine whether changes have been made by confirming the version date at the top of these terms. Your continued use of our website means that you agree to these terms, as revised.
                </p>
                <h3>1.	Website Use</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        1.1.	Eligibility: The Website is available to both teachers and students. However, registration is not mandatory to access the Website.
                    </li>
                    <li style={styles.listItem}>
                        1.2.	User Responsibilities: Users are solely responsible for their use of the Website. Users must comply with all applicable laws, regulations, and this Agreement. Users must not engage in any activities that may harm or interfere with the proper functioning of the Website or infringe upon the rights of others.
                    </li>
                    <li style={styles.listItem}>
                        1.3.	Availability: We strive to provide uninterrupted access to the Website. However, we do not guarantee that the Website will always be available, error-free, or uninterrupted. We reserve the right to modify, suspend, or terminate the Website or any part thereof without prior notice.
                    </li>
                    <li style={styles.listItem}>
                        1.4.	Registration: To access certain features and services on the Site, you may be required to create a Tuteclass user account. You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. Tuteclass reserves the right to terminate or suspend your account at any time without notice if we suspect that you have violated these Terms.
                        <ul style={styles.list}>
                            <li style={styles.listItem}>                    
                                1.4.1.	You agree that any information you provide to us about yourself upon registration or at any other time will be true, accurate, up to date and complete. We reserve the right to terminate your membership and access to our website if we learn that you have provided false or misleading information.
                            </li>
                            <li style={styles.listItem}>
                                1.4.2.	When you register to use our website, you will be asked to create a username and password. You agree not to divulge your password to any other person. You are responsible for maintaining the confidentiality of your password, and will be fully responsible for all liabilities arising in connection with your password's use (or misuse), whether such use or misuse is with or without your knowledge or approval. If you suspect that someone else knows of or is using your password, you must notify us immediately by emailing us.
                            </li>
                        </ul>
                    </li>
                </ul>
                <h3>2.	Teacher Advertisements</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        2.1.	 Payment: Teachers may choose to publish their tuition class advertisements on the Website. Upon completing the payment, the advertisement will be made available on the Website for a period of one year.
                    </li>
                    <li style={styles.listItem}>
                        2.2.	 Refunds: All payments made for advertisement services are non-refundable under any circumstances. Once a payment is completed, no refunds will be provided.
                    </li>
                    <li style={styles.listItem}>
                        2.3.	 Content Responsibility: Teachers are solely responsible for the accuracy, legality, and content of their advertisements. We do not endorse or guarantee the quality, availability, or effectiveness of any tuition classes or services offered by teachers on the Website.
                    </li>
                    <li style={styles.listItem}>
                        2.4.	 Intellectual Property: By submitting advertisements to the Website, teachers grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, and display their advertisements for the purposes of promoting the Website.
                    </li>
                </ul>
                <h3>3.	Content Posted</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        3.1.	 You must ensure that any content that you submit to our website:
                        does not infringe any third party's Intellectual Property Rights (“Intellectual Property Rights” means registered and / or unregistered copyright, database rights, trade marks, design rights and other intellectual and proprietary rights of whatever nature, and all applications for such rights, anywhere in the world);
                        does not breach any applicable law;
                        is not defamatory, profane, sexually or racially offensive or discriminating, harassing, threatening, obscene, pornographic, false, unreliable or misleading or otherwise objectionable in our opinion; or
                        does not disrupt the communications of the online community or Community Area on our website
                    </li>
                    <li style={styles.listItem}>
                        3.2.	 You agree that you will not use our website:
                        to send chain letters, junk mail, ‘spam’, business or bulk communications of any kind;
                        in a way, that might reasonably be expected to cause another person’s computer systems and/or their communications network to be interrupted, damaged or rendered less efficient;
                        to obtain personal information from “users” (which means “members” and “visitors”) or from anyone if such information is gathered deceptively or in a non-transparent way;
                        to attempt to impersonate another user; or
                        to harass, threaten or intimidate any user.
                    </li>
                </ul>
                <h3>4.	Limitation of Liability</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        4.1.	 Indemnification: To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold the Company and its affiliates, directors, officers, employees, and agents harmless from any claims, losses, damages, liabilities, costs, or expenses arising out of or related to your use of the Website, violation of this Agreement, or infringement of any rights of a third party.
                    </li>
                    <li style={styles.listItem}>
                        4.2.	 Disclaimer: The Website is provided on an "as is" and "as available" basis without warranties of any kind, whether express or implied. We disclaim all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee the accuracy, completeness, or reliability of any content on the Website.
                    </li>
                    <li style={styles.listItem}>
                        4.3.	 Limitation of Liability: To the fullest extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use of the Website, including but not limited to damages for loss of profits, data, or other intangible losses, even if we have been advised of the possibility of such damages.
                    </li>
                </ul>
                <h3>5.	Intellectual Property Rights</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        5.1.	You agree that our website and all content contained in it are protected by Intellectual Property Rights that either belong to or are licensed to us.
                    </li>
                    <li style={styles.listItem}>
                        5.2.	You acquire no proprietary interest in our website or content provided by us or others and may not use them in any way except as expressly permitted by these terms.
                    </li>
                    <li style={styles.listItem}>
                        5.3.	 You may view, print, download or temporarily store such content for your personal, non-commercial reference without alteration, addition or deletion. You may not otherwise copy, reproduce, retransmit, distribute, publish, commercially exploit or otherwise transfer such content. You acknowledge that you will not acquire any intellectual property rights by downloading or otherwise using any such content.
                    </li>
                    <li style={styles.listItem}>
                        5.4.	By submitting content to our website:
                        you grant us a royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute and communicate to the public such content; and
                        you authorize us to adapt such content and you agree to waive your moral rights to object to any derogatory treatment, or to be identified as the author, of the material in question.
                    </li>
                </ul>
                <h3>6.	Our Rights</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        6.1.	We have the right in our absolute discretion to alter, edit, remove, suspend or refuse any content without notice, if we consider that the content is objectionable or violates these terms or for any other reason.
                    </li>
                    <li style={styles.listItem}>
                        6.2.	We may preserve or disclose the content and your personal details as well as any content to any third party if such disclosure is necessary:
                        for the purpose of maintaining our website;
                        by law; or
                        to respond to claims that our website infringes or restricts the right of any party.
                    </li>
                    <li style={styles.listItem}>
                        6.3.	 We may suspend or terminate your access to our website at any time if you breach any of these terms.
                    </li>
                </ul>
                <h3>7.	 Data Collection and Use</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        7.1.	 Consent: By using the Website, you agree that your personal information will be collected, used, and stored in accordance with this Agreement.
                    </li>
                    <li style={styles.listItem}>
                        7.2.	Objectives: We gather and utilize personal data to deliver and enhance our services, manage payments, contact you, and uphold the safety and integrity of the Website.
                    </li>
                    <li style={styles.listItem}>
                        7.3.	 Minimization: We will only gather and use the minimal amount of personal data required to fulfill the obligations set forth in this Agreement. Without your explicit consent, we won't use your personal information for any other unrelated reason.
                    </li>
                    <li style={styles.listItem}>
                        7.4.	Confidentiality: We will regard your personal information as confidential and won't share it with a Third party unless this Agreement specifically permits it.
                    </li>
                    <li style={styles.listItem}>
                        7.5.	 Data Protection: This Data Protection Clause is subject to change at any time, with or without notice. When the new clause is posted on the website, any modifications become enforceable. To stay informed about our data protection procedures, we recommend you to periodically examine the Website.
                        <ul style={styles.list}>
                            <li style={styles.listItem}>
                                7.5.1.	By using the Website, you acknowledge and agree to the provisions of this Data Protection Clause and our data protection procedures as set forth in this Agreement, in accordance with the Sri Lankan Data Protection Act, by using the Website.
                            </li>
                        </ul>
                    </li>
                </ul>
                <h3>8.	Miscellaneous</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        8.1.	 Governing Law: This Agreement shall be governed by and construed in accordance with the laws of Sri Lanka, without regard to its conflict of laws principles.
                    </li>
                    <li style={styles.listItem}>
                        8.2.	Entire Agreement: This Agreement constitutes the entire agreement between you and the Company regarding the use of the Website and supersedes any prior or contemporaneous understandings or agreements, whether written or oral.
                    </li>
                </ul>
                By accessing and using the Site, you agree to be bound by these Terms of Service. If you do not agree to these Terms, you may not use the Site.
            
            </div>            
            <Footer/>
        </> 
    )
}