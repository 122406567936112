import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { InputLoader } from "../../loaders/InputLoader";
import { SearchContentLoader } from "../../loaders/SearchContentLoader";
import { Localization } from "../../models/Localization";
import { Option } from "../../models/Option";
import { MenuOption } from "../../models/MenuOption";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { getAdRequestModel, getDistrictTowns, getGradeCategorySubjects, postAdEditModel } from "../../services/TeacherService";
import { Button } from "../Shared/Button";
import { DesktopMenuDrawer } from "../Shared/DesktopMenuDrawer";
import { TeacherSearchRequestModel } from "../../models/TeacherSearchRequestModel";
import { MultySelectMultyMenuInput } from "../Shared/Inputs/MultySelectMultyMenuInput";
import { MultySelectInput } from "../Shared/Inputs/MultySelectInput";
import { TeacherAdResponseModel } from "../../models/TeacherAdResponseModel";
import { TeacherAd } from "../../models/TeacherAd";
import { InputValidation } from "../../models/InputValidation";

interface Props {
    teacherAd: TeacherAd;
    closeAdEdit(): void;
}

export const EditAd: React.FC<Props> = (props) => {
    const teacherAd = props.teacherAd;
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchPage"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [loading, setLoading] = useState<boolean>(true);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [townLoading, setTownLoading] = useState<boolean>(false);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [selectedGradeCategoryIds, setSelectedGradeCategoryIds] = useState<string | undefined>(teacherAd.GradeCategoryIds);
    const [categoryGrades, setCategoryGrades] = useState<MenuOption[]>([]);    
    const [selectedGradeIds, setSelectedGradeIds] = useState<string | undefined>(teacherAd.GradeIds);
    const [subjects, setSubjects] = useState<Option[]>([]);    
    const [selectedSubjectIds, setSelectedSubjectIds] = useState<string | undefined>(teacherAd.SubjectIds);
    const [subjectValidation, setSubjectValidation] = useState<InputValidation>({});
    const [mediums, setMediums] = useState<Option[]>([]);    
    const [selectedMediumIds, setSelectedMediumIds] = useState<string | undefined>(teacherAd.MediumIds);
    const [classTypes, setClassTypes] = useState<Option[]>([]);    
    const [selectedClassTypeIds, setSelectedClassTypeIds] = useState<string | undefined>(teacherAd.ClassTypeIds);
    const [districts, setDistricts] = useState<Option[]>([]);
    const [selectedDistrictIds, setSelectedDistrictIds] = useState<string | undefined>(teacherAd.DistrictIds);
    const [districtTowns, setDistrictTowns] = useState<MenuOption[]>([]);
    const [selectedTownIds, setSelectedTownIds] = useState<string | undefined>(teacherAd.TownIds);  
    const fileInputRef = useRef<HTMLInputElement>(null); 
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(teacherAd.TeacherAdUrl);  
    const [fileValidation, setFileValidation] = useState<InputValidation>({});   

    useEffect(() => {    
        window.scrollTo(0, 0);
        getLocalizations();  
        getRequestModelData();                                       
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("searchPage"));          
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(7, 18) as unknown as Localization[];
        setLocalizations(localizationList);        
    }     
    
    async function getRequestModelData() {        
        await getAdRequestModel(selectedGradeCategoryIds, selectedDistrictIds)
        .then((requestModel: TeacherSearchRequestModel) => {            
            setCategoryGrades(requestModel.CategoryGrades);
            setSubjects(requestModel.Subjects);
            setMediums(requestModel.Mediums);
            setClassTypes(requestModel.ClassTypes);
            setDistricts(requestModel.Districts);
            setDistrictTowns(requestModel.DistrictTowns);            
            setLoading(false);            
        })
        .catch((error) => {
            // toErrorPage(error);
        }); 
    }

    async function getGradeCategorySubjectList(gradeCategoryIds?: string) {                
        await getGradeCategorySubjects(gradeCategoryIds) 
        .then((gradeCategorySubjectList: Option[]) => {
            setSubjects(gradeCategorySubjectList);
            setSubjectLoading(false); 
        })
        .catch((error) => {
            // toErrorPage(error);
        });
    }
       
    async function getDistrictTownList(districtIds?: string) {                
        await getDistrictTowns(districtIds)
        .then((districtTownList: MenuOption[]) => {            
            setDistrictTowns(districtTownList);
            setTownLoading(false);           
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }

    const getSelectedGradeCategories = (gradeCategoryIds?: string) => {
        setSubjectLoading(true);
        setSelectedGradeCategoryIds(gradeCategoryIds);
        setSelectedSubjectIds(undefined);                               
        getGradeCategorySubjectList(gradeCategoryIds);        
    }

    const getSelectedDistricts = (districtIds?: string) => {
        setTownLoading(true);
        setSelectedDistrictIds(districtIds);
        setSelectedTownIds(undefined);                              
        getDistrictTownList(districtIds);        
    }

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];        
        if (file) {
            if(file.size > 0 && file.size <= 2097152) {
                setSelectedImage(file);
                setImagePreviewUrl(URL.createObjectURL(file));
                setFileValidation({valid: true, invalid: false});
            }
            else {
                setFileValidation({valid: false, invalid: true, invalidMessage: "File size is invalid"});
            }
        }
    };

    async function submitResponse () { 
        var isValid = selectedSubjectIds && fileValidation.valid;
        if(isValid) {
            let finalDistrictIds = selectedDistrictIds; 
            if(selectedClassTypeIds && selectedClassTypeIds.includes("4")) {                 
                if(!selectedDistrictIds) {
                    finalDistrictIds = "0";
                }
                else {
                    finalDistrictIds = "0," + selectedDistrictIds;
                }
            }
            const responseModel: TeacherAdResponseModel = {
                TeacherAdId: teacherAd.TeacherAdId, 
                GradeCategoryIds: selectedGradeCategoryIds,               
                GradeIds: selectedGradeIds,
                SubjectIds: selectedSubjectIds,
                MediumIds: selectedMediumIds,
                ClassTypeIds: selectedClassTypeIds,
                DistrictIds: finalDistrictIds,
                TownIds: selectedTownIds                
            }
            const responseString = JSON.stringify(responseModel);
            const formData = new FormData();
            formData.append('response', responseString);
            if(selectedImage) {
                formData.append('adImage', selectedImage!);
            }            
            await postAdEditModel(formData)
            .then((success: boolean) => {                
                if(success) {
                    props.closeAdEdit();
                }
            })
            .catch((error) => {            
                //toErrorPage(error);
            });
        } 
        else {
            if(!selectedSubjectIds) {                                
                setSubjectValidation({valid: false, invalid: true, invalidMessage: "Subject is required"});                
            }
            if(!selectedImage) {
                setFileValidation({valid: false, invalid: true, invalidMessage: "Advertisement is required"});
            }
        }               
    }

    return (
        <>
            <DesktopMenuDrawer/>            
            {loading ? <SearchContentLoader/> : 
            <>                
                <MultySelectMultyMenuInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwGradeLabel')?.ElementValue!}                    
                    menuOptions={categoryGrades} 
                    selectedOptionIds={teacherAd.GradeIds}
                    setSelectedLabels={getSelectedGradeCategories}
                    setSelectedOptions={setSelectedGradeIds}
                    searchEnabled={true}
                />
                {subjectLoading ? <InputLoader/> :
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwSubjectLabel')?.ElementValue!}
                    options={subjects}   
                    selectedIds={teacherAd.SubjectIds}
                    setSelected={setSelectedSubjectIds}
                    searchEnabled={true}       
                    validation={subjectValidation}                    
                    setValidation={setSubjectValidation}                                 
                />}
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwMediumLabel')?.ElementValue!}                        
                    options={mediums} 
                    selectedIds={teacherAd.MediumIds}
                    setSelected={setSelectedMediumIds}
                />
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwClassTypeLabel')?.ElementValue!}                        
                    options={classTypes} 
                    selectedIds={teacherAd.ClassTypeIds}
                    setSelected={setSelectedClassTypeIds}
                />                
                <MultySelectInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwDistrictLabel')?.ElementValue!}
                    options={districts} 
                    selectedIds={teacherAd.DistrictIds}
                    setSelected={getSelectedDistricts}
                    searchEnabled={true}                    
                />
                {townLoading ? <InputLoader/> :
                <MultySelectMultyMenuInput 
                    label={localizations.find(({ ElementName }) => ElementName === 'vwTownLabel')?.ElementValue!}
                    menuOptions={districtTowns} 
                    selectedOptionIds={teacherAd.TownIds}
                    setSelectedLabels={setSelectedDistrictIds}
                    setSelectedOptions={setSelectedTownIds}
                    searchEnabled={true}
                />}
                <div>
                    <div style={styles.imageWarning}>{"MAX FILE SIZE: 2 MB"}</div>
                    <img src={imagePreviewUrl} alt="Teacher Ad" onClick={handleImageClick} style={{...styles.imageInput, ...(fileValidation.invalid && styles.imageInvalid)}}/>
                    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef}/>
                    {fileValidation.invalid && <div style={styles.invalidMessage}>{fileValidation.invalidMessage}</div>}
                </div>                    
                <Button style={styles.searchButton} onClick={submitResponse}>
                    {localizations.find(({ ElementName }) => ElementName === 'vwBtnAdd')?.ElementValue}
                </Button>                
            </>}            
        </>
    )
}