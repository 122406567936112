import Axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { GovernmentDegreeResponseModel } from "../models/GovernmentDegreeResponseModel";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

export async function getRequestModel() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/governmentDegree/requestModel", {             
            params: {
                languageCode: localStorage.getItem('languageCode')!
            },           
            cache: {                
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getStreamSubjects(streamId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/governmentDegree/subjects", {              
            params: {
                streamId: streamId,
                languageCode: localStorage.getItem('languageCode')!
            },          
            cache: {                
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDegreeById(degreeId: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/governmentDegree/degree", {            
            params: {
                degreeId: degreeId,
                languageCode: localStorage.getItem('languageCode')!
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDegrees(responseModel: GovernmentDegreeResponseModel) {
    responseModel.languageCode = localStorage.getItem('languageCode')!;
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "common/governmentDegree/responseModel", responseModel, {                                
            cache: {    
                methods: ["post"],            
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}
