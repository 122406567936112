export enum Color {
    Blue = "#01A9E0",
    White = "#FFFFFF",
    Black = "#000000",
    LightBlack = "#3C3C3C",    
    DeepBlack = "#0B132B",
    Green = "#00A7A9", 
    AquaGreen = "#00FFFF",    
    DarkGreen = "#53797A", 
    LightGreen = "#C2EDB0",
    MediumGreen = "#51CB20",
    SharpGreen = "#DCF5D2",
    UfoGreen = "#25D366", 
    Gossip = "#DCF8C6",    
    Ash = "#EAEAEB",    
    LightAsh = "#F4F4F4",
    MiddleAsh = "#D5D5D6",    
    DeepAsh = "#515251", 
    SharpAsh = "#818080",
    DarkAsh = "#DFDFDF", 
    HarvestGold = "#E39500",
    LightGold = "#E3950019",
    MaastrichtBlue = "#0B132B",
    AliceBlue = "#EBFAFF",
    OldLace = "#FDF3E0",
    Crimson = "#DC143C",
    LoaderBackground = "#F3F3F3",
    LoaderForeground = "#ECEBEb",
    Red = "#FB3640",
    LightRed = "#F5D8DA",
    CoralRed = "#FB364019",
    Linen = "#FEEAEB",
    Nyanza = "#E4F7DD",
    LightGray = "#D5D5D5",
    PhilippineGray = "#909090", 
    LemonCurry = "#CC8D1A",
    DarkLiver = "#515151",
    Orange = "#FF7F00"
}