import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ToolBar } from "../../components/Shared/ToolBar";
import { StyleContext } from "../../providers/StyleContextProvider";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { TabItem } from "../../models/TabItem";
import { Tabs } from "../../components/Shared/Tabs";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Teacher } from "../../models/Teacher";
import { MyAds } from "../../components/Teacher/MyAds";
import { NewAd } from "../../components/Teacher/NewAd";
import { Toast } from "../../components/Shared/Toast";
import { Profile } from "../../components/Teacher/Profile";
import { useParams } from "react-router-dom";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { useAlert } from "../../hooks/useAlert";
import { Helmet } from "react-helmet-async";

const localizationList = {
    sin: {
        title: "නව දැන්වීම්",   
        newAdsTab: "නව දැන්වීම්",     
        myAdsTab: "මගේ දැන්වීම්",        
        myProfileTab: "මගේ තොරතුරු"
    },
    tam: {
        title: "புதிய விளம்பரம்",    
        newAdsTab: "புதிய விளம்பரம்",    
        myAdsTab: "என் விளம்பரங்கள்",        
        myProfileTab: "என் சுயவிவரம்"
    },
    eng: {
        title: "New Ads",  
        newAdsTab: "New Ads",      
        myAdsTab: "My Ads",        
        myProfileTab: "My Profile"
    }
}

export const NewAdPage: React.FC = () => {
    let params = useParams();
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchPage"));
    const authContext = useContext(AuthContext);
    const teacher: Teacher | null = authContext.getLoggedTeacher();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [title, setTitle] = useState<string>("");
    const [tabItems, setTabItems] = useState<TabItem[]>([]);    
    const [loading, setLoading] = useState<boolean>(true);    
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>();
    const { Alert, openAlert } = useAlert();
    const bannerType = isMobile ? 7 : 8;
    
    useEffect(() => {    
        window.scrollTo(0, 0);
        getLocalizations();    
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("searchPage"));          
    }, [isMobile]);

    async function getLocalizations() {        
        let localizations: any;
        let languageCode = localStorage.getItem('languageCode');        
        switch (languageCode) {
            case "SIN": {
                localizations = localizationList.sin;
                break;
            }
            case "TAM": {
                localizations = localizationList.tam;
                break;
            }
            case "ENG": {
                localizations = localizationList.eng;
                break;
            }
        }
        if(teacher) {
            let newTabItems: TabItem[] = [                
                { label: localizations.newAdsTab, value: "newAds", component: <NewAd teacher={teacher}/> },
                { label: localizations.myAdsTab, value: "myAds", component: <MyAds teacherId={teacher.TeacherId}/> },                
                { label: localizations.myProfileTab, value: "myProfile", component: <Profile teacherId={teacher.TeacherId} setToastMessage={setToastMessage} setOpenToast={setOpenToast} openAlert={openAlert}/> }
            ]
            setTabItems(newTabItems);
        }
        setTitle(localizations.title);
        setLoading(false);   
    }
            
    return (
        <>
            <Helmet>
                <title>{Texts.title}</title>
                <meta name="description" content={Texts.description}/>
            </Helmet>
            <ToolBar>
                {title}
            </ToolBar>
            <DesktopMenuDrawer/> 
            <div style={styles.container}>
                {!loading && <div style={styles.content}>
                    <h1 style={styles.desktopTitle}>{title}</h1>
                    <Tabs styles={styles.tabs} path="/teacherAds/" tabItems={tabItems} selectedTab={params.tab!}/>                    
                </div>}
                <div style={styles.banner}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}
                </div> 
            </div>      
            {openToast && <Toast setDisplay={setOpenToast}>{toastMessage}</Toast>}
            <Alert/>
        </>
    )
}