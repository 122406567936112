import { Color } from "../enums/Color";

export const card = {
    mobile: {              
        wrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            borderBottom: "2px solid " + Color.Green,                        
            paddingTop: "5px",
            paddingBottom: "5px",
            alignItems: "center"
        },
        logo: {
            height: "50px",
            width: "50px",
            margin: "10px",
            objectFit: "contain"
        }, 
        university: {
            fontSize: "13px"
        },
        degree: {
            marginTop: "2px",
            marginBottom: "2px",
            fontWeight: "bold"
        },
        degreeSecondary: {
            fontSize: "13px"
        }    
    },
    desktop: {}
}