import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Localization } from "../../models/Localization";
import { ToolBar } from "../../components/Shared/ToolBar";
import { getDegrees, getRequestModel } from "../../services/PrivateDegreeService";
import { PrivateDegreeResponseModel } from "../../models/PrivateDegreeResponseModel";
import { ListLoader } from "../../loaders/ListLoader";
import { NoData } from "../../components/Shared/NoData";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { getSeoName } from "../../helpers/Common";
import { SingleSelectInput } from "../../components/Shared/Inputs/SingleSelectInput";
import { Option } from "../../models/Option";
import { ReactComponent as DropdownIcon } from "../../assets/svgs/drop_down.svg";
import { SEOKeywords } from "../../helpers/LayoutTexts";
import { DegreeCard } from "../../components/Shared/DegreeCard";
import { Degree } from "../../models/Degree";

export const PrivateDegreeListPage: React.FC = () => { 
    const navigate = useNavigate();     
    const [searchParams] = useSearchParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("degreeListPage"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [isFilterLoad, setIsFilterLoad] = useState<boolean>(false);
    const [isFilterExpand, setIsFilterExpand] = useState<boolean>(false);
    const [categories, setCategories] = useState<Option[]>([]);     
    const [universities, setUniversities] = useState<Option[]>([]); 
    const [degreePeriods, setDegreePeriods] = useState<Option[]>([]); 
    const categoryId: number = searchParams.get("categoryId") === null ? -1 : +searchParams.get("categoryId")!;    
    const universityId: number = searchParams.get("universityId") === null ? -1 : +searchParams.get("universityId")!;
    const degreePeriodId: number = searchParams.get("degreePeriodId") === null ? -1 : +searchParams.get("degreePeriodId")!;
    const keyword: string = searchParams.get("keyword") === null ? "" : searchParams.get("keyword")!;
    const [degrees, setDegrees] = useState<Degree[]>([]);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("degreeListPage"));                                                                          
    }, [isMobile]);

    useEffect(() => {   
        window.scrollTo(0, 0);                                     
        getLocalizations(); 
        getRequestModelData();
        setSearchKeyword(keyword);    
        expandFilterPanel();
    }, []);

    useEffect(() => { 
        getDegreeList();                            
    }, [categoryId, universityId, degreePeriodId, keyword]);

    async function getLocalizations() {
        await getPageLocalizations(4, 12)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList); 
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                         
    }

    async function getRequestModelData() {        
        await getRequestModel()
        .then((requestModel: any) => {
            setCategories(requestModel.PrivateDegreeCategories);
            setUniversities(requestModel.PrivateUniversities);
            setDegreePeriods(requestModel.PrivateDegreePeriods);    
            setIsFilterLoad(true);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });         
    }
    
    async function getDegreeList() {
        setLoading(true);
        let responseModel: PrivateDegreeResponseModel = {
            degreeCategoryId: categoryId,
            universityId: universityId,
            degreePeriodId: degreePeriodId,
            keyword: keyword
        }
        await getDegrees(responseModel)
        .then((response: any) => {         
            setDegrees(response);             
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });         
    }    
    
    const expandFilterPanel = () => {
        if(categoryId !== -1 || universityId !== -1 || degreePeriodId !== -1 || keyword !== "") {
            setIsFilterExpand(true);
        }
    }

    const setSelectedCategory = (selectedCategoryId: number) => {        
        searchParams.set("categoryId", selectedCategoryId.toString());
        handleNavigate();
    }

    const setSelectedUniversity = (selectedUniversityId: number) => {        
        searchParams.set("universityId", selectedUniversityId.toString());
        handleNavigate();
    }

    const setSelectedDegreePeriod = (selectedDegreePeriodId: number) => {        
        searchParams.set("degreePeriodId", selectedDegreePeriodId.toString());
        handleNavigate();
    }

    const setKeyword = (keywordInput: string) => {
        if(keywordInput === "") {
            searchParams.delete("keyword");
        }   
        else {                
            searchParams.set("keyword", keywordInput);
        }
        handleNavigate();
    }
    
    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchKeyword(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setKeyword(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleNavigate = () => {                                    
        const newUrl = "/privateDegrees/" + getPageSEO() + `?${searchParams}`;
        navigate(newUrl);                
    }

    const navigateToGovernmentDegrees = () => {
        navigate("/governmentDegrees" + SEOKeywords.privateDegree);
    }

    const getPageSEO = (): string => {        
        let categorySEO = "", universitySEO = "private university";                        
        if(searchParams.get("categoryId") !== null && searchParams.get("categoryId") !== '-1') {                 
            let selectedCategoryId = +searchParams.get("categoryId")!;                
            categorySEO = "for " + categories.find(x => x.Value === selectedCategoryId)!.SecondaryOption!;
        }
        if(searchParams.get("universityId") !== null && searchParams.get("universityId") !== '-1') {                 
            let selectedUniversityId = +searchParams.get("universityId")!;                
            universitySEO = universities.find(x => x.Value === selectedUniversityId)!.SecondaryOption!;
        }
        let pageSEO = `${universitySEO} degrees ${categorySEO} in sri lanka`;
        pageSEO = pageSEO.trim();
        return getSeoName(pageSEO);
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer />
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            <div style={styles.accordionButtonsContainer}>
                <div
                    style={{ ...styles.accordionButton, ...styles.accordionButton.left }}
                    onClick={navigateToGovernmentDegrees} 
                >
                    {localizations.find(({ ElementName }) => ElementName === 'vwGovernmentLabel')?.ElementValue!}
                </div>
                <div style={{ ...styles.accordionButton, ...styles.accordionButton.right, ...styles.accordionButton.selected }}>
                    {localizations.find(({ ElementName }) => ElementName === 'vwPrivateLabel')?.ElementValue!}
                </div>
            </div>
            <div style={{...styles.filter, ...(isFilterExpand && styles.filter.expand)}}>
                <div style={{...styles.filterExpandButton, ...(isFilterExpand && styles.filterExpandButton.expand)}} onClick={() => setIsFilterExpand((prev) => !prev)}>
                    {"Search Filters"}
                    <DropdownIcon style={{...styles.expandButtonIcon, ...(isFilterExpand && styles.expandButtonIcon.up)}}/>
                </div>
                {(isFilterLoad && isFilterExpand) && <div style={styles.filterContent}>
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwCategoryLabel')?.ElementValue!}
                        options={categories}                    
                        selectedId={categoryId}
                        setSelected={setSelectedCategory}
                        searchEnabled={true}
                    />                    
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwUniversityLabel')?.ElementValue!}                        
                        options={universities} 
                        selectedId={universityId}
                        setSelected={setSelectedUniversity}
                        searchEnabled={true}
                    />
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwDegreePeriodLabel')?.ElementValue!}                        
                        options={degreePeriods} 
                        selectedId={degreePeriodId}
                        setSelected={setSelectedDegreePeriod}
                    /> 
                    <input 
                        type="text"
                        placeholder="Search by keywords"
                        value={searchKeyword}
                        onChange={(e) => handleKeywordChange(e)}                        
                        style={styles.searchInput}
                    />
                </div>}
            </div>
            {loading ? <ListLoader/> : 
            <div style={styles.container}>
                {degrees.length === 0 ? <NoData/> : 
                <>
                    <div style={styles.listCount}>{degrees.length + " Degrees"}</div>
                    {degrees.map((degree, index) =>
                        <div key={index}>
                            <Link to={`/privateDegreeDisplay/${degree.DegreeId}/` + getSeoName(degree.DegreeName_ENG + "-" + degree.UniversityName_ENG)} style={styles.link}>
                                <DegreeCard degree={degree} />
                            </Link>                            
                        </div>                     
                    )}  
                </>}    
            </div>}
        </div>
    )
}