import { Color } from "../enums/Color";

export const popUpDialog = {
    mobile: {
        position: "fixed" as "fixed",
        left: "0",
        right: "0",
        bottom: "0",
        top: "0",
        transition: "transform 0.3s ease-out",
        zIndex: "2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        backdrop: {
            position: "absolute" as "absolute",
            left: "0",
            right: "0",
            bottom: "0",
            top: "0",
            backgroundColor: Color.Black,
            opacity: 0.4
        },
        container: {
            position: "relative",
            width: "96%",
            height: "83vh",
            backgroundColor: Color.White,
            borderRadius: "2.41vw",
            padding: "2.41vw"
        },
        closeButton: {
            position: "absolute" as "absolute",
            width: "6.28vw",
            height: "6.28vw",
            top: "4.42vw",
            right: "4.83vw",
            cursor: "pointer",
            zIndex: "3"
        }
    },
    desktop: {        
        justifyContent: "center",
        container: {
            borderRadius: "0.52vw",            
            padding: "1.04vw"
        },
        closeButton: {
            width: "1.35vw",
            height: "1.35vw",
            top: "0.73vw",
            right: "0.73vw"
        }
    }
}