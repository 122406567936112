import { Color } from "../enums/Color";

export const profilePage = {
    mobile: {
        container: {
            marginTop: "50px"
        },
        imageWrapper: {
            margin: "20px 0 42px 0"
        },
        image: {
            height: "auto",
            width: "-webkit-fill-available",
            objectFit: "cover",            
            backgroundColor: Color.LightAsh
        },
        detailContainer: {
            margin: "20px 5%"
        },
        name: {
            fontSize: "16px",
            fontWeight: "bold",
            margin: "10px 0 10px 0"
        },
        rowWrapper: {
            display: "flex",
            flexDirection: "row"
        },
        tepNo: {
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            flexWrap: "wrap-reverse",
            fontSize: "16px",
            marginTop: "8px",
            cursor: "pointer",
            icon: {
                height: "18px",
                width: "18px",
                marginLeft: "8px",
                fill: Color.Green
            }            
        },
        description: {
            marginTop: "10px"
        },
        submitButton: {
            height: "50px",
            width: "-webkit-fill-available",
            background: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            margin: "30px 0"
        }
    },
    desktop: {
        container: {
            margin: "60px 0 20px 0",
            paddingLeft: "32%",
            paddingRight: "32%"
        },
        imageWrapper: {
            margin: "48px 0 42px 0"
        },
        image: {            
            width: "60%",
            margin: "0 20%",
            borderRadius: "0.41vw"
        }
    }
}