import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { DesktopMenuDrawer } from "../components/Shared/DesktopMenuDrawer";
import { ToolBar } from "../components/Shared/ToolBar";
import { DetailLoader } from "../loaders/DetailLoader";
import { Localization } from "../models/Localization";
import { StyleContext } from "../providers/StyleContextProvider";
import { getPageLocalizations } from "../services/CommonService";
import { Footer } from "../components/Shared/Footer";

interface StateProps { 
    price: string; 
}

export const BankDetailsPage: React.FC = () => {   
    const location = useLocation(); 
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("displayPage")); 
    const state = location.state as StateProps;   
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  

    useEffect(() => {   
        window.scrollTo(0, 0);    
        getLocalizations();                               
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("displayPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(9, 34)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);               
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                       
    }

    const toWhatsapp = (event: React.MouseEvent) => {
        event.stopPropagation();
        window.open(`https://wa.me/94719464357`, '_blank');
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            {loading ? <DetailLoader/> : 
            <>
                <div style={styles.container}>
                    <div style={styles.description}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwDescription')?.ElementValue.replace("{amount}", state.price)}
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwBankLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{"Sampath Bank PLC"}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwBranchLabel')?.ElementValue}
                        </div>
                        <div style={styles.value}>{"Maharagama Super"}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwAccountNumber')?.ElementValue}
                        </div>
                        <div style={styles.value}>{"009210012556"}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwAccountName')?.ElementValue}
                        </div>
                        <div style={styles.value}>{"CLIPES SOLUTIONS (PRIVATE) LIMITED"}</div>
                    </div>
                    <div>
                        <div style={styles.label}>
                            {localizations.find(({ ElementName }) => ElementName === 'vwSwiftCode')?.ElementValue}
                        </div>
                        <div style={styles.value}>{"BSAMLKLX"}</div>
                    </div>
                    <div style={styles.warning} onClick={(e) => toWhatsapp(e)}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwWarning')?.ElementValue}
                    </div>
                </div>
                {isMobile && <Footer/>}
            </>}
        </div>
    )
}