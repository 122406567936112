import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { StyleContext } from "../../providers/StyleContextProvider";
import { Banner } from "../../models/Banner";
import { Adsense } from "@ctrl/react-adsense";
import { getBanners, updateBannerClickCount } from "../../services/CommonService";

interface Props {    
    typeId: number;
    adsenseClient: string;
    adsenseSlotOne: string;
    adsenseSlotTwo?: string;
}

export const HorizontalBanner: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("horizontalBanner"));  
    const [bannersOne, setBannersOne] = useState<Banner[]>([]);
    const [bannersTwo, setBannersTwo] = useState<Banner[]>([]);                
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [loading, setLoading] = useState<boolean>(true);
   
    useEffect(() => {                     
        setStyles(styleContext.getComponentStyle("horizontalBanner"));                                      
    }, [isMobile]);
        
    useEffect(() => {
        getBannerList();
    }, []);

    const sliderSettings = {       
        infinite: true,        
        dots: false,         
        rows: 1,
        speed: 1000,
        autoplay: true,      
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: false
    };
    
    async function getBannerList() {             
        await getBanners(props.typeId)
        .then((bannerList: Banner[]) => {  
            if(isMobile){
                setBannersOne(bannerList);
            }
            else {                          
                let bannerListOne = bannerList.filter(x => x.BannerUrl.includes("banner-i-"));
                setBannersOne(bannerListOne);
                let bannerListTwo = bannerList.filter(x => x.BannerUrl.includes("banner-ii-"));
                setBannersTwo(bannerListTwo); 
            }           
            setLoading(false);
        })    
        .catch((error) => {            
            //toErrorPage(error);
        });
    }

    async function redirectToBannerUrl(banner: Banner) {        
        window.open(banner.BannerLink, '_blank');
        await updateBannerClickCount(banner.BannerId); 
    }

    return (
        <>        
            {!loading && <div style={styles.wrapper}>
                <div style={styles.sliderOne}>                
                    {bannersOne.length > 0 ? <Slider {...sliderSettings}>
                        {bannersOne.map((banner, index) =>
                            <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                                <img src={banner.BannerUrl} style={styles.banner}></img>                           
                            </div>                     
                        )}
                    </Slider> 
                    : <Adsense
                        client={props.adsenseClient}
                        slot={props.adsenseSlotOne}
                    />}               
                </div>
                <div style={styles.sliderTwo}>
                    {bannersTwo.length > 0 ? <Slider {...sliderSettings}>
                        {bannersTwo.map((banner, index) =>
                            <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                                <img src={banner.BannerUrl} style={styles.banner}></img>                           
                            </div>                     
                        )}
                    </Slider>
                    : <Adsense
                        client={props.adsenseClient}
                        slot={props.adsenseSlotTwo!}
                    />}
                </div>
            </div>}
        </>
    );
}