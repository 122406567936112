import ContentLoader from 'react-content-loader';
import { Color } from '../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const styles = {
    mobile: {
        card: {
            display: "inline-block",
            width: "100%",
            margin: "1%",
            height: "12vh"
        },
        box: {
            w: "20vw",
            h: "20vw"
        },
        longRect: {
            w: "50vw",
            h: "1.8vh",
            x: "25vw"
        },
        mediumRect: {
            w: "40vw",
            h: "1.5vh",
            x: "25vw"
        },
        smallRect: {
            w: "18vw",
            h: "1.5vh",
            x: "70vw"
        },
        borderRadius: "2vw"
    },
    desktop: {
        width: "68%",
        marginTop: "8.8vh",
        marginLeft: "1%",
        marginRight: "0px",
        card: {
            height: "20vh"
        },
        box: {
            w: "7.3vw",
            h: "7.3vw"
        },
        longRect: {
            x: "10vw",
            w: "30vw"
        },
        mediumRect: {
            x: "10vw",
            w: "25vw"    
        },
        smallRect: {
            x: "50vw",
            w: "10vw"
        },
        borderRadius: "0.3vw"
    }
};

export const CardListLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles = styles.mobile;
    if (!isMobile) {
        let extendStyles = merge(styles.mobile, styles.desktop);
        loaderStyles = extendStyles;
    }
    const loaderElements = new Array<any>();
    const loaderElementsCount = 10;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={loaderStyles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={loaderStyles.card.height}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect
                        x="0" 
                        y="0"
                        rx={loaderStyles.borderRadius}
                        ry={loaderStyles.borderRadius}
                        width={loaderStyles.box.w}
                        height={loaderStyles.box.h}
                    />
                    <rect
                        x={loaderStyles.longRect.x}
                        y="20%" 
                        rx={loaderStyles.borderRadius}
                        ry={loaderStyles.borderRadius}
                        width={loaderStyles.longRect.w}
                        height={loaderStyles.longRect.h}
                    />
                    <rect
                        x={loaderStyles.mediumRect.x}
                        y="50%"
                        rx={loaderStyles.borderRadius}
                        ry={loaderStyles.borderRadius}
                        width={loaderStyles.mediumRect.w}
                        height={loaderStyles.mediumRect.h}
                    />
                    <rect
                        x={loaderStyles.smallRect.x}
                        y="50%"
                        rx={loaderStyles.borderRadius}
                        ry={loaderStyles.borderRadius}
                        width={loaderStyles.smallRect.w}
                        height={loaderStyles.smallRect.h}
                    />
                </ContentLoader>
            </div>
        )
    }
    return (
        <div style={loaderStyles}>
            {loaderElements}
        </div>
    );
}