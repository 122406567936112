import { Color } from "../enums/Color";

export const degreeListPage = {
    mobile: {                 
        desktopTitle: {
            display: "none"
        },
        container: {
            margin: "20px 5% 20px 5%",
            paddingTop: "2px"
        },        
        searchInput: {
            height: "38px",
            width: "-webkit-fill-available",
            backgroundColor: Color.White,
            border: "2px solid " + Color.Green,
            borderRadius: "20px",
            padding: "0 15px",
            marginTop: "20px"
        },
        link: {
            textDecoration: "none",
            color: Color.Black
        },
        dialog: {
            dialog: {
                height: "20%",
                top: "40%"
            },
            title: {
                height: "25%"      
            },
            container: {
                height: "60%",
                overflow: "auto"            
            },            
            buttonWrapper: {
                paddingTop: "0px"
            },
            button: {            
                float: "right"
            }
        },        
        listCount: {            
            display: "flex",
            fontSize: "4vw",
            color: Color.Green,            
            fontWeight: "bold",
            marginBottom: "10px"
        },
        filter: {
            position: "relative",
            margin: "20px 4.83vw 0 4.83vw",                       
            backgroundColor: Color.LightAsh,
            borderRadius: "2.41vw"            
        },
        filterExpandButton: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "10.86vw",
            lineHeight: "10.86vw",           
            paddingLeft: "3.62vw",
            paddingRight: "3.62vw",            
            border: "none",
            backgroundColor: Color.DarkAsh,
            borderRadius: "2.41vw",        
            cursor: "pointer",
            boxSizing: "border-box",
            WebkitTapHighlightColor: 'transparent',
            fontWeight: "bold",
            expand: {
                borderRadius: "2.41vw 2.41vw 0 0"
            }
        },        
        expandButtonIcon: {
            height: "4.58vw",
            width: "4.1vw",
            margin: "auto",
            marginRight: "0",
            transition: "transform .1s ease-in-out",
            up: {
                transform: "rotate(180deg)"
            }
        },
        filterContent: {
            padding: "0 4.83vw 4.83vw 4.83vw"
        },
        accordionButtonsContainer: {            
            display: "flex",
            justifyContent: "center",
            marginTop: "70px"
        },
        accordionButton: {
            height: "8.42vw",
            width: "32vw",
            border: "2px solid " + Color.DarkAsh,                        
            fontWeight: "bold",
            fontSize: "3.83vw",
            backgroundColor: Color.White,
            color: Color.SharpAsh,
            textAlign: "center" as "center",
            cursor: "pointer",
            letterSpacing: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            WebkitTapHighlightColor : "transparent",            
            left: {                
                borderRadius: "5px 0px 0px 5px"
            },
            right: {
                borderRadius: "0px 5px 5px 0px"
            },
            selected: {
                border: "2px solid " + Color.DeepBlack,
                color: Color.White,
                backgroundColor: Color.DeepBlack,
                fontWeight: "500"
            }
        }
    },
    desktop: {        
        desktopTitle: {
            display: "block",
            position: "fixed" as "fixed",
            top: "0",
            left: "32%",
            right: "32%",            
            textAlign: "center" as "center",
            color: Color.Green,
            background: Color.White,
            margin: 0,
            paddingTop: "48.4px",
            zIndex: 1
        },              
        container: {
            margin: "18px 0 20px 0",
            paddingLeft: "32%",
            paddingRight: "32%"
        },        
        dialog: {
            dialog: {                       
                top: "30%",
                right: "35%",
                padding: "2%"
            },
            container: {
                height: "48%",                
                paddingTop: "2%"          
            }
        },        
        listCount: {
            fontSize: "1.34vw"
        },
        filter: {
            position: "unset",
            margin: "18px 32% 0 32%",
            borderRadius: "0.73vw"
        },
        filterExpandButton: {
            height: "3.2vw",            
            paddingLeft: "1.62vw",
            paddingRight: "1.62vw",
            lineHeight: "3.2vw",
            borderRadius: "0.73vw",
            expand: {
                borderRadius: "0.73vw 0.73vw 0 0"
            }
        },        
        expandButtonIcon: {
            width: "0.8vw",
            height: "0.8vw"
        },
        filterContent: {
            padding: "0 1.62vw 1.62vw 1.62vw"
        },
        accordionButtonsContainer: {            
            marginTop: "112px"
        },
        accordionButton: {
            height: "2.6vw",
            width: "10vw",
            fontSize: "1.2vw",
            fontWeight: "500",
            left: {                
                borderRadius: "0.52vw 0px 0px 0.52vw"
            },
            right: {                
                borderRadius: "0px 0.52vw 0.52vw 0px"
            },
            selected: {                
                fontWeight: "bold"
            }
        }
    }
}