import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { ReactComponent as Dropdown } from "../../../assets/svgs/drop_down.svg";
import { ReactComponent as Close } from "../../../assets/svgs/close-circle-ash.svg";
import { InputValidation } from '../../../models/InputValidation';

interface Props {         
    values: string[];
    children?: React.ReactNode; 
    validation?: InputValidation;   
    onClick?(): void;
    onReset?(): void;
};

export const DialogInput: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const [value, setValue] = useState<string>(""); 
    const [rows, setRows] = useState<number>(1); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const validation = props.validation;    
    const rowWidth: number = 36;

    useEffect(() => {          
        setStyles(styleContext.getComponentStyle("input"));                                                                   
    }, [isMobile]);

    
    useEffect(() => {  
        createInput();                                                                          
    }, [props.values]);

    const createInput = () => {
        let tempValue: string = "";
        let tempRows: number = 1; 
        if(props.values.length > 0) {
            tempRows = 0;                     
            props.values.forEach((value, index) => {
                if(index < (props.values.length - 1)) {                
                    tempValue = tempValue + value + "\n";
                } else {
                    tempValue = tempValue + value;
                }                        
                tempRows = tempRows + Math.ceil(value.length / rowWidth);                
            }); 
            let tempStyles = styles;
            tempStyles.input.long.paddingBottom = (6 + tempRows * 3).toString() + "px";
            setStyles(tempStyles);
        }
        setValue(tempValue);
        setRows(tempRows);
    }

    const resetInput = (event: React.MouseEvent) => {
        event.stopPropagation();
        if(props.onReset) {
            props.onReset();
        }
    }

    return (
        <>
            <div style={styles.wrapper}>
                <textarea style={{...styles.input, ...(props.values.length > 0 && styles.input.long)}} value={value} rows={rows} readOnly/>
                <label style={{...styles.label, ...((props.values.length > 0) && styles.label.focus), ...(validation?.invalid && styles.label.invalid)}}>{props.children}</label>
                <Dropdown style={styles.icon} />
                {props.onReset && value !== "" && <Close style={styles.resetIcon} onClick={(e) => resetInput(e)} />}
            </div> 
            {validation?.invalidMessage && <div style={styles.invalidMessage}>{validation.invalidMessage}</div>}
        </>       
    );
}