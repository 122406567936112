import { useContext, useEffect, useState } from "react";
import { StyleContext } from "../../providers/StyleContextProvider";
import { Localization } from "../../models/Localization";
import { TeacherAd } from "../../models/TeacherAd";
import { useMediaQuery } from "react-responsive";
import { getPageLocalizations } from "../../services/CommonService";
import { deleteTeacherAd, getTeacherAds, getTeacherAdsCount } from "../../services/TeacherService";
import { CardListLoader } from "../../loaders/CardListLoader";
import { NoData } from "../Shared/NoData";
import { Button } from "../Shared/Button";
import { EditAd } from "./EditAd";
import { MessageDialog } from "../Shared/Dialogs/MessageDialog";
import { TeacherAdsCount } from "../../models/TeacherAdsCount";

interface Props {
    teacherId: number;
}

export const MyAds: React.FC<Props> = (props) => { 
    const teacherId = props.teacherId;      
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("myAds"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [teacherAds, setTeacherAds] = useState<TeacherAd[]>([]);
    const [editMode, setEditMode] = useState<boolean>(false); 
    const [editAd, setEditAd] = useState<TeacherAd>(); 
    const [openMessage, setOpenMessage] = useState<boolean>(false);
    const [deleteAdId, setDeleteAdId] = useState<number>(); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [adsCount, setAdsCount] = useState<TeacherAdsCount>();  
    const [status, setStatus] = useState<string>("LIVE");     

    useEffect(() => {    
        window.scrollTo(0, 0);
        getLocalizations(); 
        getAdsCount();                                    
    }, []);

    useEffect(() => { 
        getTeacherAdList();                            
    }, [status]);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("myAds"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(7, 19) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }

    async function getAdsCount() {                
        await getTeacherAdsCount(teacherId)
        .then((teacherAdsCount: TeacherAdsCount) => {            
            setAdsCount(teacherAdsCount);                   
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }

    async function getTeacherAdList() {        
        await getTeacherAds(teacherId, status)
        .then((teacherAdList: TeacherAd[]) => {
            setTeacherAds(teacherAdList);              
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });        
    }

    const openAdEdit = (teacherAd: TeacherAd) => {
        setEditAd(teacherAd);
        setEditMode(true);
    }

    const closeAdEdit = () => {
        setEditMode(false);
        getTeacherAdList();
    }

    const openAdDelete = (teacherAdId: number) => {
        setOpenMessage(true);
        setDeleteAdId(teacherAdId);
    }

    const closeAdDelete = () => {
        setOpenMessage(false);        
    }

    async function deleteAd() {
        await deleteTeacherAd(deleteAdId!)
        .then((success: boolean) => {
            setOpenMessage(false);
            getTeacherAdList();
        })
        .catch((error) => {            
            //toErrorPage(error);
        });
    }

    return (
        <div style={styles}>            
            {loading ? <CardListLoader/> : 
            <div style={styles.container}>
                {editMode ? <EditAd teacherAd={editAd!} closeAdEdit={closeAdEdit}/> : 
                <>                                         
                    {adsCount && <div style={styles.buttonsWrapper}>                            
                        <Button 
                            style={{...styles.statusButton, ...styles.statusButton.live, ...(status === 'LIVE' && styles.statusButton.active)}}
                            onClick={() => setStatus('LIVE')}
                        >
                            {"Live " + adsCount.LiveCount}
                        </Button>
                        <Button 
                            style={{...styles.statusButton, ...styles.statusButton.pending, ...(status === 'PENDING' && styles.statusButton.active)}}
                            onClick={() => setStatus('PENDING')}
                        >
                            {"Pending " + adsCount.PendingCount}
                        </Button>
                        <Button 
                            style={{...styles.statusButton, ...styles.statusButton.unpaid, ...(status === 'UNPAID' && styles.statusButton.active)}}
                            onClick={() => setStatus('UNPAID')}
                        >
                            {"Unpaid " + adsCount.UnpaidCount}
                        </Button>
                        <Button 
                            style={{...styles.statusButton, ...styles.statusButton.expired, ...(status === 'EXPIRED' && styles.statusButton.active)}}
                            onClick={() => setStatus('EXPIRED')}
                        >
                            {"Expired " + adsCount.ExpiredCount}
                        </Button>                            
                    </div>}
                    {teacherAds.length === 0 ? <NoData/> :
                    <>
                        {teacherAds.map((teacherAd, index) =>
                            <div key={index} style={styles.card}>                            
                                {teacherAd.TeacherAdUrl && <img src={teacherAd.TeacherAdUrl} style={styles.image} alt="Teacher Ad"></img>}
                                <div style={{...styles.label, ...styles.initialLabel}}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwGradeLabel')?.ElementValue!}
                                    <span style={styles.value}>{teacherAd.Grades}</span>
                                </div>
                                <div style={styles.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwSubjectLabel')?.ElementValue!}
                                    <span style={styles.value}>{teacherAd.Subjects}</span>
                                </div>
                                <div style={styles.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwMediumLabel')?.ElementValue!}
                                    <span style={styles.value}>{teacherAd.Mediums}</span>
                                </div>
                                <div style={styles.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwClassTypeLabel')?.ElementValue!}
                                    <span style={styles.value}>{teacherAd.ClassTypes}</span>
                                </div>
                                <div style={styles.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwDistrictLabel')?.ElementValue!}
                                    <span style={styles.value}>{teacherAd.Districts}</span>
                                </div>
                                <div style={styles.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwTownLabel')?.ElementValue!}
                                    <span style={styles.value}>{teacherAd.Towns}</span>
                                </div>
                                <div style={styles.buttonWrapper}>
                                    <Button style={styles.editButton} onClick={() => openAdEdit(teacherAd)}>Edit</Button>
                                    <Button style={styles.deleteButton} onClick={() => openAdDelete(teacherAd.TeacherAdId)}>Delete</Button>
                                </div>                            
                            </div>
                        )}
                    </>} 
                </>}               
            </div>}
            {openMessage && <MessageDialog 
                styles={styles.dialog}
                title={"Delete"} 
                message={localizations.find(({ ElementName }) => ElementName === 'vwDeleteMessage')?.ElementValue!}
                handleClose={closeAdDelete}
                handleOk={deleteAd}/>
            }
        </div>
    )
}