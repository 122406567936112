import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ToolBar } from "../components/Shared/ToolBar";
import { Localization } from "../models/Localization";
import { StyleContext } from "../providers/StyleContextProvider";
import { getPageLocalizations } from "../services/CommonService";
import { Button } from "../components/Shared/Button";
import { DesktopMenuDrawer } from "../components/Shared/DesktopMenuDrawer";
import { ChatResponseModel } from "../models/ChatResponseModel";
import { getChatResponse } from "../services/ChatService";
import { ReactComponent as Send } from "../assets/svgs/send.svg";
import { flushSync } from "react-dom";
import { ChatPageLoader } from "../loaders/ChatPageLoader";
import { ExtendableTextAreaInput } from "../components/Shared/Inputs/ExtendableTextAreaInput";

export const ChatPage: React.FC = () => {
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("chatPage"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);    
    const [chatResponses, setChatResponses] = useState<ChatResponseModel[]>([]);
    const [question, setQuestion] = useState<string>("");    
    const listRef = useRef<HTMLUListElement | null>(null);    

    useEffect(() => {
        window.scrollTo(0, 0);    
        getLocalizations();                                         
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("chatPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(10, 32) as unknown as Localization[];
        setLocalizations(localizationList);   
        setLoading(false);     
    }   
    
    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();        
        let chatResponse: ChatResponseModel = {
            question: question,            
            answer: ""
        };
        let tempChatResponses = [...chatResponses];        
        tempChatResponses.push(chatResponse);                
        flushSync(() => {setChatResponses(tempChatResponses);});
        listRef.current?.lastElementChild?.scrollIntoView();
        const answer = await getChatResponse(question) as unknown as string;        
        tempChatResponses[tempChatResponses.length - 1].answer = answer;        
        setQuestion("");
        flushSync(() => {setChatResponses(tempChatResponses);});
        listRef.current?.lastElementChild?.scrollIntoView();        
    }

    return (
        <div style={styles}>            
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar> 
            <DesktopMenuDrawer />
            {loading ? <ChatPageLoader /> : <>
                <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>           
                <div style={styles.container}>
                    <div style={styles.warningMessage}>{localizations.find(({ ElementName }) => ElementName === 'vwWarningMessage')?.ElementValue}</div>                
                    <ul ref={listRef} style={styles.list}>
                        {chatResponses.map((response, index) => 
                            <div key={index}>
                                <div style={styles.question}>{response.question}</div>                            
                                <div style={styles.answer}>{response.answer === "" ? "TuteClass is typing..." : response.answer}</div>
                            </div>
                        )}
                    </ul>
                    <form onSubmit={handleSubmit} style={styles.form}>                    
                        <ExtendableTextAreaInput                                                         
                            value={question}
                            handleChange={setQuestion}
                            styles={styles.input}
                        />                    
                        <Button type="submit" style={styles.button} onClick={handleSubmit}>
                            <Send style={styles.icon}/>
                        </Button>
                    </form>
                </div>
            </>}
        </div>
    )
}