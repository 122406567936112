import React, { useState } from 'react';

export interface Props {    
    styles: any;
    children?: React.ReactNode;
    onClick?(): void;
}


export const DropdownItem: React.FC<Props> = (props) => {
    const [hover, setHover] = useState(false);
    
    return (
        <div
            onMouseEnter={()=>{ setHover(true); }}
            onMouseLeave={()=>{ setHover(false); }} 
            style={{...props.styles,...(hover ? props.styles.hover : props.styles.leave)}} onClick={props.onClick}>
            {props.children}
        </div>
    );
}