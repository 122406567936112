import React from "react"
import ContentLoader from "react-content-loader"
import { Color } from "../enums/Color"

export const TagLoader: React.FC = (props) => {
    return (
        <div>
            <ContentLoader 
                speed={2}
                width={"100px"}
                height={20}                
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                {...props}
            >
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="20"/>                                              
            </ContentLoader>
        </div>
    )
}