import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { merge } from 'react-merge';
import { MenuOption } from '../../../models/MenuOption';

interface Props { 
    styles?: any;        
    title: string;    
    menuOptions: MenuOption[]; 
    labelId: number;
    optionId: number;
    getMenuSelected(menuOption: MenuOption[], labelId: number, optionId: number): void;    
    closeDialog(): void;    
};

export const SingleSelectMenuFilterDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [menuOptions, setMenuOptions] = useState<MenuOption[]>(props.menuOptions); 
    const [filteredMenuOptions, setFilteredMenuOptions] = useState<MenuOption[]>(props.menuOptions);  
    const [labelId, setLabelId] = useState<number>(props.labelId);   
    const [optionId, setOptionId] = useState<number>(props.optionId);   
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const languageCode = localStorage.getItem('languageCode')!;           

    useEffect(() => {           
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);        
    }, [isMobile]);

    const itemChecked = (selectedLabelId: number, selectedOptionId: number) => {        
        let tempMenuOptions = [...menuOptions];        
        if(labelId !== -1 && optionId !== -1) {
            tempMenuOptions.find(menuOption => menuOption.LabelId === labelId)!.Options.find(option => option.Value === optionId)!.Checked = false;                                
        } 
        tempMenuOptions.find(menuOption => menuOption.LabelId === selectedLabelId)!.Options.find(option => option.Value === selectedOptionId)!.Checked = true;           
        setMenuOptions(tempMenuOptions);

        let tempFilteredMenuOptions = [...filteredMenuOptions];        
        if(labelId !== -1 && optionId !== -1) {
            let previousSelectedOption = tempFilteredMenuOptions.find(menuOption => menuOption.LabelId === labelId)!.Options.find(option => option.Value === optionId);
            if(previousSelectedOption) {
                previousSelectedOption.Checked = false;
            }
        }  
        tempFilteredMenuOptions.find(menuOption => menuOption.LabelId === selectedLabelId)!.Options.find(option => option.Value === selectedOptionId)!.Checked = true;          
        setFilteredMenuOptions(tempFilteredMenuOptions);
        setLabelId(selectedLabelId);
        setOptionId(selectedOptionId);
        props.getMenuSelected(tempMenuOptions, selectedLabelId, selectedOptionId);
    }

    const filterOptions = (keyword: string) => {
        keyword = keyword.trim();        
        let filteredOptions = menuOptions.map(menuOption => ({
            LabelId: menuOption.LabelId,
            Label: menuOption.Label,
            Expand: true,            
            Options: menuOption.Options.filter(option => (
                option.PrimaryOption.toLowerCase().includes(keyword.toLowerCase()) ||
                option.SecondaryOption && option.SecondaryOption.toLowerCase().includes(keyword.toLowerCase())
            )),
        }));     
        filteredOptions = filteredOptions.filter(menuOption => menuOption.Options.length > 0);   
        setFilteredMenuOptions(filteredOptions);
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={{...styles.dialog, ...styles.filterDialog}}>        
                <div style={styles.title}>{props.title}</div>
                <input type="text" style={styles.search} placeholder={"Search"} onChange={(e) => filterOptions(e.target.value)}/>
                <div style={styles.container} className={"selector"}>
                    {filteredMenuOptions.map((menuOption, parentIndex) => 
                        <div key={parentIndex}>
                            <div style={styles.searchLabelWrapper}>
                                {menuOption.Label}                                
                            </div>
                            <div style={styles.optionContainer}>
                                {menuOption.Options.map((option, childIndex) => 
                                    <div key={childIndex} style={styles.optionWrapper} onClick={() => itemChecked(menuOption.LabelId, option.Value)}>                                                    
                                        <input type="radio" checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                                        <div style={styles.option}>
                                            {option.PrimaryOption}    
                                            {languageCode !== "ENG" && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>                                                                            
                    )}
                </div>
            </div> 
        </>       
    );
}