import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { AuthContext } from '../../providers/AuthContextProvider';
import { HeaderTexts as Texts } from '../../helpers/LayoutTexts';
import { merge } from 'react-merge';
import { Teacher } from '../../models/Teacher';
import { Student } from '../../models/Student';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';

interface Props {
    styles?: any;
}

export const ProfileEntrance: React.FC<Props> = (props) => {  
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const teacher: Teacher | null = authContext.getLoggedTeacher();
    const student: Student | null = authContext.getLoggedStudent();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("profileEntrance"));                     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [userAvatar, setUserAvatar] = useState<string>(Texts.defaultAvatar);

    useEffect(() => {   
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("profileEntrance"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);                    
    }, [isMobile]);

    useEffect(() => {
        if(teacher && teacher.Image) {
            setUserAvatar(teacher.Image);
        }
        else if(student && student.Image) {
            setUserAvatar(student.Image);
        }
    }, []);    

    const profileItems: any[] = [        
        { item: 
            <div onClick={() => {handleToProfile()}} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Profile"}</DropdownItem>
            </div>
        },
        { item: 
            <div onClick={() => {handleLogout()}} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Logout"}</DropdownItem>
            </div>
        }   
    ]

    const handleToProfile = () => {
        if(teacher) {
            navigate('/teachers/myProfile/teachers-and-tuition-classes-in-sri-lanka');
        }
        else if(student) {
            navigate('/studentProfile');
        }
    }    

    const handleLogin = () => {               
        navigate('/account/login');
    }

    const handleLogout = () => {
        if(teacher) {
            localStorage.removeItem("teacher");
        }
        else if(student) {
            localStorage.removeItem("student");
        }
        navigate('/account/login');
    }

    return (
        <div style={styles.wrapper}>                        
            {(teacher || student) ? <div style={styles.userWrapper}>
                <Dropdown styles={styles.userDropdown} items={profileItems}>
                    <img src={userAvatar} style={styles.userAvatar} />
                </Dropdown>
            </div> : 
            <Button type="button" style={styles.loginButton} onClick={handleLogin}>{"Login"}</Button>}
        </div>
    );
}