import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from 'react-slick';
import { ImageSliderLoader } from "../../loaders/ImageSliderLoader";

interface Props {
    styles: any;
    title: string;
    content: string;    
}

const sliderLinks = {
    mobile: {
        sin: [
            "https://api.tuteclass.com/img/sliders/mobile_degree_sin_1.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_sin_2.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_sin_3.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_sin_4.png",
            "https://api.tuteclass.com/img/sliders/mobile_lan_common.png"
        ],
        tam: [
            "https://api.tuteclass.com/img/sliders/mobile_degree_tam_1.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_tam_2.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_tam_3.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_tam_4.png",
            "https://api.tuteclass.com/img/sliders/mobile_lan_common.png"
        ],
        eng: [
            "https://api.tuteclass.com/img/sliders/mobile_degree_eng_1.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_eng_2.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_eng_3.png",
            "https://api.tuteclass.com/img/sliders/mobile_degree_eng_4.png",
            "https://api.tuteclass.com/img/sliders/mobile_lan_common.png"
        ]
    },
    desktop: {
        sin: [
            "https://api.tuteclass.com/img/sliders/degree_sin_1.png",
            "https://api.tuteclass.com/img/sliders/degree_sin_2.png",
            "https://api.tuteclass.com/img/sliders/degree_sin_3.png",
            "https://api.tuteclass.com/img/sliders/degree_sin_4.png",
            "https://api.tuteclass.com/img/sliders/lan_common.png"
        ],
        tam: [
            "https://api.tuteclass.com/img/sliders/degree_tam_1.png",
            "https://api.tuteclass.com/img/sliders/degree_tam_2.png",
            "https://api.tuteclass.com/img/sliders/degree_tam_3.png",
            "https://api.tuteclass.com/img/sliders/degree_tam_4.png",
            "https://api.tuteclass.com/img/sliders/lan_common.png"
        ],
        eng: [
            "https://api.tuteclass.com/img/sliders/degree_eng_1.png",
            "https://api.tuteclass.com/img/sliders/degree_eng_2.png",
            "https://api.tuteclass.com/img/sliders/degree_eng_3.png",
            "https://api.tuteclass.com/img/sliders/degree_eng_4.png",
            "https://api.tuteclass.com/img/sliders/lan_common.png"
        ]
    }
}

export const DegreeSlider: React.FC<Props> = (props) => {
    const styles = props.styles; 
    const [sliders, setSliders] = useState<string[]>([]);                
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {        
        getSliders();                     
    }, []);
    
    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        dots: false,         
        rows: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        useTransform: false
    };
    
    const getSliders = () => {
        let languageCode = localStorage.getItem('languageCode');
        if(isMobile) {
            switch (languageCode) {
                case "SIN": {
                    setSliders(sliderLinks.mobile.sin);               
                    break;
                }
                case "TAM": {
                    setSliders(sliderLinks.mobile.tam);
                    break;
                }
                case "ENG": {
                    setSliders(sliderLinks.mobile.eng);
                    break;
                }
            }
        }
        else {
            switch (languageCode) {
                case "SIN": {
                    setSliders(sliderLinks.desktop.sin);               
                    break;
                }
                case "TAM": {
                    setSliders(sliderLinks.desktop.tam);
                    break;
                }
                case "ENG": {
                    setSliders(sliderLinks.desktop.eng);
                    break;
                }
            }
        }
    }
    
    return (
        <>
            {props.title ? <div style={styles}>
                <div style={styles.leftWrapper}>
                    <div style={styles.title}>
                        {props.title}
                    </div>
                    <div style={styles.content}>
                        {props.content}
                    </div>
                </div>
                <div style={styles.rightWrapper}>
                    <Slider {...sliderSettings}>
                        {sliders.map((slider, index) =>
                            <div key={index}>
                                <img src={slider} style={styles.slider} alt="TuteClass degree"/>
                            </div>
                        )}
                    </Slider>
                </div>            
            </div>
            : <ImageSliderLoader styles={styles}/>}
        </>
    )
}