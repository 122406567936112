import { searchPage } from "./SearchPage";
import { listPage } from "./ListPage";
import { displayPage } from "./DisplayPage";
import { homePage } from "./HomePage";
import { input } from "./Input";
import { dialog } from "./Dialog";
import { toast } from "./Toast";
import { toolBar } from "./ToolBar";
import { card } from "./Card";
import { loginPage } from "./LoginPage";
import { footer } from "./Footer";
import { searchArea } from "./SearchArea";
import { header } from "./Header";
import { mobileMenu } from "./MobileMenu";
import { desktopMenu } from "./DesktopMenu";
import { subHeader } from "./SubHeader";
import { dropdown } from "./Dropdown";
import { noData } from "./NoData";
import { chatPage } from "./ChatPage";
import { tabs } from "./Tabs";
import { profileEntrance } from "./ProfileEntrance";
import { paymentResponsePage } from "./PaymentResponsePage";
import { profilePage } from "./ProfilePage";
import { termsAndConditionsPage } from "./TermsAndConditionsPage";
import { loginHeader } from "./LoginHeader";
import { horizontalBanner } from "./HorizontalBanner";
import { verticalBanner } from "./VerticalBanner";
import { myAds } from "./MyAds";
import { newAds } from "./NewAds";
import { responseAlert } from "./ResponseAlert";
import { pagination } from "./Pagination";
import { adViewPage } from "./AdViewPage";
import { teacherListPage } from "./TeacherListPage";
import { degreeListPage } from "./DegreeListPage";
import { cashPaymentPage } from "./CashPaymentPage";
import { classListPage } from "./ClassListPage";
import { classManagePage } from "./ClassManagePage";
import { popUpDialog } from "./PopUpDialog";

export const styleDictionary = new Map<string, any>();

styleDictionary.set("input", input);
styleDictionary.set("toolBar", toolBar);
styleDictionary.set("mobileMenu", mobileMenu);
styleDictionary.set("desktopMenu", desktopMenu);
styleDictionary.set("searchArea", searchArea);
styleDictionary.set("header", header);
styleDictionary.set("subHeader", subHeader);
styleDictionary.set("loginHeader", loginHeader);
styleDictionary.set("footer", footer);
styleDictionary.set("card", card);
styleDictionary.set("dialog", dialog);
styleDictionary.set("toast", toast);
styleDictionary.set("noData", noData);
styleDictionary.set("dropdown", dropdown);
styleDictionary.set("tabs", tabs);
styleDictionary.set("profileEntrance", profileEntrance);
styleDictionary.set("homePage", homePage);
styleDictionary.set("loginPage", loginPage);
styleDictionary.set("searchPage", searchPage);
styleDictionary.set("listPage", listPage);
styleDictionary.set("teacherListPage", teacherListPage);
styleDictionary.set("degreeListPage", degreeListPage);
styleDictionary.set("displayPage", displayPage);
styleDictionary.set("chatPage", chatPage);
styleDictionary.set("paymentResponsePage", paymentResponsePage);
styleDictionary.set("profilePage", profilePage);
styleDictionary.set("termsAndConditionsPage", termsAndConditionsPage);
styleDictionary.set("adViewPage", adViewPage);
styleDictionary.set("cashPaymentPage", cashPaymentPage);
styleDictionary.set("classListPage", classListPage);
styleDictionary.set("classManagePage", classManagePage);
styleDictionary.set("horizontalBanner", horizontalBanner);
styleDictionary.set("verticalBanner", verticalBanner);
styleDictionary.set("myAds", myAds);
styleDictionary.set("newAds", newAds);
styleDictionary.set("responseAlert", responseAlert);
styleDictionary.set("pagination", pagination);
styleDictionary.set("popUpDialog", popUpDialog);