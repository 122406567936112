import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Localization } from "../../models/Localization";
import { ToolBar } from "../../components/Shared/ToolBar";
import { getDegrees, getStreamSubjects, getRequestModel } from "../../services/GovernmentDegreeService";
import { GovernmentDegreeResponseModel } from "../../models/GovernmentDegreeResponseModel";
import { Degree } from "../../models/Degree";
import { DegreeCard } from "../../components/Shared/DegreeCard";
import { ListLoader } from "../../loaders/ListLoader";
import { ResponseModel } from "../../models/ResponseModel";
import { MessageDialog } from "../../components/Shared/Dialogs/MessageDialog";
import { NoData } from "../../components/Shared/NoData";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { getSeoName } from "../../helpers/Common";
import { ReactComponent as DropdownIcon } from "../../assets/svgs/drop_down.svg";
import { SingleSelectInput } from "../../components/Shared/Inputs/SingleSelectInput";
import { InputLoader } from "../../loaders/InputLoader";
import { Option } from "../../models/Option";
import { SubjectSelectInput } from "../../components/Degree/SubjectSelectInput";
import { SEOKeywords } from "../../helpers/LayoutTexts";

export const GovernmentDegreeListPage: React.FC = () => {  
    const navigate = useNavigate();  
    const [searchParams] = useSearchParams();    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("degreeListPage"));    
    const [loading, setLoading] = useState<boolean>(true);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [isFilterLoad, setIsFilterLoad] = useState<boolean>(false);
    const [isFilterExpand, setIsFilterExpand] = useState<boolean>(false);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [streams, setStreams] = useState<Option[]>([]); 
    const [subjects, setSubjects] = useState<Option[]>([]); 
    const [universities, setUniversities] = useState<Option[]>([]); 
    const [degreePeriods, setDegreePeriods] = useState<Option[]>([]); 
    const streamId: number = searchParams.get("streamId") === null ? -1 : +searchParams.get("streamId")!;
    const subjectIds: string = searchParams.get("subjectIds") === null ? "" : searchParams.get("subjectIds")!;
    const universityId: number = searchParams.get("universityId") === null ? -1 : +searchParams.get("universityId")!;
    const degreePeriodId: number = searchParams.get("degreePeriodId") === null ? -1 : +searchParams.get("degreePeriodId")!;
    const keyword: string = searchParams.get("keyword") === null ? "" : searchParams.get("keyword")!;
    const [degrees, setDegrees] = useState<Degree[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const [openDialog, setOpenDialog] = useState<boolean>(false);   
    const [dialogMessage, setDialogMessage] = useState<string>("");
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("degreeListPage"));                                                                           
    }, [isMobile]);

    useEffect(() => {
        window.scrollTo(0, 0);                                 
        getLocalizations(); 
        getRequestModelData();  
        if(streamId !== -1) {                        
            getStreamSubjectList(streamId, true);
        }  
        setSearchKeyword(keyword);    
        expandFilterPanel();                            
    }, []);

    useEffect(() => { 
        getDegreeList();                            
    }, [streamId, subjectIds, universityId, degreePeriodId, keyword]);

    async function getLocalizations() {        
        await getPageLocalizations(4, 10)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList); 
        })
        .catch((error) => {            
            //toErrorPage(error);
        }); 
    }

    async function getRequestModelData() {        
        await getRequestModel()
        .then((requestModel: any) => {
            setStreams(requestModel.Streams);
            setUniversities(requestModel.Universities);
            setDegreePeriods(requestModel.DegreePeriods);    
            setIsFilterLoad(true);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });         
    }

    async function getStreamSubjectList(streamId: number, isInitialLoad: boolean) {   
        setSubjectLoading(true);               
        await getStreamSubjects(streamId)
        .then((streamSubjects: any[]) => {
            setSubjects(streamSubjects);
            if(!isInitialLoad) {            
                let tempSubjectIds = "";
                if(streamId == 10) {
                    tempSubjectIds = "53";
                } else if(streamId == 12) {
                    tempSubjectIds = "57,58";
                } else if(streamId == 13) {
                    tempSubjectIds = "58,59";
                }
                if(tempSubjectIds !== "") {
                    setSelectedSubjects(tempSubjectIds);
                }
            }
            setSubjectLoading(false); 
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                 
    }

    const expandFilterPanel = () => {
        if(streamId !== -1 || subjectIds !== "" || universityId !== -1 || degreePeriodId !== -1 || keyword !== "") {
            setIsFilterExpand(true);
        }
    }

    const setSelectedStream = (selectedStreamId: number) => {        
        searchParams.set("streamId", selectedStreamId.toString());
        searchParams.delete("subjectIds");
        if(selectedStreamId !== -1) {                        
            getStreamSubjectList(selectedStreamId, false);
        }
        handleNavigate();
    }

    const setSelectedSubjects = (selectedSubjectIds: string) => {        
        searchParams.set("subjectIds", selectedSubjectIds);
        handleNavigate();
    }

    const setSelectedUniversity = (selectedUniversityId: number) => {        
        searchParams.set("universityId", selectedUniversityId.toString());
        handleNavigate();
    }

    const setSelectedDegreePeriod = (selectedDegreePeriodId: number) => {        
        searchParams.set("degreePeriodId", selectedDegreePeriodId.toString());
        handleNavigate();
    }

    const setKeyword = (keywordInput: string) => {
        if(keywordInput === "") {
            searchParams.delete("keyword");
        }   
        else {                
            searchParams.set("keyword", keywordInput);
        }
        handleNavigate();
    }
    
    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchKeyword(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setKeyword(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleNavigate = () => {                                    
        const newUrl = "/governmentDegrees/" + getPageSEO() + `?${searchParams}`;
        navigate(newUrl);                
    }

    async function getDegreeList() { 
        setLoading(true); 
        let subjectIdArray: number[] = subjectIds.split(',').map(Number); 
        if(subjectIdArray.length < 3) {
            subjectIdArray = [];
        }     
        let responseModel: GovernmentDegreeResponseModel = {
            streamId: streamId,
            subjectId_1: subjectIdArray[0],
            subjectId_2: subjectIdArray[1],
            subjectId_3: subjectIdArray[2],
            universityId: universityId,
            degreePeriodId: degreePeriodId,
            keyword: keyword
        }
        await getDegrees(responseModel)
        .then((response: ResponseModel) => {
            if(response.Status) {                    
                setDegrees(response.Data);
            }        
            else {
                setDialogMessage(response.Message);
                setOpenDialog(true);
            }     
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });            
    }

    const handleDialogOk = () => {
        setOpenDialog(false);
        navigate(-1);
    } 

    const navigateToPrivateDegrees = () => {
        navigate("/privateDegrees" + SEOKeywords.privateDegree);
    }

    const getPageSEO = (): string => {        
        let streamSEO = "streams", universitySEO = "government university";
        if(searchParams.get("streamId") !== null && searchParams.get("streamId") !== '-1') {                 
            let selectedStreamId = +searchParams.get("streamId")!;                
            streamSEO = streams.find(x => x.Value === selectedStreamId)!.SecondaryOption! + " stream";
        }       
        if(searchParams.get("universityId") !== null && searchParams.get("universityId") !== '-1') {                 
            let selectedUniversityId = +searchParams.get("universityId")!;                
            universitySEO = universities.find(x => x.Value === selectedUniversityId)!.SecondaryOption!;
        }                      
        let pageSEO = `${universitySEO} degrees for advanced level ${streamSEO} in sri lanka`;
        pageSEO = pageSEO.trim();
        return getSeoName(pageSEO);
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
            <div style={styles.accordionButtonsContainer}>
                <div style={{ ...styles.accordionButton, ...styles.accordionButton.left, ...styles.accordionButton.selected }}>
                    {localizations.find(({ ElementName }) => ElementName === 'vwGovernmentLabel')?.ElementValue!}
                </div>
                <div
                    style={{ ...styles.accordionButton, ...styles.accordionButton.right }}
                    onClick={navigateToPrivateDegrees} 
                >
                    {localizations.find(({ ElementName }) => ElementName === 'vwPrivateLabel')?.ElementValue!}
                </div>
            </div>
            <div style={{...styles.filter, ...(isFilterExpand && styles.filter.expand)}}>
                <div style={{...styles.filterExpandButton, ...(isFilterExpand && styles.filterExpandButton.expand)}} onClick={() => setIsFilterExpand((prev) => !prev)}>
                    {"Search Filters"}
                    <DropdownIcon style={{...styles.expandButtonIcon, ...(isFilterExpand && styles.expandButtonIcon.up)}}/>
                </div>
                {(isFilterLoad && isFilterExpand) && <div style={styles.filterContent}>
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwStreamLabel')?.ElementValue!}
                        options={streams}                    
                        selectedId={streamId}
                        setSelected={setSelectedStream}
                    />
                    {subjectLoading ? <InputLoader/> :
                    <SubjectSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwSubjectsLabel')?.ElementValue!}
                        streamId={streamId}
                        options={subjects}
                        selectedIds={subjectIds}
                        setSelected={setSelectedSubjects} 
                        streamSelectionError={localizations.find(({ ElementName }) => ElementName === 'vwStreamSelectionError')?.ElementValue!} 
                        subjectSelectionError={localizations.find(({ ElementName }) => ElementName === 'vwSubjectSelectionError')?.ElementValue!} 
                        lowSubjectSelectionError={localizations.find(({ ElementName }) => ElementName === 'vwLowSubjectSelectionError')?.ElementValue!} 
                        highSubjectSelectionError={localizations.find(({ ElementName }) => ElementName === 'vwHighSubjectSelectionError')?.ElementValue!}
                    />}
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwUniversityLabel')?.ElementValue!}                        
                        options={universities} 
                        selectedId={universityId}
                        setSelected={setSelectedUniversity}
                        searchEnabled={true}
                    />
                    <SingleSelectInput 
                        label={localizations.find(({ ElementName }) => ElementName === 'vwDegreePeriodLabel')?.ElementValue!}                        
                        options={degreePeriods} 
                        selectedId={degreePeriodId}
                        setSelected={setSelectedDegreePeriod}
                    /> 
                    <input 
                        type="text"
                        placeholder="Search by keywords"
                        value={searchKeyword}
                        onChange={(e) => handleKeywordChange(e)}                        
                        style={styles.searchInput}
                    />                  
                </div>}
            </div>
            {loading ? <ListLoader/> : 
            <div style={styles.container}>
                {degrees.length === 0 ? <NoData/> : 
                <>
                    <div style={styles.listCount}>{degrees.length + " Degrees"}</div>
                    {degrees.map((degree, index) =>
                        <div key={index}>
                            <Link to={`/governmentDegreeDisplay/${degree.DegreeId}/` + getSeoName(degree.DegreeName_ENG + "-" + degree.UniversityName_ENG)} style={styles.link}>
                                <DegreeCard degree={degree}/>
                            </Link>                            
                        </div>                     
                    )} 
                </>}     
            </div>}
            {openDialog && <MessageDialog styles={styles.dialog} title={"Subject Selection Error"} message={dialogMessage} handleOk={handleDialogOk} />}
        </div>
    )
}