import React, { useContext, useEffect, useState } from 'react'
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';

interface Props {
    styles?: any,
    checked: boolean,
    handleClick?(): void
}

export const Switch: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    return (
        <div style={{...styles.switch, ...(props.checked && styles.switch.checked)}} onClick={props.handleClick}>
            <span style={{ ...styles.switchCircle, ...(props.checked && styles.switchCircle.checked) }}></span>
        </div>
    )
}