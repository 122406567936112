import ContentLoader from 'react-content-loader';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { Color } from '../enums/Color';

const styles = {
    mobile: {        
        marginLeft: "5%",
        marginTop: "70px"              
    },
    desktop: {
        width: "44%",
        margin: "5% 0 0 30%"
    }
};

export const DetailLoader: React.FC = () => {            
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles = styles.mobile;
    if (!isMobile) {
        let extendStyles = merge(styles.mobile, styles.desktop);
        loaderStyles = extendStyles;
    }
    return (                         
        <div style={loaderStyles}>
            <ContentLoader
                speed={1}
                width={"100%"}
                height={"600"}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
            >
                <rect x="5%" y="0" rx="3" ry="3" width="85%" height="15" />
                <rect x="15%" y="20" rx="3" ry="3" width="60%" height="10" />                
                <rect x="0" y="60" rx="3" ry="3" width="28%" height="6" />
                <rect x="0" y="70" rx="3" ry="3" width="70%" height="12" />
                <rect x="0" y="110" rx="3" ry="3" width="28%" height="6" />
                <rect x="0" y="120" rx="3" ry="3" width="70%" height="12" />
                <rect x="0" y="160" rx="3" ry="3" width="28%" height="6" />
                <rect x="0" y="170" rx="3" ry="3" width="50%" height="12" />                
                <rect x="0" y="220" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="230" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="240" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="250" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="260" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="270" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="280" rx="3" ry="3" width="50%" height="5" />
                <rect x="0" y="310" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="320" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="330" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="340" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="350" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="360" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="370" rx="3" ry="3" width="70%" height="5" />
                <rect x="0" y="400" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="410" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="420" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="430" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="440" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="450" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="460" rx="3" ry="3" width="40%" height="5" />
                <rect x="0" y="490" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="500" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="510" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="520" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="530" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="540" rx="3" ry="3" width="95%" height="5" />
                <rect x="0" y="550" rx="3" ry="3" width="80%" height="5" />                
            </ContentLoader>
        </div>
    );
}