import { Color } from "../enums/Color"

export const mobileMenu = {
    mobile: {  
        display: "flex",
        flexDirection: "row" as "row",
        position: "fixed",                                            
        height: "100%",  
        width: "100%",              
        top: "0",
        right: "0",        
        zIndex: "2",                   
        transition: "transform 0.3s ease-out",
        leftContent: {
            height: "100%",
            width: "30%"
        },
        rightContent: {
            height: "100%",
            width: "70%",
            marginLeft: "auto",
            background: Color.DeepBlack,            
            boxShadow: "1px 0px 7px rgba(0,0,0,0.5)"
        },
        open: {
            transform: "translateX(0)"
        },
        close: {
            transform: "translateX(100%)"
        },
        closeButton: {
            backgroundColor: "transparent",
            marginTop: "8%",
            marginLeft: "5%",                         
            border: "none"
        },
        closeIcon: {
            width: "25px",
            height: "25px",
            fill: Color.LightAsh                
        },
        menu: {        
            position: "relative" as "relative",            
            display: "flex",
            flexDirection: "column" as "column", 
            paddingTop: "8%",            
            alignItems: "center" as "center",             
            label: {   
                textDecoration: "none",      
                fontSize: "16px",                
                color: Color.White                         
            },
            itemWrapper: {
                marginBottom: "20px"
            }
        },
        loginButton: {     
            position: "relative" as "relative", 
            left: "50%",
            top: "5%",         
            transform: "translate(-50%, 0%)",
            width: "84%",
            height: "40px",  
            fontWeight: "bold" as "bold",
            fontSize: "16px",                                  
            backgroundColor: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
        }          
    },
    desktop: {        
        display: "none"        
    }
}
