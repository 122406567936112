import React, { useState } from 'react';
import { Button } from '../Shared/Button';
import { Input } from '../Shared/Inputs/Input';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/AccountService';
import { LoginTexts as Texts} from '../../helpers/LayoutTexts';
import { LoginPageLoader } from '../../loaders/LoginPageLoader';

interface Props {
    styles: any;  
    callbackUrl: string | null;  
    callbackMessage: string | null;
    setError(isError: boolean): void;
    setStage(stage: string): void;
    openMessageDialog(message: string): void;
}

export const Login: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const styles = props.styles;

    async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        await login(email, password)
        .then((loginResponse: any) => {
            setLoading(false);
            if(loginResponse.userType === "1") {
                localStorage.setItem('teacher', loginResponse.teacher);                
            }
            else if(loginResponse.userType === "2") {                
                localStorage.setItem('student', loginResponse.student);                
            } 
            if(props.callbackUrl) {
                navigate(props.callbackUrl);
            } else {
                navigate('/');
            }            
        })
        .catch((error) => { 
            setLoading(false);
            props.setError(true);           
            props.openMessageDialog(Texts.loginError);
        });    
    }
    
    return (
        <form style={styles.form} onSubmit={handleLogin}>
            <h1 style={styles.title}>Login</h1>            
            {loading ? <LoginPageLoader/> : <>
                {props.callbackMessage && <div style={styles.callbackMessage}>{props.callbackMessage}</div>}
                <Input type="text" name="email" value={email} label="Email" onChange={(e) => setEmail(e.target.value)} />
                <Input type="password" name="password" value={password} label="Password" onChange={(e) => setPassword(e.target.value)} />
                <Button style={styles.submitButton} onClick={handleLogin}>Login</Button>
            </>}
            <div style={styles.rowWrapper}>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.left}} onClick={() => {props.setStage("forgotPassword")}}>Forgot Password</Button>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.right}} onClick={() => {props.setStage("register")}}>Register</Button>
            </div>            
        </form>
    )
}