import React from 'react';

export interface Props {
    styles: any;
    menuItems: any[];
}

export const DesktopMenu: React.FC<Props> = (props) => {    
    return (
        <div style={props.styles.wrapper}>
            <ul style={props.styles.list}>
                {props.menuItems.map((menuItem, index) =>
                    <li key={index} style={props.styles.listItem}>
                        { menuItem }
                    </li>                    
                )}                
            </ul>
        </div>
    );
}