export const horizontalBanner = {
    mobile: {
        wrapper: {
            display: "flex",
            flexDirection: "column" as "column",
            overflow: "hidden"            
        },
        sliderOne: {
            display: "block",
            marginBottom: "2.70vh"
        },
        sliderTwo: {
            display: "none"
        },
        banner: {
            width: "100%",
            background: "transparent",
            borderRadius: "1vw",
            margin: "auto",
            opacity: "1",
            objectFit: "contain",
            cursor: "pointer"
        }
    },
    desktop: {
        wrapper: {
            flexDirection: "row"            
        },
        sliderOne: {
            width: "49%",
            marginRight: "1%",
            marginBottom: "2.70vh"
        },
        sliderTwo: {
            width: "49%",
            marginLeft: "1%",
            display: "block"
        },
        banner: {
            background: "transparent",
            borderRadius: "0.8vw",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "2.70vh",
            opacity: "1"
        }
    }
}