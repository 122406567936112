import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';
import { Diploma } from '../../models/Diploma';

interface Props {    
    diploma: Diploma;
};

export const DiplomaCard: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("card")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const languageCode = localStorage.getItem('languageCode');  
    const diploma = props.diploma;   
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("card"));                                                                             
    }, [isMobile]);

    return (
        <div style={styles.wrapper}>                        
            <div>
                <img src={diploma.InstituteLogoUrl} style={styles.logo}></img>
            </div>
            <div>
                <div style={styles.university}>{diploma.InstituteName}</div>
                <div style={styles.degree}>{diploma.DiplomaName}</div>
                {(languageCode !== "ENG") && <div style={styles.degreeSecondary}>{diploma.DiplomaName_ENG}</div>}
            </div>
        </div>        
    );
}