import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { StyleContext } from "../../providers/StyleContextProvider";
import { Adsense } from "@ctrl/react-adsense";
import { getBanners, updateBannerClickCount } from "../../services/CommonService";
import { Banner } from "../../models/Banner";

interface Props {
    typeId: number;
    adsenseClient: string;
    adsenseSlot: string;
}

export const VerticalBanner: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const[styles, setStyles] = useState<any>(styleContext.getComponentStyle("verticalBanner"));    
    const[banners, setBanners] = useState<Banner[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const[loading, setLoading] = useState<boolean>(true);
    //const [toErrorPage] = useErrorPage();
       
    useEffect(() => {                         
        setStyles(styleContext.getComponentStyle("verticalBanner"));                       
    }, [isMobile]);

    useEffect(() => {
        getBannerList();
    }, []);
        
    const sliderSettings = {        
        infinite: true,        
        dots: false,         
        rows: 1,
        speed: 500,
        autoplay: true,      
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: false
    };

    async function getBannerList() {        
        await getBanners(props.typeId)
        .then((bannerList: Banner[]) => {
            setBanners(bannerList);
            setLoading(false);
        })    
        .catch((error) => {            
            //toErrorPage(error);
        });       
    }

    async function redirectToBannerUrl(banner: Banner) {        
        await updateBannerClickCount(banner.BannerId);
        window.open(banner.BannerLink, '_blank');        
    }

    return (
        <>
            {!loading && <div style={styles.wrapper}>                          
                {banners.length > 0 ? <Slider {...sliderSettings}>
                    {banners.map((banner, index) =>
                        <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                            <img src={banner.BannerUrl} style={styles.banner}></img>                           
                        </div>
                    )}
                </Slider>
                : <Adsense
                    client={props.adsenseClient}
                    slot={props.adsenseSlot}
                />}
            </div>}
        </>
    );
}