import { useNavigate } from "react-router-dom";

export interface Props {
    styles: any;
}

export const Title: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const toHome = () => {
        navigate('/');
    }

    return (
        <div style={props.styles} onClick={toHome}>
            TuteClass
        </div>
    );
}