import React, { useContext, useEffect, useState } from 'react';
import { Button } from './Button';
import { ReactComponent as Icon } from '../../assets/svgs/drop_down.svg';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';
import { SelectOption } from '../../models/SelectOption';
import { DropdownItem } from './DropdownItem';

interface Props {    
    styles?: any;
    label?: string;
    options: SelectOption[];
    setSelectValue(selectedValue: string | number): void;
    onLeave?(): void;
}

export const SingleSelect: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dropdown"));
    const [label, setLabel] = useState<string | undefined>(props.label);
    const [hover, setHover] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    
    useEffect(() => {           
        let style = styleContext.getComponentStyle("dropdown");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);        
    }, [isMobile]);

    const onSelect = (option: SelectOption) => {        
        setLabel(option.option);
        props.setSelectValue(option.value);
        if(isMobile) {
            setHover(false);
        }
    }

    const onLeave = () => {
        setHover(false);
        if(props.onLeave) {
            props.onLeave();
        }
    }

    return (
        <div
            onMouseEnter={()=>{ setHover(true);}}
            onMouseLeave={()=>{ onLeave();}} 
            style={styles.wrapper}>
            <Button type="button" style={styles.button} onClick={()=>{ setHover(true);}}>
                <div style={styles.labelWrapper}>
                    {label}
                    <Icon style={styles.labelIcon}/>                    
                </div>                
            </Button>             
            <div style={hover ? styles.hover : styles.leave}>                       
                {props.options.map((option, index) =>
                <DropdownItem key={index} styles={styles.item}>
                    <div onClick={() => onSelect(option)}>{option.option}</div>
                </DropdownItem>                    
                )}
            </div>             
        </div>
    );
}