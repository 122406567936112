import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Option } from "../../../models/Option";
import { StyleContext } from "../../../providers/StyleContextProvider";
import { SingleSelectDialog } from "../Dialogs/SingleSelectDialog";
import { DialogInput } from "../../Shared/Inputs/DialogInput";
import { InputValidation } from "../../../models/InputValidation";
import { SingleSelectFilterDialog } from "../Dialogs/SingleSelectFilterDialog";

interface Props {
    label: string;    
    options: Option[];
    selectedId?: number;    
    setSelected(selectedId: number): void; 
    searchEnabled?: boolean;   
    openToast?(message: string): void;
    toastMessage?: string;
    validation?: InputValidation;    
    setValidation?(validation: InputValidation): void;
}

export const SingleSelectInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [options, setOptions] = useState<Option[]>(props.options);    
    const [selectedOption, setSelectedOption] = useState<string[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
                            
    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("input"));          
    }, [isMobile]);

    useEffect(() => {        
        setInitialSelect();
    }, [props.selectedId]);
    
    const setInitialSelect = () => { 
        if(props.selectedId && props.selectedId !== -1) {       
            let selectedOption = props.options.filter(x => x.Value === props.selectedId)[0];
            props.options.filter(x => x.Value === props.selectedId)[0].Checked = true;
            setSelectedOption([selectedOption.PrimaryOption]);            
        }
        else {
            setSelectedOption([]);            
            props.options.forEach(option => {option.Checked = false});
        }     
        setOptions(props.options);   
    }

    const getSelected = (resultOptions: Option[]) => {                
        let selectedOption = resultOptions.filter(({Checked: checked}) => checked)[0]!;                                   
        setSelectedOption([selectedOption.PrimaryOption]);        
        props.setSelected(selectedOption.Value);
        setOptions(resultOptions);
        setOpenDialog(false);
        if(props.setValidation) {
            props.setValidation({});
        }        
    }  
    
    const openSelectDialog = () => {
        if(options.length > 0) {
            setOpenDialog(true);
        } 
        else if(props.openToast) {
            props.openToast(props.toastMessage!);
        }
    }

    const resetOption = () => {        
        setSelectedOption([]);
        let tempOptions = [...options];
        tempOptions.forEach(option => {option.Checked = false});
        setOptions(tempOptions);
        props.setSelected(-1);
    }

    return (
        <div style={styles}>                
            <div style={styles.inputWrapper} onClick={openSelectDialog}>
                <DialogInput values={selectedOption} validation={props.validation} onReset={resetOption}>
                    {props.label}
                </DialogInput>
            </div>     
            {openDialog && (props.searchEnabled ? <SingleSelectFilterDialog title={props.label} options={options} getSelected={getSelected} closeDialog={() => setOpenDialog(false)}/> 
            : <SingleSelectDialog title={props.label} options={options} getSelected={getSelected} closeDialog={() => setOpenDialog(false)}/>)}
        </div>
    )
}