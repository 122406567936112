import { Color } from "../enums/Color"
import { Font } from "../enums/Font"

export const subHeader = {
    mobile: {
        row: {
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center" as "center",
            paddingTop: "16px"
        },
        wrapper: {
            background: Color.DarkGreen, 
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/mobile_header.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 60%",
            backgroundPosition: "center bottom",        
            margin: "0",            
            width: "100%",
            height: "310px",        
            opacity: "1"
        }, 
        title: {            
            paddingLeft: "4%", 
            textAlign: "left" as "left",                
            fontSize: "36px",        
            fontFamily: Font.Advanced,        
            letterSpacing: "0px",
            color: Color.AquaGreen,            
            cursor: "pointer",
            opacity: "1"
        },       
        label: {             
            textDecoration: "none",
            fontSize: "20px", 
            fontWeight: "bold" as "bold",
            letterSpacing: "0px",
            color: Color.White,
            opacity: "1",
            cursor: "pointer",
            textShadow: "0px 0px 10px black"
        },
        mobileMenuButton: {
            backgroundColor: "transparent",
            border: "none",
            marginLeft: "auto",
            marginRight: "4%",
            paddingRight: "0%"            
        },
        desktopMenu: {            
            wrapper: {
                display: "none",
                marginLeft: "auto",
                marginRight: "40px"                
            },
            list: {            
                listStyleType: "none",
                display: "flex",
                flexDirection: "row" as "row"
            },
            listItem: {
                marginRight: "25px"
            }
        },
        heading: {
            position: "relative" as "relative", 
            left: "50%",         
            transform: "translate(-50%, 0%)",
            width: "80%",
            height: "40px",
            marginTop: "30px",
            marginBottom: "auto",
            textAlign: "center" as "center",            
            fontSize: "8vw",                    
            letterSpacing: "0px",
            color: Color.White,
            opacity: "1"
        },
        text: {  
            display: "none"
        }
    },
    desktop: {        
        wrapper: {               
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/desktop_header.jpg)",
            backgroundSize: "100% 160%",                                                      
            height: "300px"          
        },  
        title: {                        
            paddingLeft: "50px",                            
            fontSize: "46px"
        },    
        desktopMenu: {            
            wrapper: {
                display: "inline"
            }
        },
        mobileMenuButton: {
            display: "none"
        },
        loginButton: {
            display: "inline"
        },
        heading: {
            width: "auto",            
            height: "60px",
            marginTop: "70px",
            fontSize: "40px"
        }
    }
}