import React, { useContext, useEffect, useState } from 'react';
import { Option } from '../../models/Option';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';
import { Button } from '../Shared/Button';

interface Props {            
    title: string; 
    streamId: number;   
    options: Option[];    
    getSelected(selectedOptions: Option[]): void;    
    closeDialog(): void;    
    openAlert(status: string, message: string): void;
    subjectSelectionError: string;
    lowSubjectSelectionError: string;
    highSubjectSelectionError: string;
};

export const SubjectSelectDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [options, setOptions] = useState<Option[]>(props.options);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>(props.options);             
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });           

    useEffect(() => {               
        setStyles(styleContext.getComponentStyle("dialog"));                                                                  
    }, [isMobile]);

    const itemSelected = (value: number, checked: boolean) => {          
        const isError = checkSubjectSelectionError(value, checked);     
        if(isError) {            
            let subjectSelectionError = options.find(x => x.Value === value)!.PrimaryOption + " " + props.subjectSelectionError;
            props.openAlert("warning", subjectSelectionError);
        } 
        else {     
            let tempOptions = [...options];              
            tempOptions.find(x => x.Value === value)!.Checked = checked;   
            setOptions(tempOptions);    
            
            let tempFilteredOptions = [...filteredOptions];        
            tempFilteredOptions.find(x => x.Value === value)!.Checked = checked;   
            setFilteredOptions(tempFilteredOptions); 
        }
    }

    const filterOptions = (keyword: string) => {  
        keyword = keyword.trim();      
        let tempFilteredOptions = options.filter(option => (
            option.PrimaryOption.toLowerCase().includes(keyword.toLowerCase()) ||
            option.SecondaryOption && option.SecondaryOption.toLowerCase().includes(keyword.toLowerCase())
        ));          
        setFilteredOptions(tempFilteredOptions);
    }
    
    const checkSubjectSelectionError = (value: number, checked: boolean): boolean => {  
        let isError: boolean = false;               
        if(props.streamId === 10 && value === 53 && !checked) {            
            isError = true;
        } 
        else if (props.streamId === 12 && (value === 57 || value === 58) && !checked) {             
            isError = true;
        } 
        else if (props.streamId === 13 && (value === 58 || value === 59) && !checked) {             
            isError = true;
        }
        return isError;
    }  
    
    const handleSelectClick = () => {
        const selectedCount = options.filter(x => x.Checked).length;
        if(selectedCount < 3) {
            props.openAlert("warning", props.lowSubjectSelectionError);
        }
        else if(selectedCount > 3) {
            props.openAlert("warning", props.highSubjectSelectionError);
        }
        else {
            props.getSelected(options);
        }
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={{...styles.dialog, ...styles.filterDialog}}>
                <div style={styles.title}>{props.title}</div>
                <input type="text" style={styles.search} placeholder={"Search"} onChange={(e) => filterOptions(e.target.value)}/>
                <div style={styles.container} className={"selector"}>
                    {filteredOptions.map((option, index) =>                                     
                        <div key={index} style={styles.optionWrapper} onClick={() => itemSelected(option.Value, !option.Checked)}>                                                    
                            <input type={"checkbox"} checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                            <div style={styles.option}>
                                {option.PrimaryOption}    
                                {option.SecondaryOption && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                            </div>
                        </div>                    
                    )}    
                </div>
                <div style={styles.buttonWrapper}>
                    <Button style={styles.button} onClick={() => props.closeDialog()}>Cancel</Button>
                    <Button style={{...styles.button, ...styles.button.select}} onClick={handleSelectClick}>Select</Button>                    
                </div>
            </div> 
        </>       
    );
}