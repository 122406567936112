import { Color } from "../enums/Color";

export const noData = {
    mobile: {     
        margin: "60px",
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        color: Color.Black
    },
    desktop: {
        fontSize: "24px"
    }
}