import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ToolBar } from "../components/Shared/ToolBar";
import { Localization } from "../models/Localization";
import { StyleContext } from "../providers/StyleContextProvider";
import { getAllLanguages, getPageLocalizations } from "../services/CommonService";
import { Option } from "../models/Option";
import { DialogInput } from "../components/Shared/Inputs/DialogInput";
import { Button } from "../components/Shared/Button";
import { SingleSelectDialog } from "../components/Shared/Dialogs/SingleSelectDialog";
import { DesktopMenuDrawer } from "../components/Shared/DesktopMenuDrawer";
import { SettingsPageLoader } from "../loaders/SettingsPageLoader";

export const SettingsPage: React.FC = () => {
    const styleContext = useContext(StyleContext);
    const navigate = useNavigate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchPage"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [loading, setLoading] = useState<boolean>(true);
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [languages, setLanguages] = useState<any[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);
    const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>(localStorage.getItem('languageCode')!);            
    const [dialogOptions, setDialogOptions] = useState<Option[]>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);    

    useEffect(() => {
        window.scrollTo(0, 0);    
        getLocalizations();  
        getLanguageList();                                    
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("searchPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(6, 14) as unknown as Localization[];
        setLocalizations(localizationList);        
    }
        
    async function getLanguageList() {        
        const languageList = await getAllLanguages() as unknown as any[];
        const languageName = languageList.find(({ LanguageCode }) => LanguageCode === selectedLanguageCode)?.LanguageName;        
        setLanguages(languageList);
        setSelectedLanguage([languageName]);
        setLoading(false);                 
    }

    const openSelectDialog = () => {
        let options = new Array<Option>();            
        languages.forEach((language) => {            
            let option: Option = {
                Value: language.LanguageCode,
                PrimaryOption: language.LanguageName,
                SecondaryOption: "",
                Checked: language.LanguageCode === selectedLanguageCode
            }           
            options.push(option);            
        });                                                             
        setDialogOptions(options);                           
        setOpenDialog(true);        
    }

    const closeSelectDialog = () => {
        setOpenDialog(false);
    }

    const getSelected = (resultOptions: Option[]) => {                
        let selectedOptions = resultOptions.filter(({Checked: checked}) => checked)!;        
        if(selectedOptions.length !== 0) {            
            setSelectedLanguage([selectedOptions[0].PrimaryOption]);
            setSelectedLanguageCode(selectedOptions[0].Value);               
        }                
        setOpenDialog(false);        
    }

    const submitResponse = () => {    
        localStorage.setItem('languageCode', selectedLanguageCode);
        localStorage.setItem('language', languages.find(x => x.LanguageCode === selectedLanguageCode)?.LanguageName);
        navigate(-1);
    }

    return (
        <div style={styles}>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar> 
            <DesktopMenuDrawer />
            {loading ? <SettingsPageLoader/> : 
            <div style={styles.container}>
                <div style={styles.content}>
                    <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>           
                    <div style={styles.subContainer}> 
                        <>
                            <div style={styles.inputWrapper} onClick={() => openSelectDialog()}>
                                <DialogInput values={selectedLanguage}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwLanguageLabel')?.ElementValue}
                                </DialogInput>
                            </div>
                            <Button style={styles.searchButton} onClick={() => submitResponse()}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwBtnSubmit')?.ElementValue}
                            </Button>
                        </>
                        <>
                            <div style={styles.videoLabel}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwVideoLabel_1')?.ElementValue}
                            </div>
                            <iframe style={styles.video} src={localizations.find(({ ElementName }) => ElementName === 'vwVideoUrl_1')?.ElementValue} frame-border="0" data-allow="autoplay; encrypted-media" allowFullScreen></iframe>
                        </>
                    </div>
                </div>                
            </div>}
            {openDialog && <SingleSelectDialog title={localizations.find(({ ElementName }) => ElementName === 'vwLanguageLabel')?.ElementValue!} options={dialogOptions!} getSelected={getSelected} closeDialog={closeSelectDialog} />}            
        </div>
    )
}