import { useEffect, useState } from "react";
import Slider from 'react-slick';
import { getDiplomasForSlider } from "../../services/GovernmentDiplomaService";
import { DiplomaView } from "../../models/DiplomaView";
import { Localization } from "../../models/Localization";
import { getPageLocalizations } from "../../services/CommonService";
import { ContentSliderLoader } from "../../loaders/ContentSliderLoader";

interface Props {
    styles: any;
    title: string;
    content: string;
}

export const DiplomaSlider: React.FC<Props> = (props) => {         
    const styles = props.styles;    
    const [diplomas, setDiplomas] = useState<DiplomaView[]>([]);
    const [localizations, setLocalizations] = useState<Localization[]>([]);    
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {   
        getLocalizations();  
        getDiplomas();              
    }, []);
    
    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        dots: false,         
        rows: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        useTransform: false
    };

    async function getLocalizations() {        
        const localizationList = await getPageLocalizations(8, 29) as unknown as Localization[];
        setLocalizations(localizationList);                        
    }

    async function getDiplomas() {        
        await getDiplomasForSlider()
        .then((diplomaList: DiplomaView[]) => {
            setDiplomas(diplomaList);                
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });        
    }
    
    return (
        <>
            {loading ? <ContentSliderLoader styles={styles}/> 
            : <div style={styles}>
                <div style={styles.leftWrapper}>
                    <Slider {...sliderSettings}>
                        {diplomas.map((diploma, index) =>
                            <div key={index}>
                                <div style={styles.slider} className="scrollArea">
                                    <div style={styles.card}>
                                        <div style={styles.card.titleWrapper}>
                                            <div>
                                                <img src={diploma?.GovernmentInstituteLogoUrl} style={styles.card.logo}></img>
                                            </div>
                                            <div>                                
                                                <div style={styles.card.title}>{diploma?.GovernmentDiplomaName}</div>
                                                <div style={styles.card.titleSecondary}>{diploma?.GovernmentDiplomaName_ENG}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={styles.card.label}>
                                                {localizations.find(({ ElementName }) => ElementName === 'vwInstituteLabel')?.ElementValue}
                                            </div>
                                            <div style={styles.card.content}>{diploma?.GovernmentInstituteName}</div>
                                        </div>
                                        <div>
                                            <div style={styles.card.label}>
                                                {localizations.find(({ ElementName }) => ElementName === 'vwDiplomaPeriodLabel')?.ElementValue}
                                            </div>
                                            <div style={styles.card.content}>{diploma?.GovernmentDiplomaPeriodName}</div>
                                        </div>
                                        {diploma?.RequiredQualifications && <div>
                                            <div style={styles.card.label}>
                                                {localizations.find(({ ElementName }) => ElementName === 'vwRequiredQualificationLabel')?.ElementValue}
                                            </div>
                                            <div style={styles.card.content} dangerouslySetInnerHTML={{ __html: diploma?.RequiredQualifications}}></div>
                                        </div>}
                                        {diploma?.GovernmentDiplomaDetail && <div>
                                            <div style={styles.card.label}>
                                                {localizations.find(({ ElementName }) => ElementName === 'vwDiplomaDetailLabel')?.ElementValue}
                                            </div>
                                            <div style={styles.card.content} dangerouslySetInnerHTML={{ __html: diploma?.GovernmentDiplomaDetail}}></div>
                                        </div>}                                        
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
                <div style={styles.rightWrapper}>
                    <div style={styles.title}>
                        {props.title}
                    </div>
                    <div style={styles.content}>
                        {props.content}
                    </div>
                </div>                       
            </div>}
        </>
    )
}