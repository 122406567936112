import { Color } from "../enums/Color";

export const adViewPage = {
    mobile: {        
        container: {
            marginTop: "50px"
        },
        image: {
            width: "100vw",
            height: "100vw"
        },
        detailContainer: {
            margin: "16px 12px"
        },
        label: {
            margin: "12px 0 4px 0",
            fontSize: "12px",
            fontWeight: "bold"
        },
        name: {
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            flexWrap: "wrap-reverse",
            fontSize: "18px",
            cursor: "pointer",
            icon: {
                height: "18px",
                width: "18px",
                marginLeft: "8px",
                fill: Color.Green
            }
        },
        tepNo: {
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            flexWrap: "wrap-reverse",
            fontSize: "16px",
            marginTop: "8px",
            cursor: "pointer",
            icon: {
                height: "18px",
                width: "18px",
                marginLeft: "8px",
                fill: Color.Green
            }            
        },
        tagWrapper: {
            display: "block"
        },
        tag: {
            width: "max-content",
            height: "8.45vw",
            paddingLeft: "4.83vw",
            paddingRight: "4.83vw",            
            marginRight: "1.20vw",
            marginBottom: "1.20vw",
            textAlign: "center" as "center",
            lineHeight: "8.45vw",            
            borderRadius: "6.03vw",
            display: "inline-block",
            backgroundColor: Color.Ash,        
            fontSize: "3.62vw",
            color: Color.DeepAsh,
            overflow:"hidden",
            cursor: "pointer",
            flex: "0 0 auto"
        },
        subjects: {},
        grades: {},
        mediums: {},
        classTypes: {},
        districts: {},
        towns: {}
    },
    desktop: {
        container: {
            margin: "58px 0 20px 0",
            paddingLeft: "32%",
            paddingRight: "32%"
        },
        image: {
            width: "-webkit-fill-available",
            height: "auto",
            borderRadius: "0.41vw"
        },
        label: {            
            fontSize: "14px"
        },
        tag: {
            width: "fit-content",
            height: "1.82vw",
            marginBottom: "0.26vw",
            marginRight: "0.26vw",            
            lineHeight: "1.82vw",
            borderRadius: "1.30vw",                   
            fontSize: "0.78vw",
            paddingLeft: "1.04vw",
            paddingRight: "1.04vw"
        }
    }
}