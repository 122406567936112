import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StyleContext } from "../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { Button } from "../components/Shared/Button";
import { Footer } from "../components/Shared/Footer";
import { ReactComponent as SuccessfullyApplied } from "../assets/svgs/successfully-applied.svg"
import { SEOKeywords } from "../helpers/LayoutTexts";

export const PaymentResponsePage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const paymentId = searchParams.get("paymentId");
    const paymentTypeId = searchParams.get("paymentTypeId");
    const success = searchParams.get("success") === "True";
    const message = searchParams.get("message");
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("paymentResponsePage"));    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("paymentResponsePage"));
    }, [isMobile]);

    return (
        <>
            <Header styleSheet={"subHeader"} searchEnable={false} searchSelectEnable={false}/>            
            <div style={styles.container}>    
                <SuccessfullyApplied style={styles.image}/>            
                <h3>{success ? "Payment Successful" : "Invalid Payment Request"}</h3>
                <p>{message}</p>                
                <Button style={styles.button} onClick={() => navigate("/teacherAds/myAds" + SEOKeywords.teacher)}>{"GO TO MY ADS"}</Button>                
            </div>            
            <Footer/>
        </> 
    )
}