import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const tabs = {
    mobile: {
        container: {
            marginTop: "80px"
        },
        linkWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            width: "fit-content" as "fit-content",
            overflow: "scroll",
            height: "40px",            
            backgroundColor: Color.LightAsh,
            borderRadius: "5px",
            margin: "80px auto 0 auto"
        },
        link: {
            height: "40px",
            lineHeight: "40px",
            flexShrink: "0",
            fontFamily: Font.Default,
            fontsize: "16px",
            color: Color.DeepAsh,
            borderRadius: "5px",
            padding: "0 10px",         
            alignSelf: "center" as "center",
            cursor: "pointer",
            active: {
                backgroundColor: Color.MaastrichtBlue,
                color: Color.LightAsh
            }
        },
        contentWrapper: {
            //marginTop: "-70px"
        }
    },
    desktop: {
        container: {
            marginTop: "50px"
        },
        linkWrapper: {            
            height: "45px",
            borderRadius: "10px",
            margin: "-25px auto 0 auto"
        },
        link: {
            height: "45px",
            lineHeight: "45px",
            fontsize: "18px",
            borderRadius: "10px",
            padding: "0 20px"
        },
        title: {
            fontSize: "30px",
            marginTop: "50px"
        },
        contentWrapper: {
            marginTop: "-20px"
        }
    }
}