import Axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { DiplomaResponseModel } from "../models/DiplomaResponseModel";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

export async function getRequestModel() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/governmentDiploma/requestModel", {              
            params: {
                languageCode: localStorage.getItem('languageCode')!
            },          
            cache: {                
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDiploma(diplomaId: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/governmentDiploma/selectedDiploma", {           
            params: {
                diplomaId: diplomaId,
                languageCode: localStorage.getItem('languageCode')!
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDiplomas(responseModel: DiplomaResponseModel) {
    responseModel.languageCode = localStorage.getItem('languageCode')!;
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "common/governmentDiploma/responseModel", responseModel, {                                
            cache: {    
                methods: ["post"],            
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDiplomasForSlider() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/governmentDiploma/forSlider", {
            params: {
                languageCode: localStorage.getItem('languageCode')!
            },            
            cache: {                
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}