import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Title } from './Title';
import { Button } from './Button';
import { SearchArea } from './SearchArea';
import { Link, useLocation } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { SEOKeywords, HeaderTexts as Texts } from '../../helpers/LayoutTexts';
import { ReactComponent as MobileMenu } from '../../assets/svgs/mobile_menu.svg';
import { MobileMenuDrawer } from './MobileMenuDrawer';
import { SelectOption } from '../../models/SelectOption';
import { DesktopMenu } from './DesktopMenu';
import { ProfileEntrance } from './ProfileEntrance';
import { SingleSelect } from './SingleSelect';

interface Props {
    styleSheet: string;
    heading?: string;
    searchEnable: boolean;
    searchKeyword?: string;       
    setKeyword?(keyword: string): void; 
    searchSelectEnable: boolean;
    searchSelectLabel?: string;
    searchSelectOptions?: SelectOption[];
    setSelectValue?(selectValue: string | number): void;
    handleSearch?(): void;    
}

export const Header: React.FC<Props> = (props) => {  
    const location = useLocation();   
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle(props.styleSheet));                     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [mobileMenuToggle, setMobileMenuToggle] = useState<boolean>(false); 
    const [languageLabel] = useState<string>(localStorage.getItem('language') !== null ? localStorage.getItem('language')! : "English");

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle(props.styleSheet));                    
    }, [isMobile]);
    
    const menuItems: any[] = [        
        <Link to={'/'} style={styles.label}>{"Home"}</Link>,
        <Link to={'/degree/government' + SEOKeywords.governmentDegree} style={styles.label}>{"Degrees"}</Link>,  
        <Link to={'/diploma/government' + SEOKeywords.governmentDiploma} style={styles.label}>{"Diplomas"}</Link>,
        <Link to={'/teachers' + SEOKeywords.teacher} style={styles.label}>{"Teachers"}</Link>,
        <Link to={'/settings'} style={styles.label}>{"Settings"}</Link>,
        <Link to={'/chat'} style={styles.label}>{"Ask Anything"}</Link>
    ]    

    const languages: SelectOption[] = [
        { value: "SIN", option: "සිංහල", checked: false},
        { value: "TAM", option: "தமிழ்", checked: false},
        { value: "ENG", option: "English", checked: false}                    
    ]

    const setLanguage = (languageCode: string) => {    
        localStorage.setItem('languageCode', languageCode);
        localStorage.setItem('language', languages.find(x => x.value === languageCode)?.option);
        window.location.href = location.pathname;       
    }

    const handleMobileMenuToggle = () => {        
        setMobileMenuToggle((prev) => !prev);
    }

    return (
        <div style={styles.wrapper}>
            {/* This is loading only mobile platform */}
            <MobileMenuDrawer open={mobileMenuToggle} toggle={handleMobileMenuToggle}/>            
            <div style={styles.row}>                
                <Title styles={styles.title}/>  
                {/* This is loading only desktop platform */}
                <DesktopMenu styles={styles.desktopMenu} menuItems={menuItems}/> 
                <SingleSelect styles={styles.languageSelect} label={languageLabel} options={languages} setSelectValue={setLanguage} />
                {!isMobile && <ProfileEntrance/>}                
                <Button type="button" style={styles.mobileMenuButton} onClick={handleMobileMenuToggle}><MobileMenu/></Button>
            </div>                
            <h1 style={styles.heading}>{props.heading ? props.heading : Texts.heading}</h1> 
            <p style={styles.text}>{isMobile ? Texts.mobileText : Texts.desktopText}</p>
            {props.searchEnable && <SearchArea 
                placeholder={isMobile ? Texts.mobileSearch : Texts.desktopSearch} 
                keyword={props.searchKeyword} 
                setKeyword={props.setKeyword} 
                selectEnable={props.searchSelectEnable} 
                selectLabel={props.searchSelectLabel} 
                selectOptions={props.searchSelectOptions} 
                setSelectValue={props.setSelectValue} 
                handleSearch={props.handleSearch} 
                buttonText={"SEARCH"}
            />}            
        </div>
    );
}