import React, { useContext, useEffect, useState, useRef } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { InputValidation } from '../../../models/InputValidation';

interface Props {        
    id?: string;
    name?: string;
    value: any;
    handleChange(text: any): void;
    handleBlur?(event?: React.FocusEvent<HTMLSpanElement>): void;
    disabled?: boolean;
    validation?: InputValidation;
    styles?: any;
}

export const ExtendableTextAreaInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [focused, setFocused] = useState<boolean>(false);
    const validation = props.validation;
    const spanRef = useRef<HTMLSpanElement | null>(null); 

    useEffect(() => {
        if (spanRef.current && props.value === "") { 
            spanRef.current.innerText = "";
        }
    }, [props.value]);

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    const handleOnChange = () => {
        if (spanRef.current) {            
            const textValue = spanRef.current.innerText;                    
            props.handleChange(textValue);  
        }
    };
    
    const handleOnBlur = (event?: React.FocusEvent<HTMLSpanElement>) => {
        setFocused(false);
        props.handleBlur && props.handleBlur(event);
    }

    return (
        <>            
            <span
                style={{...styles.extendArea,  ...(focused && styles.active), ...(validation?.valid && styles.valid), ...(validation?.invalid && styles.invalid)}}
                id={props.id}
                role="textbox"
                contentEditable
                ref={spanRef}
                onBlur={handleOnBlur}
                onInput={handleOnChange}
                onClick={() => setFocused(true)}
            ></span>            
        </>
    )
}