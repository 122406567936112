import { Color } from "../enums/Color";

export const displayPage = {
    mobile: {        
        desktopTitle: {
            display: "none"
        },                    
        container: {                                        
            marginTop: "65px",
            paddingLeft: "5%",
            paddingRight: "5%"
        },
        title: {
            textAlign: "center",                                                        
            fontWeight: "bold",
            fontSize: "18px"
        },
        titleSecondary: {
            textAlign: "center",                                                                   
            fontSize: "16px"
        },
        subTitleWrapper: {
            display: "flex",
            flexDirection: "row" as "row"
        },
        subTitle: {                                                                    
            fontWeight: "bold",
            fontSize: "16px",
            marginTop: "15px"
        },
        subTitleSecondary: {                                                                               
            fontSize: "14px"
        },
        description: {

        },
        label: {
            marginTop: "20px",
            textAlign: "left",                                                        
            fontWeight: "bold",
            fontSize: "14px",
            color: Color.DeepBlack
        },
        value: {},
        details: {
            marginTop: "20px"
        },
        linkWrapper: {
            marginTop: "20px",
            paddingBottom: "20px"
        },
        link: {
            color: Color.Green            
        },
        subContainer: {
            marginLeft: "5%"
        },
        dropdownIcon: {
            width: "12px",
            margin: "24px 0 0 auto",
            transition: "transform .1s ease-in-out",
            hover: {
                transform: "rotate(180deg)"
            }
        },
        warning: {
            fontSize: "13px",
            color: Color.White,        
            background: Color.DeepBlack,
            padding: "16px 5% 16px 5%",
            margin: "36px -5% 0 -5%",
            textAlign: "center",
            cursor: "pointer"
        }
    },
    desktop: {
        desktopTitle: {
            display: "block",
            textAlign: "center" as "center",
            color: Color.Green,
            marginTop: "50px"
        },        
        container: {
            marginTop: "30px",
            paddingLeft: "32%",
            paddingRight: "32%"
        },
        description: {
            marginBottom: "42px"
        },
        warning: {
            fontSize: "15px",
            margin: "62px -5% 0 -5%"
        }
    }
}