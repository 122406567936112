export const verticalBanner = {
    mobile: {
        wrapper: {            
            borderRadius: "20px",            
            overflow: "hidden"
        },
        banner: {
            width: "100%"
        }
    },
    desktop: {                               
        wrapper: {
            display: "block"
        }        
    }
}