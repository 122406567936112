import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../../providers/StyleContextProvider";
import { DialogInput } from "./DialogInput";
import { MenuOption } from "../../../models/MenuOption";
import { SingleSelectMenuDialog } from "../Dialogs/SingleSelectMenuDialog";
import { InputValidation } from "../../../models/InputValidation";
import { SingleSelectMenuFilterDialog } from "../Dialogs/SingleSelectMenuFilterDialog";

interface Props {
    label: string;    
    menuOptions: MenuOption[];
    selectedOptionId?: number;
    setSelectedOption(labelId: number, optionId: number): void;
    searchEnabled?: boolean;
    validation?: InputValidation;
    setValidation?(validation: InputValidation): void;
}

export const SingleSelectMenuInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [labelId, setLabelId] = useState<number>(-1);
    const [optionId, setOptionId] = useState<number>(props.selectedOptionId ? props.selectedOptionId : -1);
    const [menuOptions, setMenuOptions] = useState<MenuOption[]>(props.menuOptions);
    const [selectedOption, setSelectedOption] = useState<string[]>([]);
    
    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("input"));          
    }, [isMobile]);

    useEffect(() => {        
        setInitialSelect();
    }, [props.selectedOptionId]);
    
    const getMenuSelected = (resultMenuOptions: MenuOption[], selectedLabelId: number, selectedOptionId: number) => {        
        let selectedOption = menuOptions.find(menuOption => menuOption.LabelId === selectedLabelId)!.Options.find(option => option.Value === selectedOptionId);
        setSelectedOption([selectedOption!.PrimaryOption]);        
        props.setSelectedOption(selectedLabelId, selectedOption!.Value);         
        setLabelId(selectedLabelId);
        setOptionId(selectedOptionId);
        setMenuOptions(resultMenuOptions);        
        setOpenDialog(false);
        if(props.setValidation) {
            props.setValidation({});
        }
    }

    const setInitialSelect = () => {
        if(props.selectedOptionId && props.selectedOptionId !== -1) {
            props.menuOptions.forEach((menuOption, menuIndex) => {
                menuOption.Options.forEach((option, optionIndex) => {
                    if(option.Value === props.selectedOptionId) {
                        props.menuOptions[menuIndex].Expand = true;
                        props.menuOptions[menuIndex].Options[optionIndex].Checked = true;
                        setLabelId(props.menuOptions[menuIndex].LabelId);                    
                        setSelectedOption([option.PrimaryOption]);
                        return;
                    }
                });
            });
        }
        else {
            setLabelId(-1);
            setOptionId(-1);
            setSelectedOption([]);    
            props.menuOptions.forEach(menuOption => menuOption.Options.forEach(option => {option.Checked = false}));
        }
        setMenuOptions(props.menuOptions);
    }

    const resetOption = () => {
        setLabelId(-1);
        setOptionId(-1);
        setSelectedOption([]);
        let tempMenuOptions = [...menuOptions];
        tempMenuOptions.find(menuOption => menuOption.LabelId === labelId)!.Options.find(option => option.Value === optionId)!.Checked = false;
        setMenuOptions(tempMenuOptions);
        props.setSelectedOption(-1, -1);
    }

    return (
        <div style={styles}>                
            <div style={styles.inputWrapper} onClick={() => setOpenDialog(true)}>
                <DialogInput values={selectedOption} validation={props.validation} onReset={resetOption}>
                    {props.label}
                </DialogInput>
            </div>                          
            {openDialog && (props.searchEnabled ? <SingleSelectMenuFilterDialog title={props.label} labelId={labelId} optionId={optionId} menuOptions={menuOptions} getMenuSelected={getMenuSelected} closeDialog={() => setOpenDialog(false)}/> :
            <SingleSelectMenuDialog title={props.label} labelId={labelId} optionId={optionId} menuOptions={menuOptions} getMenuSelected={getMenuSelected} closeDialog={() => setOpenDialog(false)}/>)}            
        </div>
    )
}