import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Icon } from '../../assets/svgs/search.svg';
import { SelectOption } from '../../models/SelectOption';
import { StyleContext } from '../../providers/StyleContextProvider';
import { Button } from './Button';
import { SingleSelect } from './SingleSelect';

interface Props {      
    placeholder?: string;    
    keyword?: string;
    setKeyword?(keyword: string): void; 
    selectEnable?: boolean;
    selectLabel?: string;
    selectOptions?: SelectOption[]; 
    setSelectValue?(selectValue: string | number): void;         
    buttonText?: string;         
    handleSearch?(): void;
};

export const SearchArea: React.FC<Props> = (props) => {
        
    const styleContext = useContext(StyleContext);           
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchArea"));                     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    
    useEffect(() => {
        setStyles(styleContext.getComponentStyle("searchArea"));                              
    }, [isMobile]);
    
    return (
        <form style={styles}>            
            <input style={styles.input} placeholder={props.placeholder} value={props.keyword} onChange={event => props.setKeyword!(event.currentTarget.value)}/>
            {props.selectEnable && <SingleSelect label={props.selectLabel} options={props.selectOptions!} setSelectValue={props.setSelectValue!} />}
            <Button type="submit" style={styles.button} onClick={props.handleSearch!}>
                <div style={styles.button.buttonWrapper}>
                    <div style={styles.button.buttonText}>{props.buttonText}</div>
                    <Icon style={styles.button.buttonIcon}/>
                </div>                
            </Button>
        </form>
    );
}