import { Color } from "../enums/Color";

export const toolBar = {
    mobile: {                       
        wrapper: {
            position: "fixed",
            height: "50px",
            width: "-webkit-fill-available",
            top: 0,
            textAlign: "center",            
            fontSize: "20px",
            lineHeight: "50px",            
            color: Color.White,                    
            letterSpacing: "0px",
            backgroundColor: Color.Green,
            zIndex: 1
        },
        backButton: {            
            backgroundColor: "transparent",
            border: "none",
            float: "left",
            lineHeight: "60px",
            marginLeft: "8px",
            marginRight: "-40px"
        },
        backIcon: {
            height: "20px",
            fill: Color.White
        },
        mobileMenuButton: {
            backgroundColor: "transparent",
            border: "none",
            float: "right",
            marginTop: "16px",
            marginRight: "4%"
        }
    },
    desktop: {
        wrapper: {
            display: "none"
        }
    }
}