import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ToolBar } from "../../components/Shared/ToolBar";
import { StyleContext } from "../../providers/StyleContextProvider";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Teacher } from "../../models/Teacher";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { SearchContentLoader } from "../../loaders/SearchContentLoader";
import { Input } from "../../components/Shared/Inputs/Input";
import { Button } from "../../components/Shared/Button";
import { Localization } from "../../models/Localization";
import { getPageLocalizations } from "../../services/CommonService";
import { useAlert } from "../../hooks/useAlert";
import { QuillEditor } from "../../components/Shared/QuillEditor";
import { OnlineClassWeek, OnlineClassWeekValidation } from "../../models/OnlineClassWeek";
import { DocumentViewer } from "../../components/Shared/DocumentViewer";
import Dropzone from "react-dropzone";
import { ReactComponent as FileUpload } from "../../assets/svgs/file-upload.svg";
import { ReactComponent as CloseCircle } from "../../assets/svgs/close-circle-ash.svg";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { getOnlineClassWeek, saveClassWeek } from "../../services/ClassService";

const intialFormInputs: OnlineClassWeek = {
    OnlineClassWeekId: 0,
    OnlineClassId: 0
}

const initialValidation: OnlineClassWeekValidation = {
    Title: {},
    Date: {},
    Time: {},  
    DurationInHours: {},
    DurationInMins: {},         
    ClassFee: {}
}

export const WeekManagePage: React.FC = () => {
    let params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const classId: number = +params.classId!;
    const weekId: number = +params.weekId!;        
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("classManagePage"));
    const authContext = useContext(AuthContext);
    const teacher: Teacher | null = authContext.getLoggedTeacher();
    const [tuteFile, setTuteFile] = useState<string | File>();
    const [classWeek, setClassWeek] = useState<OnlineClassWeek>(intialFormInputs);
    const [validation, setValidation] = useState<OnlineClassWeekValidation>(initialValidation);
    const [loading, setLoading] = useState<boolean>(false);       
    const [editLoading, setEditLoading] = useState<boolean>(false); 
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const {Alert, openAlert} = useAlert();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const bannerType = isMobile ? 7 : 8;     
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];        
    
    useEffect(() => {    
        window.scrollTo(0, 0);
        if(teacher !== null) {              
            getLocalizations(); 
            if(weekId === 0) {
                const newFormInputs: OnlineClassWeek = {
                    OnlineClassWeekId: 0,
                    OnlineClassId: classId
                }
                setClassWeek(newFormInputs);
            }      
            else {
                getOnlineClassWeekData();
            }     
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login to create a new class week." };
            navigate({
                pathname: '/account/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("classManagePage"));          
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(11, 37)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getOnlineClassWeekData() {  
        setEditLoading(true);              
        await getOnlineClassWeek(weekId)
        .then((weekData: OnlineClassWeek) => {            
            const editFormInputs: OnlineClassWeek = {
                OnlineClassWeekId: weekData.OnlineClassWeekId,
                OnlineClassId: weekData.OnlineClassId,
                Title: weekData.Title,
                Date: weekData.Date!.split('T')[0],
                Time: weekData.Time,
                DurationInHours: weekData.DurationInHours,
                DurationInMins: weekData.DurationInMins,
                Description: weekData.Description,
                ClassFee: weekData.ClassFee,
                ZoomLink: weekData.ZoomLink,
                TuteLink: weekData.TuteLink
            };
            setClassWeek(editFormInputs);
            setEditLoading(false);                  
        })
        .catch((error) => {
            // toErrorPage(error);
        });                        
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value; 
        setClassWeek((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name]!.invalid) {
            validateInput(name, value);
        }       
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }
    
    const handleFileChange = (uploadedFiles: File[]) => {   
        if(uploadedFiles.length > 0) {
            let tempUploaded = uploadedFiles[0];            
            setTuteFile(tempUploaded);                                             
        }
    }

    const handleDescriptionChange = (description: string) => {        
        setClassWeek((values: any) => ({ ...values, ["Description"]: description }));              
    }

    const validateInput = (name: string, value: string | number) => {                
        if(name === "Title" || name === "Date" || name === "Time") {  
            if(value === "") {          
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: name + " is required"}}));   
            }  
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }       
        }        
        if(name === "DurationInHours") {            
            if(+value < 0 || +value > 24) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 24"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }    
        if(name === "DurationInMins") {            
            if(+value < 0 || +value > 60) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 60"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }     
        if(name === "ClassFee") {
            if(value === "") {          
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Class fee is required"}}));   
            }         
            else if(+value < 0) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "The value should be greater than 0"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }        
    } 
    
    const removeSelectedFile = () => {
        setTuteFile(undefined);
    }

    const validateForm = (): number => {
        let formValidation: OnlineClassWeekValidation = {
            Title: {valid: true},
            Date: {valid: true},
            Time: {valid: true},  
            DurationInHours: {valid: true},
            DurationInMins: {valid: true},         
            ClassFee: {valid: true}
        }        
        if(classWeek.Title === "") {
            formValidation.Title = {valid: false, invalid: true, invalidMessage: "Title is required"};            
        }        
        if(classWeek.Date === "") {
            formValidation.Date = {valid: false, invalid: true, invalidMessage: "Date is required"};            
        } 
        if(classWeek.Time === "") {
            formValidation.Time = {valid: false, invalid: true, invalidMessage: "Time is required"};            
        }
        if(classWeek.DurationInHours) {                                
            if(classWeek.DurationInHours < 0 || classWeek.DurationInHours > 24) {
                formValidation.DurationInHours = {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 24"};
            } 
        }
        if(classWeek.DurationInMins) {                                
            if(classWeek.DurationInMins < 0 || classWeek.DurationInMins > 60) {
                formValidation.DurationInHours = {valid: false, invalid: true, invalidMessage: "The value should be between 0 and 60"};
            } 
        }
        if(!classWeek.ClassFee) {          
            formValidation.ClassFee = {valid: false, invalid: true, invalidMessage: "Class fee is required"};   
        }         
        else if(classWeek.ClassFee < 0) {
            formValidation.ClassFee = {valid: false, invalid: true, invalidMessage: "The value should be greater than 0"};
        }
        setValidation(formValidation);        
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key]!.valid).length;
        return validInputCount;
    }

    async function submitResponse(event: React.FormEvent<HTMLFormElement>) {         
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key]!.valid).length;
        if(validInputCount < 6){
            validInputCount = validateForm();
        }
        if(validInputCount === 6) {            
            const classWeekString = JSON.stringify(classWeek);
            const formData = new FormData();
            formData.append('response', encodeURIComponent(classWeekString));  
            formData.append('tuteFile', tuteFile!);
            await saveClassWeek(formData)
            .then((success: boolean) => {  
                if(success) {
                    openAlert("success", "Online class has been submitted successfully.");  
                    navigate(-1);                                                       
                }
                else {
                    openAlert("error", "Oops! something went wrong.");
                }
            })
            .catch((error) => {            
                //toErrorPage(error);
            });  
        }             
    }  

    return (
        <>            
            <ToolBar>{localizations.find(({ ElementName }) => ElementName === 'vwTitle')?.ElementValue!}</ToolBar>
            <DesktopMenuDrawer/>             
            <div style={styles.container}>
                {(loading || editLoading) ? <SearchContentLoader/> : <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>
                        {localizations.find(({ ElementName }) => ElementName === 'vwTitle')?.ElementValue!}
                    </h1>
                    <form style={styles.week.formWrapper} onSubmit={submitResponse}>  
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwTitleLabel')?.ElementValue!}
                            </label>
                            <Input
                                type={"text"}
                                name={"Title"}
                                value={classWeek.Title}
                                styles={styles.week.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}                                               
                                validation={validation.Title}    
                            />
                        </div>              
                        <div style={{...styles.week.inputWrapper, ...styles.week.rowWrapper}}>
                            <div style={styles.week.halfWrapper}>
                                <label style={styles.week.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwDateLabel')?.ElementValue!}
                                </label>
                                <Input 
                                    type={"date"}
                                    name={"Date"}
                                    value={classWeek.Date}
                                    min={formattedToday}
                                    styles={styles.week.input}
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur}   
                                    validation={validation.Date}                       
                                />
                            </div> 
                            <div style={styles.week.halfWrapper}>
                                <label style={styles.week.label}>
                                    {localizations.find(({ ElementName }) => ElementName === 'vwTimeLabel')?.ElementValue!}
                                </label>                        
                                <Input
                                    type={"time"}
                                    name={"Time"}
                                    value={classWeek.Time}
                                    styles={styles.week.input}
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur}                                                           
                                    validation={validation.Time} 
                                />                        
                            </div>                   
                        </div>
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwDurationLabel')?.ElementValue!}
                            </label>
                            <div style={styles.week.rowWrapper}>
                                <div style={styles.week.halfWrapper}>
                                    <Input 
                                        type={"number"}
                                        name={"DurationInHours"}
                                        value={classWeek.DurationInHours}
                                        min={0}
                                        max={24}
                                        placeholder={localizations.find(({ ElementName }) => ElementName === 'vwHourLabel')?.ElementValue!}
                                        styles={styles.week.input}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}   
                                        validation={validation.DurationInHours}                       
                                    />                            
                                </div>
                                <div style={styles.week.halfWrapper}>
                                    <Input
                                        type={"number"}
                                        name={"DurationInMins"}
                                        value={classWeek.DurationInMins}
                                        min={0}
                                        max={60}
                                        placeholder={localizations.find(({ ElementName }) => ElementName === 'vwMinLabel')?.ElementValue!}
                                        styles={styles.week.input}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}                                                           
                                        validation={validation.DurationInMins} 
                                    />
                                </div>
                            </div>                   
                        </div>
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwZoomLabel')?.ElementValue!}
                            </label>
                            <Input
                                type={"url"}
                                name={"ZoomLink"}
                                value={classWeek.ZoomLink}
                                styles={styles.week.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}  
                            />
                        </div> 
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwFeeLabel')?.ElementValue!}
                            </label>
                            <Input
                                type={"number"}
                                name={"ClassFee"}
                                value={classWeek.ClassFee}
                                min={0}
                                placeholder="LKR"
                                styles={styles.week.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}                                               
                                validation={validation.ClassFee}    
                            />
                        </div> 
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwTuteLabel')?.ElementValue!}
                            </label>
                            <div style={styles.week.rowWrapper}>
                                {(tuteFile || classWeek.TuteLink) && <>                            
                                    <DocumentViewer styles={styles.week.documentViewer} document={tuteFile ? tuteFile : classWeek.TuteLink}/>
                                    <CloseCircle style={styles.week.fileClose} onClick={removeSelectedFile} />
                                </>}
                                <div style={{...styles.week.fullWrapper, ...(tuteFile && styles.week.halfWrapper)}}>
                                    <Dropzone onDrop={acceptedFiles => handleFileChange(acceptedFiles)} multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ style: styles.week.uploadFile })} className="center-content">
                                                <input {...getInputProps()} /> 
                                                <div style={styles.week.uploadIconWrapper}>
                                                    <FileUpload style={styles.week.uploadIcon} />
                                                </div>
                                                <div style={styles.week.uploadText}>
                                                    {"Add a tute file if available."}                                  
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                        <div style={styles.week.inputWrapper}>
                            <label style={styles.week.label}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwDescriptionLabel')?.ElementValue!}
                            </label>
                            <QuillEditor 
                                value={classWeek.Description}
                                onChange={handleDescriptionChange}
                                styles={styles.week.editor}
                            /> 
                        </div>           
                        <Button type="submit" style={styles.week.submitButton}>                    
                            {localizations.find(({ ElementName }) => ElementName === 'vwSubmitBtn')?.ElementValue!}                    
                        </Button>
                    </form>
                </div>}
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>
            <Alert/>
        </>
    )
}