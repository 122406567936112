import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { InputValidation } from '../../../models/InputValidation';

interface Props { 
    styles?: any;         
    name: string;
    value: string;  
    label: string;    
    onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void;
    handleBlur?(event?: React.FocusEvent<HTMLTextAreaElement>): void;
    disabled?: boolean;
    validation?: InputValidation;
};

export const TextAreaInput: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input")); 
    const [focused, setFocused] = useState<boolean>(false);       
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const validation = props.validation;   

    useEffect(() => {          
        setStyles(styleContext.getComponentStyle("input"));                                                
    }, [isMobile]);

    function handleOnBlur(event?: React.FocusEvent<HTMLTextAreaElement>) {
        setFocused(false);
        props.handleBlur && props.handleBlur(event);
    }

    return (
        <>
            <div style={styles.wrapper} onClick={() => setFocused(true)}>
                <textarea style={{...styles.input, ...styles.textarea, ...(validation?.invalid && styles.input.invalid), ...(validation?.valid && styles.input.valid)}}                
                    name={props.name} 
                    value={props.value} 
                    onChange={props.onChange}
                    onBlur={handleOnBlur}
                />
                <label style={{...styles.label, ...((props.value || focused) && styles.label.focus), ...(validation?.invalid && styles.label.invalid)}}>{props.label}</label>
            </div> 
            {validation?.invalidMessage && <div style={styles.invalidMessage}>{validation.invalidMessage}</div>} 
        </>      
    );
}