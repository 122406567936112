import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Option } from "../../models/Option";
import { StyleContext } from "../../providers/StyleContextProvider";
import { DialogInput } from "../Shared/Inputs/DialogInput";
import { SubjectSelectDialog } from "./SubjectSelectDialog";
import { useAlert } from "../../hooks/useAlert";

interface Props {
    label: string; 
    streamId: number;   
    options: Option[];
    selectedIds?: string;
    setSelected(selectedIds?: string): void; 
    streamSelectionError: string;   
    subjectSelectionError: string;
    lowSubjectSelectionError: string;
    highSubjectSelectionError: string;
}

export const SubjectSelectInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [options, setOptions] = useState<Option[]>(props.options);    
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { Alert, openAlert } = useAlert();
                            
    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("input"));          
    }, [isMobile]);

    useEffect(() => {        
        setInitialSelects();        
    }, [props.selectedIds]);
    
    const setInitialSelects = () => {        
        if(props.selectedIds && props.selectedIds !== "") {     
            let selectedIdList: string[] = props.selectedIds!.split(",");
            let selectedOptionList: string[] = [];        
            props.options.forEach(option => {
                if(selectedIdList.includes(option.Value.toString())) {                             
                    option.Checked = true; 
                    selectedOptionList.push(option.PrimaryOption);
                }
            });        
            setSelectedOptions(selectedOptionList);
        }
        else {
            setSelectedOptions([]);
            props.options.forEach(option => {option.Checked = false});
        }
        setOptions(props.options);
    }

    const openSelectDialog = () => {
        if(options.length === 0) {
            openAlert("error", props.streamSelectionError);
        }
        else {
            setOpenDialog(true);
        }
    }

    const getSelected = (resultOptions: Option[]) => {  
        let selectedIds: string = "";
        let tempSelectedOptions: string[] = [];           
        resultOptions.forEach((option) => {
            if(option.Checked) {
                selectedIds = selectedIds + option.Value + ",";
                tempSelectedOptions.push(option.PrimaryOption);
            }
        });  
        selectedIds = selectedIds.substring(0, selectedIds.length - 1);  
        props.setSelected(selectedIds);
        setSelectedOptions(tempSelectedOptions);        
        setOptions(resultOptions);        
        setOpenDialog(false); 
    }    

    const resetOptions = () => {        
        setSelectedOptions([]);
        let tempOptions = [...options];
        tempOptions.forEach(option => {option.Checked = false});
        setOptions(tempOptions);
        props.setSelected();
    }

    return (
        <>
            <div style={styles}>                
                <div style={styles.inputWrapper} onClick={openSelectDialog}>
                    <DialogInput values={selectedOptions} onReset={resetOptions}>
                        {props.label}
                    </DialogInput>
                </div>     
                {openDialog && 
                <SubjectSelectDialog 
                    title={props.label} 
                    streamId={props.streamId} 
                    options={options} 
                    getSelected={getSelected} 
                    closeDialog={() => setOpenDialog(false)}
                    openAlert={openAlert}   
                    subjectSelectionError={props.subjectSelectionError}
                    lowSubjectSelectionError={props.lowSubjectSelectionError}
                    highSubjectSelectionError={props.highSubjectSelectionError}
                />}            
            </div>
            <Alert/>
        </>
    )
}