import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const footer = {
    mobile: {
        background: Color.DeepBlack,
        width: "100%",             
        marginTop: "18%",        
        color: Color.White,        
        letterSpacing: "0px",      
        opacity: "1",
        wrapper: {
            display: "flex",
            flexDirection: "row" as "row"
        },        
        title: {
            paddingTop: "10%", 
            paddingLeft: "5%",                           
            fontSize: "36px",        
            fontFamily: Font.Advanced
        },
        menu: {        
            position: "relative" as "relative",            
            display: "flex",
            flexDirection: "column" as "column", 
            paddingTop: "8%",
            paddingLeft: "5%",
            alignItems: "left" as "left", 
            menuItem: {                                                     
                letterSpacing: "0px", 
                opacity: "1",                                  
            },
            label: {   
                textDecoration: "none",      
                fontSize: "16px",                
                color: Color.White                         
            },
            list: {
                listStyleType: "none",
                marginTop: "0%",
                paddingLeft: "5%",
            },
            item: {
                textDecoration: "none",
                fontSize: "12px",                
                color: Color.LightAsh,
                whiteSpace: "nowrap",
                WebkitTapHighlightColor : "transparent",
            }
        },
        details: {
            position: "relative" as "relative",
            paddingLeft: "5%",
            display: "grid",            
            gap: "10px",            
            fontSize: "12px",
            color: Color.LightAsh,
            paddingTop: "8%"          
        },
        largeIcon: {                
            width: "13px",
            height: "13px",
            marginLeft: "10px",
            cursor: "pointer",
            fill : Color.White,
            WebkitTapHighlightColor : "transparent",
        },
        smallIcon: {                
            width: "10px",
            height: "10px",
            marginTop: "3px",
            marginRight: "8px",
            fill : Color.White
        },        
        socialIconWrapper: {            
            marginBottom: "15px"            
        },
        copyright: {
            paddingTop: "5%",
            paddingBottom: "10%",
            textAlign: "center" as "center",
            fontSize: "8px", 
            color: Color.LightAsh
        }
    },
    desktop: {
        height: "360px",
        marginTop: "80px",
        title: {            
            padding: "40px 0 0 50px",                                      
            fontSize: "42px"
        },
        menu: {                
            position: "absolute" as "absolute", 
            flexDirection: "row" as "row",           
            gridTemplateColumns: "repeat(3, 1fr)",   
            right: "50px",                   
            paddingTop: "60px",            
            padding: 0, 
            marginTop: "-40px",
            menuItem: { 
                marginLeft: "40px",                               
            },
            label: {                     
                fontSize: "20px"                        
            },
            list: {
                listStyleType: "disc",
                marginTop: "10px",
                paddingLeft: "25px",
            },
            item: {                
                fontSize: "14px"
            }
        },
        details: {
            position: "absolute" as "absolute",
            marginTop: "100px",
            gap: "25px",
            right: "50px",
            padding: 0,
            fontSize: "14px",
            gridTemplateColumns: "340px 145px 130px" 
        },
        smallIcon: {
            marginTop: "5px",
            marginRight: "10px"
        },        
        copyright: {
            padding: 0, 
            fontSize: "10px",
            marginTop: "190px"            
        }
    }
}