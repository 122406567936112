import axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { AccountRegisterModel } from "../models/AccountRegisterModel";
import { PasswordModel } from "../models/PasswordModel";

export async function login(email: string, password: string) {    
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);
    params.append('grant_type', 'password');
    
    return new Promise<any>((resolve, reject) => {
        axios
        .post("https://api.tuteclass.com/token", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"                    
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function register(registerModel: AccountRegisterModel) {
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(Texts.apiBaseUrl + "common/account/register", registerModel, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function forgotPassword(email: string) {
    let forgotPasswordModel: any = { "Email": email };
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(Texts.apiBaseUrl + "common/account/forgotPassword", JSON.stringify(forgotPasswordModel), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function resetPassword(passwordModel: PasswordModel) {
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(Texts.apiBaseUrl + "common/account/resetPassword", passwordModel, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}