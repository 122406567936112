import { Color } from "../enums/Color";

export const listPage = {
    mobile: {                 
        desktopTitle: {
            display: "none"
        },
        container: {
            margin: "110px 5% 20px 5%",
            paddingTop: "2px"
        },
        searchWrapper: {
            width: "-webkit-fill-available",
            position: "fixed" as "fixed",
            top: "50px",
            padding: "10px 5%",
            background: Color.White
        },
        searchInput: {
            height: "38px",
            width: "-webkit-fill-available",
            backgroundColor: Color.White,
            border: "2px solid " + Color.Green,
            borderRadius: "20px",
            padding: "0 15px"
        },
        link: {
            textDecoration: "none",
            color: Color.Black
        },
        dialog: {
            dialog: {
                height: "20%",
                top: "40%"
            },
            title: {
                height: "25%"      
            },
            container: {
                height: "60%",
                overflow: "auto"            
            },            
            buttonWrapper: {
                paddingTop: "0px"
            },
            button: {            
                float: "right"
            }
        },
        image: {
            height: "198px",
            width: "-webkit-fill-available",
            borderRadius: "10px",
            marginBottom: "10px",
            objectFit: "cover"
        },
        adCard: {
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid " + Color.Green,                        
            padding: "10px 0",            
            alignItems: "center",
            cursor: "pointer",
            image: {
                height: "120px",
                width: "120px",
                borderRadius: "6px",                
                objectFit: "cover",
                cursor: "pointer"
            },
            detailWrapper: {
                marginLeft: "8px"
            },
            name: {
                fontSize: "18px"                
            },
            subjects: {
                fontSize: "13px"
            },
            districts: {
                fontSize: "13px"
            },
            type: {
                fontSize: "13px"
            },
            see: {
                fontSize: "13px",
                fontWeight: "bold",
                color: Color.Green
            }
        },
        listCount: {            
            display: "flex",
            border: "1px solid " + Color.Green,
            borderRadius: "2.41vw",
            backgroundColor: Color.SharpGreen,
            alignItems: "center" as "center",
            justifyContent: "center" as "center",
            height: "38px",
            fontSize: "4.34vw",
            color: Color.Green,            
            fontWeight: 500,
            count: {
                fontWeight: "bold",
                marginRight: "1.2vw"
            }
        },
        filtersExpandButton: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "10.86vw",
            width: "102%",            
            marginLeft: "-2%",            
            paddingLeft: "3.62vw",
            paddingRight: "3.62vw",
            lineHeight: "10.86vw",
            border: "none",
            backgroundColor: Color.DarkAsh,
            borderRadius: "2.41vw",        
            cursor: "pointer",
            boxSizing: "border-box",
            WebkitTapHighlightColor: 'transparent',
            expand: {
                borderRadius: "2.41vw 2.41vw 0 0",
                marginBottom: "3.62vw"
            }
        },        
        expandButtonIcon: {
            height: "4.58vw",
            width: "4.1vw",
            margin: "auto",
            marginRight: "0",
            transition: "transform .1s ease-in-out",
            up: {
                transform: "rotate(180deg)"
            }
        }
    },
    desktop: {        
        desktopTitle: {
            display: "block",
            position: "fixed" as "fixed",
            top: "0",
            left: "32%",
            right: "32%",            
            textAlign: "center" as "center",
            color: Color.Green,
            background: Color.White,
            margin: 0,
            paddingTop: "48.4px"
        }, 
        searchWrapper: {            
            top: "86px",
            padding: "10px 32%" ,
            textAlign: "center" as "center"           
        },
        searchInput: {            
            width: "86%"
        },       
        container: {
            margin: "152px 0 20px 0",
            paddingLeft: "32%",
            paddingRight: "32%"
        },        
        dialog: {
            dialog: {                       
                top: "30%",
                right: "35%",
                padding: "2%"
            },
            container: {
                height: "48%",                
                paddingTop: "2%"          
            }
        },
        image: {
            height: "334px",
            borderRadius: "20px"
        },
        adCard: {
            padding: "12px 0",            
            alignItems: "center",
            image: {
                height: "140px",
                width: "140px",
                borderRadius: "8px"
            },
            detailWrapper: {
                marginLeft: "12px"
            },
            name: {
                fontSize: "20px"                
            },
            subjects: {
                fontSize: "14px"
            },
            districts: {
                fontSize: "14px"
            },
            type: {
                fontSize: "14px"
            },
            see: {
                fontSize: "14px"
            }
        },
        listCount: {
            borderRadius: "0.41vw",
            height: "40px",
            fontSize: "1.34vw",
            count: {
                fontWeight: "bold",
                marginRight: "0.5vw"
            }
        },
        filtersExpandButton: { },        
        expandButtonIcon: {
            width: "0.8vw",
            height: "0.8vw"
        }
    }
}